import React, { useState, useEffect } from 'react'
import { components } from 'cng-web-lib'
import { Box, FormControl, Grid, InputLabel, Paper, Select } from '@material-ui/core'

const { button: { CngButton }, CngDialog } = components

const draft = 'DR'
const accepted = 'AC'
const rejected = 'RJ'
const cancellationAccepted = 'CL'

function MassSubmitHouseBillDialog(props) {
    const { manifestId, onClose, onSubmit, open, translatedTextsObject } = props

    const [status, setStatus] = useState(draft)
    const [messageFunction, setMessageFunction] = useState('9')

    return (
        <CngDialog
            dialogContent={
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StatusItem
                            status={status}
                            setStatus={setStatus}
                            messageFunction={messageFunction}
                            setMessageFunction={setMessageFunction}
                            translatedTextsObject={translatedTextsObject}
                        />
                    </Grid>
                </Grid>
            }
            dialogAction={
                <Grid container justify='space-between' spacing={2}>
                    <Grid item xs='auto'>
                        <CngButton color='secondary' onClick={onClose} size='medium'>Back</CngButton>
                    </Grid>

                    <Grid item xs='auto'>
                        <Grid container justify='flex-end' spacing={2}>
                            <Grid item xs='auto'>
                                <CngButton color='primary' size='medium' onClick={() => {
                                    onSubmit({ manifestId, status: status, messageFunction: messageFunction })
                                    onClose()
                                }}>
                                    Submit to Customs
                                </CngButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            dialogTitle='Mass Submit House Bills'
            fullWidth
            maxWidth='md'
            onClose={onClose}
            open={open}
            shouldShowCloseButton
        />
    )
}

export default MassSubmitHouseBillDialog

function StatusItem(props) {
    const { status, setStatus, messageFunction, setMessageFunction, translatedTextsObject } = props

    const [messageFunctions, setMessageFunctions] = useState(null)

    useEffect(() => {
        let options = []
        if (status == draft || status == cancellationAccepted) {
            setMessageFunction('9')
            options.push(
                <option value='9'>{translatedTextsObject.msgFuncOriginal}</option>
            )
        } else if (status == rejected) {
            setMessageFunction('9')
            options.push(
                <option value='9'>{translatedTextsObject.msgFuncOriginal}</option>,
                <option value='4'>{translatedTextsObject.msgFuncChange}</option>
            )
        } else if (status == accepted) {
            setMessageFunction('4')
            options.push(
                <option value='4'>{translatedTextsObject.msgFuncChange}</option>,
                <option value='52'>{translatedTextsObject.msgFuncProposedAmendment}</option>
            )
        }
        setMessageFunctions(options)
    }, [status])

    function handleStatusChange(event) {
        setStatus(event.target.value)
    }

    function handleMessageFunctionChange(event) {
        setMessageFunction(event.target.value)
    }

    return (
        <Paper variant='outlined'>
            <Box padding={2}>
                <Grid alignItems='center' container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl variant='outlined' fullWidth>
                            <InputLabel htmlFor={`status-label-${0}`}>Submit for</InputLabel>
                            <Select
                                label='Submit for'
                                inputProps={{ id: `status-label-${0}` }}
                                native
                                onChange={handleStatusChange}
                                size='small'
                                value={status}
                            >
                                <option value={draft}>Draft</option>
                                <option value={rejected}>Rejected</option>
                                <option value={accepted}>Accepted</option>
                                <option value={cancellationAccepted}>Cancelled</option>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl variant='outlined' fullWidth>
                            <InputLabel htmlFor={`message-function-label-${0}`}>Submit as</InputLabel>
                            <Select
                                label='Submit as'
                                inputProps={{ id: `message-function-label-${0}` }}
                                native
                                onChange={handleMessageFunctionChange}
                                size='small'
                                value={messageFunction}
                            >
                                {messageFunctions}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}
