import React, { useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import FormProperties from '../../housebill/FormProperties'
import { Box } from '@material-ui/core'
import moment from 'moment'
import HouseBillViewContent from './../../housebill/HouseBillViewContent'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function HouseBillDialog(props) {
  const {
    houseBill,
    expectedArrivalDateTimeValue,
    expectedArrivalTimeZoneValue,
    firstExpectedPortValue,
    isView,
    getLookupValue,
    getCountryStateLabel,
    manifestId,
    loading,
    onClose,
    onCreateHouseBill,
    onEditHouseBill,
    open,
    showNotification
  } = props
  const { initialValues, makeValidationSchema } = FormProperties.formikProps

  const { translate } = useTranslation(Namespace.EHBL_HOUSE_BILL)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let updateButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPDATE_BUTTON
    )
    let saveButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SAVE_BUTTON
    )
    let firstExpectedPort = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FIRST_EXPECTED_PORT
    )
    let arrivalDateAndTime = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ARRIVAL_DATE_AND_TIME
    )


    return {
      updateButton,
      saveButton,
      firstExpectedPort,
      arrivalDateAndTime
    }
  }

  let viewContent = (
    <HouseBillViewContent
      data={houseBill}
      getLookupValue={getLookupValue}
      getCountryStateLabel={getCountryStateLabel}
    />
  )

  if(!open){
    return null
  }

  return (
    <>
      <CngDialog
        dialogContent={
          houseBill && isView ? (viewContent) : (
            <CngForm
              fieldLevel='form'
              hookFormProps={{
                initialValues: {
                  ...(houseBill || initialValues),
                  manifestId: manifestId
                },
                makeValidationSchema: makeValidationSchema,
                onSubmit: (data) => {
                  if (houseBill && houseBill.cloneFromManifest == "N" ) {
                    onEditHouseBill(data)
                  } else {
                    onCreateHouseBill(data)
                  }
                }
              }}
              formState={FormState.COMPLETED}
              renderBodySection={(labelMap, shouldHideMap) => (
                <FormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                  getLookupValue={getLookupValue}
                  getCountryStateLabel={getCountryStateLabel}
                  autoPopulateBusinessName={!houseBill || houseBill?.cloneFromManifest != "N"}
                />
              )}
              innerForm={true}
              renderButtonSection={() => (
                <Box display='flex' justifyContent='flex-end'>
                  <CngButton
                    color='primary'
                    size='medium'
                    type='submit'
                    disabled={loading}
                    shouldShowProgress={loading}
                  >
                    {houseBill && houseBill.cloneFromManifest == "N"
                      ? translatedTextsObject.updateButton
                      : translatedTextsObject.saveButton}
                  </CngButton>
                </Box>
              )}
            />
          )
        }
        dialogAction={
          isView ? <CngButton onClick={onClose}>OK</CngButton> : null
        }
        dialogTitle='House Bill'
        open={open}
        onClose={onClose}
        maxWidth='lg'
        fullWidth
      />
    </>
  )
}

export default HouseBillDialog
