import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { components, DataFlattener, useServices } from 'cng-web-lib'
import FaxTrackerApiUrls from 'src/apiUrls/FaxTrackerApiUrls'
import { useFormContext } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import CommunicationUtilUrls from 'src/apiUrls/CommunicationUtilUrls'
import moment from 'moment-timezone'
import sweetalert from 'sweetalert'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function FaxTrackerEditPage({ showNotification, onSetLoading }) {
  const { id } = useParams()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: FaxTrackerApiUrls.GET
          }}
          update={{
            url: FaxTrackerApiUrls.SAVE
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <CustomButtonSection
              showNotification={showNotification}
              loading={loading}
              onSetLoading={onSetLoading}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default FaxTrackerEditPage

function CustomButtonSection(props) {
  const {
    loading,
    onSetLoading,
    showNotification,
  } = props

  const { createRecord, securedSendRequest } = useServices()
  const { getValues, trigger } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  async function handleSubmitFAX() {
    const values = getValues()
    onSetLoading(true)

    var serverData = FormProperties.toServerDataFormat(values)
    let result = await trigger()
    if(result){
      createRecord.execute(
        FaxTrackerApiUrls.SAVE,
        DataFlattener.unflatten(serverData),
        (result) => {
          console.log("res"+JSON.stringify(result))
          var errorMessages = result.errorMessages
          if (result !== null && (errorMessages === null || errorMessages === "" || !errorMessages)) {
            showNotification('success', 'Updated successfully')
          } else {
            showNotification('error', errorMessages)
          }
        },
        (error) => {
          console.log(error)
          showNotification('error', 'Fail to update')
        },
        () => onSetLoading(false)
      )
    }else{
      onSetLoading(false)
      sweetalert("Invalid field" ,"", "error")
    }
  }

  async function handleSendComm(type) {
    const values = getValues()
    onSetLoading(true)

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    var receivedDateTime = moment(values.faxReceivedDate).tz("Canada/Eastern").format('DD/MM/YYYY @ HH:mm ')
    var timeZoneAbbr = moment.tz("Canada/Eastern").zoneAbbr()

    const emailComm = {
      commsType: type,
      commsRecipient: values.email,
      commsSubject: "Fax Tracker Email Notification For Trip Number :  "+values.tripNo,
      commsContent: "*********** Fax Tracker Email Notification Summary ***********"+
        "\n------------------------------------------------------------------------------------------"+
        "\n\nTrip Number : "+values.tripNo+
        "\nFax Type : "+values.faxType+
        "\nPort : "+values.firstPort+
        "\nNo. Of Pages : "+values.noOfPages+
        "\nNo. Of Shipment : "+values.noOfShipments+
        "\nReceived by CrimsonLogic on "+receivedDateTime+timeZoneAbbr+
        "\n\n------------------------------------------------------------------------------------------"
    }
    const smsComm = {
      commsType: type,
      commsRecipient: values.phoneNo,
      commsContent: "Your Message: Trip no: "+values.tripNo+", "+values.noOfPages +" pages, "+values.noOfShipments+" shipments, port: "+values.firstPort+", received by CrimsonLogic on "+receivedDateTime+timeZoneAbbr+"."
    }

    function onSuccess(response) {
      var returnData = response.data
      var errorMessages = returnData.errorMessages
      if (response.status == 200 && returnData != null && errorMessages == null) {
        showNotification('success', 'Sent successfully')
      } else {
        showNotification('error', 'Fail to send')
      }
    }

    function onError(error) {
      console.log(error)
    }

    function onComplete() { onSetLoading(false) }
    const config = {}

    if(type === "Email" && getValues("email") === ""){
      onSetLoading(false)
      sweetalert("Driver Email is required" ,"", "error")
      return
    }
    if(type === "SMS" && getValues("phoneNo") === ""){
      onSetLoading(false)
      sweetalert("Driver Cell Number is required" ,"", "error")
      return
    }

    let result = await trigger()
    if(result){
      securedSendRequest.execute('POST', CommunicationUtilUrls.POST, type === "Email" ? emailComm : smsComm, onSuccess, onError, onComplete, config, null);
    }else{
      onSetLoading(false)
      sweetalert("Invalid field" ,"", "error")
    }
  }
  

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='primary'
            disabled={loading}
            onClick={handleSubmitFAX}
            size='medium'
            variant='contained'
            shouldShowProgress={loading}
          >
            Update
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                disabled={loading}
                onClick={() => handleSendComm("Email")}
                size='medium'
                shouldShowProgress={loading}
              >
                Send Email
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                disabled={loading}
                onClick={() => handleSendComm("SMS")}
                size='medium'
                shouldShowProgress={loading}
              >
                Send SMS
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}