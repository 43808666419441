import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import FaxTrackerKeys from 'src/constants/locale/key/FaxTracker'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  let minLengthErrorMessage = translate(
    Namespace.FAX_TRACKER,
    FaxTrackerKeys.Validations.MIN_LENGTH
  )

  let maxLengthErrorMessage = translate(
    Namespace.FAX_TRACKER,
    FaxTrackerKeys.Validations.MAX_LENGTH
  )

  let alphaNumericErrorMessage = translate(
    Namespace.FAX_TRACKER,
    FaxTrackerKeys.Validations.ALPHANUMERIC
  )

  let numericErrorMessage = translate(
    Namespace.FAX_TRACKER,
    FaxTrackerKeys.Validations.NUMERIC
  )

  let emailErrorMessage = translate(
    Namespace.FAX_TRACKER,
    FaxTrackerKeys.Validations.EMAIL
  )

  let tripNumPrefix = translate(
    Namespace.FAX_TRACKER,
    FaxTrackerKeys.Validations.TRIP_NUM_PREFIX
  )

  let regexAlphaNumeric = "^[a-zA-Z0-9 ]*$"
  let regexNumeric = "^[0-9+]*$"

  return Yup.object({
    faxUserId: Yup.string().max(15,maxLengthErrorMessage+" 15").required(requiredMessage).matches(regexAlphaNumeric, alphaNumericErrorMessage).nullable(),
    faxReceivedDate: Yup.string().nullable(),
    noNotification: Yup.boolean().nullable(),
    email:Yup.string().nullable().max(50,maxLengthErrorMessage+" 50").min(5,minLengthErrorMessage+ " 5").email(emailErrorMessage).when(['noNotification','phoneNo'], {
      is: (noNotification,phoneNo) => (noNotification === true && !phoneNo ),
      then: Yup.string().required(requiredMessage)
    }),
    phoneNo: Yup.string().nullable().max(15,maxLengthErrorMessage+" 15").matches(regexNumeric, numericErrorMessage).test("phoneNo",requiredMessage,function(){
      let noNoti = this.parent["noNotification"];
      let email = this.parent["email"];
      let phoneNo = this.parent["phoneNo"];
      if(noNoti === true && undefined === email && undefined === phoneNo){
        return false;
      }else{
        return true;
      }
    }),
    faxType: Yup.string().required(requiredMessage).nullable(),
    scac: Yup.string().max(4,maxLengthErrorMessage+" 4").min(4,minLengthErrorMessage+ " 4").required(requiredMessage).matches("^[a-zA-Z0-9-]*$", alphaNumericErrorMessage).nullable(),
    tripNo: Yup.string().required(requiredMessage).trim().matches("^[a-zA-Z0-9-]+$",alphaNumericErrorMessage).min(5,minLengthErrorMessage+ " 5")
    .test("tripNo",tripNumPrefix,function(){
      let carrierCode = this.parent["scac"];
      let tripNum = this.parent["tripNo"];
    if(tripNum && carrierCode) {
      if(tripNum.startsWith(carrierCode))
       return true;
      else
       return false;
    }
    }).nullable(),     
    eta: Yup.string().required(requiredMessage).nullable(),
    transitIndicator: Yup.string().required(requiredMessage).nullable(),
    firstPort: Yup.string().required(requiredMessage).nullable(),
    noOfPages: Yup.string().nullable().when('fwdToBroker', {
      is: (fwdToBroker) => (fwdToBroker === true),
      then: Yup.string().required(requiredMessage)
    }).test("noOfPages","At least one Page is required.",function(){
      let fwd = this.parent["fwdToBroker"];
      let noOfPages = this.parent["noOfPages"];
      if(fwd === true && noOfPages < 1){
        return false;
      }else{
        return true;
      }
    }),
    noOfShipments: Yup.string().nullable().when('fwdToBroker', {
      is: (fwdToBroker) => (fwdToBroker === true),
      then: Yup.string().required(requiredMessage)
    }),
    fwdToBroker: Yup.boolean().nullable(),
    brokerName: Yup.string().nullable(),
    faxNumber: Yup.string().nullable().max(14,maxLengthErrorMessage+" 14").matches(regexNumeric, numericErrorMessage).test("faxNumber",requiredMessage,function(){
      let fwd = this.parent["fwdToBroker"];
      let brEmail = this.parent["brokerEmail"];
      let faxNumber = this.parent["faxNumber"];
      if(fwd === true && undefined === brEmail && undefined === faxNumber){
        return false;
      }else{
        return true;
      }
    }),
    brokerEmail:Yup.string().nullable().max(50,maxLengthErrorMessage+" 50").min(5,minLengthErrorMessage+ " 5").email(emailErrorMessage).when(['fwdToBroker','faxNumber'], {
      is: (fwdToBroker,faxNumber) => (fwdToBroker === true && !faxNumber ),
      then: Yup.string().required(requiredMessage)
    }),
    remarks: Yup.string().nullable().max(200,maxLengthErrorMessage+" 200"),
  })
}

export default makeValidationSchema
