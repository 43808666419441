import React, { useEffect, useRef, useState } from 'react'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import EhblHouseBillApiUrls from 'src/apiUrls/EhblHouseBillApiUrls'
import EhblManifestApiUrls from 'src/apiUrls/EhblManifestApiUrls'
import {
  Box,
  Grid,
  makeStyles,
  Select,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import Table from '../../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AttachHouseBillDialog from './AttachHouseBillDialog'
import { getStatusMetadata } from '../../../../common/NACommon'
import HouseBillDialog from './HouseBillDialog'
import HouseBillFormProperties from '../../housebill/FormProperties'
import ConfirmDialog from '../../../common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import SetResponseMessageDialog from './SetResponseMessageDialog'

const {
  button: { CngButton, CngIconButton },
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { EQUAL, BETWEEN, IN, LIKE }
} = constants

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.name === 'ONE_DARK' ? 'transparent' : theme.palette.common.white,
    fontSize: 14,
    maxWidth: '100%',
    width: 150,
    '& > .MuiOutlinedInput-input': {
      padding: 8
    },
    '& > .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 32
    }
  }
}))

function HouseBillViewTable(props) {
  const { disableView = false, getCountryStateLabel, getLookupValue, manifestId, loading, showNotification} = props

  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.EHBL_HOUSE_BILL
  ])
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()
  const tableRef = useRef(null)
  const [attachHouseBillDialog, setAttachHouseBillDialog] = useState(false)
  const {
    fetchRecords
  } = useServices()

  const [houseBillDialog, setHouseBillDialog] = useState({
    open: false,
    houseBill: null,
    isView: false
  })
  
  function makeTranslatedTextsObject() {
    let ehblHouseBill = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: ehblHouseBill
      }
    )
    let manifestId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MANIFEST_ID
    )
    let corpid = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CORPID
    )
    let movementType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MOVEMENT_TYPE
    )
    let indicatorMode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.INDICATOR_MODE
    )
    let primaryCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRIMARY_CCN
    )
    let carrierCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CARRIER_CODE
    )
    let ccn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CCN
    )
    let portOfDischargeCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PORT_OF_DISCHARGE_CODE
    )
    let portOfDestCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PORT_OF_DEST_CODE
    )
    let messageFuncCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MESSAGE_FUNC_CODE
    )
    let consolidationInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONSOLIDATION_IND
    )
    let totalWeight = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TOTAL_WEIGHT
    )
    let status = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.STATUS
    )
    let closeStatus = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CLOSE_STATUS
    )
    let responseDatetime = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.RESPONSE_DATETIME
    )
    let serviceBureauId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SERVICE_BUREAU_ID
    )
    let upsClientId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPS_CLIENT_ID
    )
    let userRole = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.USER_ROLE
    )
    let email = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EMAIL
    )
    let mobileNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MOBILE_NO
    )
    let totalWeightUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TOTAL_WEIGHT_UOM
    )
    let shipperName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SHIPPER_NAME
    )
    let consigneeName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONSIGNEE_NAME
    )
    let entryNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ENTRY_NO
    )
    let amendmentCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.AMENDMENT_CODE
    )
    let ucn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UCN
    )
    let b2bComment = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.B2B_COMMENT
    )
    let destSubLocCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DEST_SUB_LOC_CODE
    )
    let discSubLocCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DISC_SUB_LOC_CODE
    )
    let undgContactName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNDG_CONTACT_NAME
    )
    let undgContactNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNDG_CONTACT_NO
    )
    let specialInstruction = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SPECIAL_INSTRUCTION
    )
    let isDangerousGoods = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.IS_DANGEROUS_GOODS
    )
    let hanInstruction = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.HAN_INSTRUCTION
    )
    let volume = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.VOLUME
    )
    let volumeUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.VOLUME_UOM
    )
    let containerizedInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONTAINERIZED_IND
    )
    let mfInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MF_IND
    )
    let mfDatetime = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MF_DATETIME
    )
    let businessName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.BUSINESS_NAME
    )
    let freightFwdCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FREIGHT_FWD_CODE
    )
    let uniqueRefNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNIQUE_REF_NO
    )
    let clMsgInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CL_MSG_IND
    )
    let prevCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PREV_CCN
    )
    let noticeReasonCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.NOTICE_REASON_CODE
    )
    let houseRefNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.HOUSE_REF_NO
    )
    let usPortOfExit = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.US_PORT_OF_EXIT
    )
    let usPortOfExitDesc = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.US_PORT_OF_EXIT_DESC
    )
    let frnPortOfLoading = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FRN_PORT_OF_LOADING
    )
    let frnPortOfLoadingDesc = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FRN_PORT_OF_LOADING_DESC
    )
    let remarks = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.REMARKS
    )
    let printDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_DATE
    )
    let accountOff = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ACCOUNT_OFF
    )
    let storageDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.STORAGE_DATE
    )
    let partyId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMITTED_DATE
    )
    let templateName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TEMPLATE_NAME
    )
    let modeOfSubmission = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MODE_OF_SUBMISSION
    )

    let createdBy = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CREATED_DATE
    )

    let updatedBy = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPDATED_DATE
    )

    let msgFuncOriginal = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MSG_FUNC_ORIGINAL
    )

    let msgFuncChange = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MSG_FUNC_CHANGE
    )

    let msgFuncCancel = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MSG_FUNC_CANCEL
    )

    let msgFuncProposedAmendment = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MSG_FUNC_PROPOSED_AMENDMENT
    )

    let submitButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMIT_BUTTON
    )

    let submitSentRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMIT_SENT_RECORD
    )

    let detachButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DETACH_BUTTON
    )

    let detachSentRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DETACH_SENT_RECORD
    )

    let editButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EDIT_BUTTON
    )

    let editSentRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EDIT_SENT_RECORD
    )

    let cloneButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CLONE_BUTTON
    )

    let deleteButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DELETE_BUTTON
    )

    let deleteNotDraftRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DELETE_NOT_DRAFT_RECORD
    )


    return {
      ehblHouseBill,
      tableTitle,
      manifestId,
      corpid,
      movementType,
      indicatorMode,
      primaryCcn,
      carrierCode,
      ccn,
      portOfDischargeCode,
      portOfDestCode,
      messageFuncCode,
      consolidationInd,
      totalWeight,
      status,
      closeStatus,
      responseDatetime,
      serviceBureauId,
      upsClientId,
      userRole,
      email,
      mobileNo,
      totalWeightUom,
      shipperName,
      consigneeName,
      entryNo,
      amendmentCode,
      ucn,
      b2bComment,
      destSubLocCode,
      discSubLocCode,
      undgContactName,
      undgContactNo,
      specialInstruction,
      isDangerousGoods,
      hanInstruction,
      volume,
      volumeUom,
      containerizedInd,
      mfInd,
      mfDatetime,
      businessName,
      freightFwdCode,
      uniqueRefNo,
      clMsgInd,
      prevCcn,
      noticeReasonCode,
      houseRefNo,
      usPortOfExit,
      usPortOfExitDesc,
      frnPortOfLoading,
      frnPortOfLoadingDesc,
      remarks,
      printDate,
      accountOff,
      storageDate,
      partyId,
      submittedBy,
      submittedDate,
      templateName,
      modeOfSubmission,
      updatedBy,
      updatedDate,
      createdBy,
      createdDate,

      msgFuncOriginal,
      msgFuncChange,
      msgFuncCancel,
      msgFuncProposedAmendment,
      submitButton,
      submitSentRecord,
      detachButton,
      detachSentRecord,
      editButton,
      editSentRecord,
      cloneButton,
      deleteButton,
      deleteNotDraftRecord
    }
  }

  const columns = [
    {
      field: "primaryCcn",
      sortKey: "primaryCcn",
      title: translatedTextsObject.primaryCcn,
    },
    {
      field: "ccn",
      sortKey: "ccn",
      title: translatedTextsObject.ccn,
    },
    {
      field: "shipperName",
      sortKey: "shipperName",
      title: translatedTextsObject.shipperName,
    },
    {
      field: "consigneeName",
      sortKey: "consigneeName",
      title: translatedTextsObject.consigneeName,
    },
    {
      field: "noticeReasonCode",
      sortKey: "noticeReasonCode",
      title: translatedTextsObject.noticeReasonCode,
    },
    {
      field: "status",
      sortKey: "status",
      title: translatedTextsObject.status,
      render: (data) => {

        let status = getStatusMetadata(data.status === 'AC' ? '1000' : data.status)

        let content = [
          <Box>
            <Typography variant='inherit' style={{ color: status.color }}>
              {status.label}
            </Typography>
            <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {data.responseDatetime && moment(data.responseDatetime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')}
            </Typography>
          </Box>
        ]

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            {content}
          </Box>
        )
      }
    },
  ]

  return (
    <>
      <Table
        actions={[
        ]}
        columns={columns}
        compact
        fetch={{ url: EhblHouseBillApiUrls.GET }}
        fetchFilters={[
          { field: 'manifestId', operator: EQUAL, value: manifestId }
        ]}
        onRowClick={(rowData) =>
          setHouseBillDialog({ open: true, houseBill: HouseBillFormProperties.toClientDataFormat(rowData), isView: true })
        }
        rowActions={[
        ]}
        search={{ field: 'ccn', operator: LIKE }}
        showNotification={showNotification}
        tableRef={tableRef}
      />
      <AttachHouseBillDialog
        columns={columns}
        manifestId={manifestId}
        open={attachHouseBillDialog}
        onAttachHouseBill={() => {
          if (tableRef.current) {
            tableRef.current.performRefresh()
          }
        }}
        onClose={() => setAttachHouseBillDialog(false)}
        showNotification={showNotification}
      />
      <HouseBillDialog
        getCountryStateLabel={getCountryStateLabel}
        getLookupValue={getLookupValue}
        isView={houseBillDialog.isView}
        manifestId={manifestId}
        //firstExpectedPortValue={firstExpectedPortValue}
        //expectedArrivalDateTimeValue={expectedArrivalDateTimeValue}
        //expectedArrivalTimeZoneValue={expectedArrivalTimeZoneValue}
        loading={loading}
        onClose={() =>
          setHouseBillDialog({ open: false, houseBill: null, isView: false })
        }
        //onCreateHouseBill={handleCreateHouseBill}
        //onEditHouseBill={handleEditHouseBill}
        open={houseBillDialog.open}
        houseBill={houseBillDialog.houseBill}
        showNotification={showNotification}
      />
    </>
  )
  /*return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              //addRoute={`${pathname}/add`}
              columns={columns}
              //del={{ url: EhblHouseBillApiUrls.DELETE }}
              //editRoute={`${pathname}/edit`}
              exportData={{ url: EhblHouseBillApiUrls.EXPORT }}
              fetch={{ url: EhblHouseBillApiUrls.GET }}
              fetchFilters={[
                { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
              ]}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              // viewRoute={`${pathname}/view`}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 500, 1000]
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )*/
}

export default HouseBillViewTable


function MessageFuncAndAmendmentCode(props) {
  const {
    amendmentCodeOptions,
    isSelected,
    isSentStatus,
    onSubmitHouseBill,
    houseBill,
    onUpdateHouseBill,
    translatedTextsObject
  } = props

  const theme = useTheme()
  const classes = useStyles()

  function handleSubmitButtonClick(event) {
    event.stopPropagation()
    onSubmitHouseBill(houseBill)
  }

  return (
    <Grid alignItems='center' container spacing={1} wrap='nowrap'>
      <Grid item xs='auto'>
        <Select
          className={classes.select }
          disabled={isSentStatus || !isSelected}
          native
          onChange={(event) =>
            onUpdateHouseBill({ ...houseBill, messageFunction: event.target.value })
          }
          onClick={(event) => event.stopPropagation()}
          size='small'
          value={houseBill.messageFunction || ''}
          variant='outlined'
        >
          <option value="" disabled>
            {translatedTextsObject.messageFunction}
          </option>
          <option value='9'>{translatedTextsObject.msgFuncOriginal}</option>
          <option value='4'>{translatedTextsObject.msgFuncChange}</option>
          <option value='1'>{translatedTextsObject.msgFuncCancel}</option>
          <option value='52'>{translatedTextsObject.msgFuncProposedAmendment}</option>
        </Select>
      </Grid>
      {houseBill.messageFunction === '52' && (
        <Grid item xs='auto'>
          <Select
            className={classes.select}
            disabled={isSentStatus || !isSelected}
            native
            onChange={(event) =>
              onUpdateHouseBill({ ...houseBill, amendmentCode: event.target.value })
            }
            onClick={(event) => event.stopPropagation()}
            size='small'
            value={houseBill.amendmentCode || ''}
            variant='outlined'
          >
            <option value="" disabled>
              {translatedTextsObject.amendmentCode}
            </option>
            {amendmentCodeOptions
              .map((option) => (
                <option key={option.code} value={option.code}>
                  {option.desc}
                </option>
              ))}
          </Select>
        </Grid>
      )}
      <Grid item xs='auto'>
        <Tooltip
          arrow
          placement='top'
          title={
            isSentStatus
              ? translatedTextsObject.submitSentRecord
              : null
          }
        >
          <span>
            <CngIconButton
              disabled={isSentStatus || !isSelected || !houseBill.messageFunction}
              size='small'
              icon={['fal', 'arrow-alt-right']}
              onClick={handleSubmitButtonClick}
              style={{
                color:
                  isSentStatus || !isSelected
                    ? theme.palette.action.disabled
                    : theme.palette.primary.main
              }}
              type='outlined'
            />
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
