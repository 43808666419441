import EquipmentMasterApiUrls from 'src/apiUrls/EquipmentMasterApiUrls'
import FormProperties from './FormProperties'
import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from '../../paths/pathMap'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import { useFormContext } from 'react-hook-form'
import withFormState from '../../components/aciacehighway/hocs/withFormState'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage({ history, loading, onSetLoading, showNotification }) {
  const { id } = useParams()

  return (
    <CngEditForm
      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
      showNotification={showNotification}
      renderBodySection={(labelMap, shouldHideMap) => (
        <FormProperties.Fields
          showNotification={showNotification}
          shouldHideMap={shouldHideMap}
        />
      )}
      formikProps={FormProperties.formikProps}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      fetch={{ url: EquipmentMasterApiUrls.GET }}
      update={{ url: EquipmentMasterApiUrls.PUT }}
      id={id}
      renderButtonSection={(_, loading) => (
        <CustomButtonSection
          history={history}
          loading={loading}
          onSetLoading={onSetLoading}
        />
      )}
    />
  )
}

function CustomButtonSection(props) {
  const { history, loading, onSetLoading } = props
  const [alertDialog, setAlertDialog] = useState(false)
  const {
    formState: { isSubmitting }
  } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting}
            onClick={() => setAlertDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <CngButton
            color='primary'
            disabled={isSubmitting}
            type='submit'
            size='medium'
          >
            Update
          </CngButton>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
        onCancel={() => setAlertDialog(false)}
        onConfirm={() => history.push(pathMap.EQUIPMENT_MASTER_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and can't be restored. Are you
        sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(EditPage)
