import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import EquipmentMasterKeys from 'src/constants/locale/key/EquipmentMaster'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let minLengthErrorMessage = translate(
    Namespace.EQUIPMEMENT_MASTER,
    EquipmentMasterKeys.Validations.MIN_LENGTH
  )

  let maxLengthErrorMessage = translate(
    Namespace.EQUIPMEMENT_MASTER,
    EquipmentMasterKeys.Validations.MAX_LENGTH
  )

  let alphaNumericErrorMessage = translate(
    Namespace.EQUIPMEMENT_MASTER,
    EquipmentMasterKeys.Validations.ALPHANUMERIC
  )

  let regexAlphaNumeric = "^[a-zA-Z0-9 ]*$"

  return Yup.object({
    equipmentId: Yup.string().max(15,maxLengthErrorMessage+" 15").required(requiredMessage).matches(regexAlphaNumeric, alphaNumericErrorMessage).nullable(),
    carrierCode: Yup.string().max(4,maxLengthErrorMessage+" 4").min(4,minLengthErrorMessage+ " 4").matches(regexAlphaNumeric, alphaNumericErrorMessage).required(requiredMessage).nullable(),
    equipmentNo: Yup.string().max(35,maxLengthErrorMessage+" 35").required(requiredMessage).matches(regexAlphaNumeric, alphaNumericErrorMessage).nullable(),
    equipmentType: Yup.string().required(requiredMessage).nullable(),
    aceId: Yup.string().max(10,maxLengthErrorMessage+" 10").matches(regexAlphaNumeric, alphaNumericErrorMessage).nullable(),
    licensePlates: Yup.array()
  })
}

export default makeValidationSchema
