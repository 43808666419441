import EhblManifestApiUrls from 'src/apiUrls/EhblManifestApiUrls'
import FormProperties from './FormProperties'
import { Box, Card, useTheme } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import ManifestEditPage from './ManifestEditPage'
import Stepper from '../../../components/aciacehighway/Stepper'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import HouseBillAttachTable from '../manifest/housebill/HouseBillAttachTable'
import StatusNotificationListPage from './StatusNotificationListPage'
import ViewLogsHistory from './ViewLogsHistory'
import CloseMsgAttachTable from '../manifest/closemsg/CloseMsgAttachTable'

const {
  form: { CngEditForm }
} = components

function EditPage({ history, loading, location, onSetLoading, showNotification }) {
  const { id } = useParams()

  const { state } = location
  const manifestData = useRef(state?.manifestData || null)
  const [step, setStep] = useState((state?.step ? state.step : 1) - 1)
  const [manifestStatus, setManifestStatus] = useState(null)
  const [stepIconColor, setStepIconColor] = useState({})
  const [completed, setCompleted] = useState({})
  const theme = useTheme()

  function renderContent(step) {
    switch (step) {
      case 0:
        return (
          <ManifestEditPage
            id={id}
            manifestData={manifestData}
            onChangeStep={(step) => setStep(step)}
            onSetLoading={onSetLoading}
            onSetManifestStatus={(status) => setManifestStatus(status)}
            showNotification={showNotification}
            step={step}
          />
        )
      case 1:
        return (
          <HouseBillAttachTable
            history={history}
            showNotification={showNotification}
            manifestId={id}
            onChangeStep={(step) => setStep(step)}
            step={step}
            loading={loading}
            onSetLoading={onSetLoading}
            manifestData={manifestData}
          />
        )
      case 2:
        return (
          <CloseMsgAttachTable
            history={history}
            showNotification={showNotification}
            manifestId={id}
            onChangeStep={(step) => setStep(step)}
            step={step}
            loading={loading}
            onSetLoading={onSetLoading}
            manifestData={manifestData}
          />
        )  
      case 3:
        return (
          <StatusNotificationListPage
            history={history}
            showNotification={showNotification}
            manifestId={id}
            manifestData={manifestData}
            onSetLoading={onSetLoading}
            onChangeStep={(step) => setStep(step)}
            step={step}
          />
        )
      case 4:
        return (
          <ViewLogsHistory
            manifestId={id}
            history={history}
            onChangeStep={(step) => setStep(step)}
            step={step}
          />
        )
    }
  }

  return (
    <>
    <Card>
      <Stepper
          steps={!manifestStatus || manifestStatus === 'DR' ? ['Create manifest', 'Add house bills','Add Close Message', 'Set status notification'] : ['Create manifest', 'Add house bills','Add Close Message', 'Set status notification', 'View logs & history']}
        activeStep={step}
        completed={completed}
        onSetActiveStep={(step) => setStep(step)}
        stepIconColor={stepIconColor}
      />
        <Box padding={2}>
        {renderContent(step)}
      </Box>
    </Card>
    </>
  )

}

export default withFormState(EditPage)
