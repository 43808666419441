import React,{useState} from 'react'
import { components, constants, useTranslation,useServices } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import EhblHouseBillApiUrls from 'src/apiUrls/EhblHouseBillApiUrls'
import Table from '../../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import axios from 'axios'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import EhblManifestApiUrls from 'src/apiUrls/EhblManifestApiUrls'
import ConfirmDialog from '../../../common/ConfirmDialog'

const {
  button: { CngButton },
  CngDialog
} = components

const {
  filter: { LIKE, EQUAL,IN }
} = constants

function AttachHouseBillDialog(props) {
  const {
    columns,
    manifestId,
    open,
    onAttachHouseBill,
    onClose,
    showNotification
  } = props

  const { translate } = useTranslation(Namespace.EHBL_HOUSE_BILL)
  const translatedTextsObject = makeTranslatedTextsObject()
  const {
    createRecord,
    deleteRecord,
    fetchRecords,
    securedSendRequest,
    updateRecord
  } = useServices()

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    data: null
  })

  function makeTranslatedTextsObject() {
    let attachHouseBillSuccessMsg = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ATTACH_CARGO_SUCESS_MSG
    )
    let attach = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ATTACH
    )
    let attachSentRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ATTACH_SENT
    )

    return {
      attachHouseBillSuccessMsg,
      attach,
      attachSentRecord,
    }
  }

  function handleSubmitSelectedHouseBill() {
    let rows = confirmDialog.data
    const result = {
      manifestId,
      houseBillId: rows.map((houseBill) => houseBill.id)
    }

    securedSendRequest.execute(
        'POST',
        EhblManifestApiUrls.ATTACH,
        result,
        (response) => {
          const data = response.data
          if (data.errorMessages != null && data.errorMessages.length > 0) {
            showNotification('error', data.errorMessages)
          } else if (
            data.errorMessages === null ||
            data.errorMessages === undefined
          ) {
            onAttachHouseBill()
            setConfirmDialog({ open: false, data: null })
            onClose()
          }
        },
        (error) => console.log(error)
      )
  }

  return (
    <>
    <CngDialog
      dialogTitle='Attach house bill'
      dialogContent={
        <Table
          checkboxSelection
          columns={columns}
          compact
          fetch={{ url: EhblHouseBillApiUrls.GET }}
          fetchFilters={[
            { field: 'status', operator: IN, value: 'DR|RJ|CL' },
            { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
            { field: 'manifestId', operator: EQUAL, value: 'null' }
          ]}
          selectActions={[
            {
              disabled: (rows) => {
                let shouldDisable = false

                rows.forEach((rowData) => {
                  if (
                    rowData.status === 'ST'
                  ) {
                    shouldDisable = true
                  }
                })

                return shouldDisable
              },
              icon: <FontAwesomeIcon icon={['fal', 'link']} />,
              label: translatedTextsObject.attach,
              onClick: (rows) => setConfirmDialog({ open: true, data: rows }),
              tooltip: (rows) => {
                let includesSentRecords = false

                rows.forEach((rowData) => {
                  if (
                    rowData.status === 'ST'
            
                  ) {
                    includesSentRecords = true
                  }
                })

                return includesSentRecords
                  ? translatedTextsObject.attachSentRecord
                  : null
              }
            }
          ]}
          search={{ field: 'ccn', operator: LIKE }}
          showNotification={showNotification}
          sortConfig={{
            type: 'column',
            defaultDirection: 'DESC',
            defaultField: 'updatedDate'
          }}
        />
      }
      dialogAction={
        <CngButton color='secondary' onClick={onClose}>
          Discard
        </CngButton>
      }
      fullWidth
      maxWidth='lg'
      open={open}
      onClose={onClose}
      shouldShowCloseButton
    />
    <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, data: null })}
        confirmDialog={handleSubmitSelectedHouseBill}
        content="All selected HBLs are going to attach with manifest and Primary Cargo Control Number will be updated. Would you like to proceed ?"
        okMsg='Yes'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default AttachHouseBillDialog
