import EquipmentMasterApiUrls from 'src/apiUrls/EquipmentMasterApiUrls'
import FormProperties from './FormProperties'
import { Card, CardActions, CardContent, Divider } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from '../../paths/pathMap'

const {
  button: { CngButton },
  form: { CngViewForm }
} = components

function ViewPage({ history, showNotification }) {
  const { id } = useParams()

  return (
    <Card>
      <CardContent>
        <CngViewForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap) => (
            <FormProperties.Fields
              disabled
              isView
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            id: id,
            url: EquipmentMasterApiUrls.GET
          }}
        />
      </CardContent>
      <Divider />
      <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
        <CngButton
          color='secondary'
          onClick={() => history.push(pathMap.EQUIPMENT_MASTER_LIST_VIEW)}
        >
          Back to equipment master list
        </CngButton>
      </CardActions>
    </Card>
  )
}

export default ViewPage
