import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import EquipmentMasterKeys from 'src/constants/locale/key/EquipmentMaster'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let minLengthErrorMessage = translate(
    Namespace.EQUIPMEMENT_MASTER,
    EquipmentMasterKeys.Validations.MIN_LENGTH
  )

  let maxLengthErrorMessage = translate(
    Namespace.EQUIPMEMENT_MASTER,
    EquipmentMasterKeys.Validations.MAX_LENGTH
  )

  let alphaNumericErrorMessage = translate(
    Namespace.EQUIPMEMENT_MASTER,
    EquipmentMasterKeys.Validations.ALPHANUMERIC
  )

  return Yup.object({
    licensePlateNo: Yup.string().max(35,maxLengthErrorMessage+" 35").required(requiredMessage).matches("^[a-zA-Z0-9]*$", alphaNumericErrorMessage).nullable(),
    countryofRegistration: Yup.string().required(requiredMessage).nullable(),
    state: Yup.string().required(requiredMessage).nullable()
  })
}

export default makeValidationSchema
