import makeValidationSchema from './LicensePlatesMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EquipmentMasterKeys from 'src/constants/locale/key/EquipmentMaster'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from 'react-hook-form'
import {
  NaStateCodeAutoCompleteField,
  NaAceStateCodeAutoCompleteField
} from 'src/components/na'

const {
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  licensePlateNo: '',
  countryofRegistration: 'CA',
  state: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, watch } = useFormContext()
  const state = watch('state')
  const countryofRegistration = watch('countryofRegistration')

  const { translate } = useTranslation(Namespace.EQUIPMEMENT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let licensePlateNo = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.LicensePlates.LICENSE_PLATE_NO
    )
    let countryofRegistration = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.LicensePlates.COUNTRYOF_REGISTRATION
    )
    let state = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.LicensePlates.STATE
    )

    return {
      licensePlateNo,
      countryofRegistration,
      state
    }
  }

  return (
    <Grid container spacing={2}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.licensePlateNo}>
        <CngTextField
          name='licensePlateNo'
          label={translatedTextsObject.licensePlateNo}
          isRequired
          disabled={disabled}
          onBlur={(e) =>
            setValue('licensePlateNo', e.target.value.toUpperCase())
          }
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.countryofRegistration}>
        <CngCodeMasterAutocompleteField
          name='countryofRegistration'
          label={translatedTextsObject.countryofRegistration}
          isRequired
          disabled={disabled}
          onChange={() => setValue('state', '', { shouldValidate: true })}
          codeType='EQ_MASTER_COUNTRY'
        />
      </CngGridItem>
      {countryofRegistration == 'MX' && (
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.state}>
          <NaAceStateCodeAutoCompleteField
            name='state'
            label={translatedTextsObject.state}
            disabled={disabled}
            countryCode={countryofRegistration}
            key={state}
          />
        </CngGridItem>
      )}
      {(countryofRegistration == 'US' || countryofRegistration == 'CA') && (
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.state}>
          <NaStateCodeAutoCompleteField
            name='state'
            label={translatedTextsObject.state}
            disabled={disabled}
            countryCode={countryofRegistration}
            key={state}
          />
        </CngGridItem>
      )}
    </Grid>
  )
}

const LicensePlatesFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default LicensePlatesFormProperties
