import React, { useState,useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'
import { Grid, Card, CardContent, Divider, Typography, Box, useTheme, InputAdornment, IconButton } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
  useTranslation
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import ContainerDetailsFormProperties from './ContainerDetailsFormProperties'
import TradePartyFormProperties from './TradePartyFormProperties'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import { useFormContext, useWatch } from "react-hook-form";
import ContainerDetailsTable from './ContainerDetailsTable'
import TradePartyTable from './TradePartyTable'
import { NaCustomsOfficeAutocompleteField, NaSublocationAutocompleteField } from 'src/components/na'
import ContainerDetailsDialog from './ContainerDetailsDialog'
import TradePartyDialog from './TradePartyDialog'
import {FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSwitchField,
      CngCodeMasterAutocompleteField
    },
  },
  table: {
    CngLocalModeDialogFormTable,
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  corpid: "",
  movementType: "",
  indicatorMode: "",
  primaryCcn: "",
  carrierCode: "",
  portOfDischargeCode: "",
  portOfDestCode: "",
  messageFuncCode: "",
  consolidationInd: false,
  totalWeight: "",
  status: "",
  closeStatus: "",
  responseDatetime: "",
  serviceBureauId: "",
  userRole: "",
  email: "",
  mobileNo: "",
  totalWeightUom: "",
  myReferenceNo: "",
  destSubLocCode: "",
  discSubLocCode: "",
  clStatus: "",
  clMsgFuncCode: "",
  clAmendCode: "",
  clUniqueRefNo: "",
  containerizedInd: false,
  totalVolume: "",
  totalVolumeUom: "",
  cargoQuantity: "",
  quantityUom: "",
  b2bComment: "",
  totalHbl: "",
  partyId: "",
  submittedBy: "",
  submittedDate: "",
  containerDetails: [],
  tradeParty: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap,getLookupValue,getCountryStateLabel }) {

  const { setValue, getValues, reset, watch, trigger } = useFormContext();
  const { translate } = useTranslation(Namespace.EHBL_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");

  const [user, setUser] = useState([]);
  useEffect(() => { 
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
  }
  }, []);

  const [tradePartyDialog, setTradePartyDialog] = useState({
    open: false,
    tradeParty: null,
    isView: false
  })

  const [containerDetailsDialog, setContainerDetailsDialog] = useState({
    open: false,
    containerDetails: null,
    isView: false
  })

  const containerDetails = watch('containerDetails')

  function handleAddContainerDetails(data) {

    updateSealNo(data)

    const containerDetails = [...getValues('containerDetails'), data].map(
      (containerDetails, index) => ({ ...containerDetails, _id: index + 1 }))
    setValue('containerDetails', containerDetails, { shouldValidate: true })
  }

  function handleDeleteContainerDetails(data) {

    const containerDetails = [...getValues('containerDetails')].filter(
      (containerDetails) => containerDetails.id ? containerDetails.id !== data.id : containerDetails._id !== data._id
    )

    setValue('containerDetails', containerDetails, { shouldValidate: true })
  }

  function handleEditContainerDetails(data) {

    updateSealNo(data)

    const containerDetails = [...getValues('containerDetails')]
    const index = containerDetails.findIndex(
      (containerDetails) => containerDetails.id ? containerDetails.id === data.id : containerDetails._id === data._id
    )

    containerDetails[index] = data

    setValue('containerDetails', containerDetails, { shouldValidate: true })
  }

  function updateSealNo(data) {
    let dataSealNoArraylist = [];
    if (data.sealNo1 != null && data.sealNo1 != undefined && data.sealNo1 != "") {
      dataSealNoArraylist.push(data.sealNo1)
    }

    if (data.sealNo2 != null && data.sealNo2 != undefined && data.sealNo2 != "") {
      dataSealNoArraylist.push(data.sealNo2)
    }

    if (data.sealNo3 != null && data.sealNo3 != undefined && data.sealNo3 != "") {
      dataSealNoArraylist.push(data.sealNo3)
    }

    if (data.sealNo4 != null && data.sealNo4 != undefined && data.sealNo4 != "") {
      dataSealNoArraylist.push(data.sealNo4)
    }

    if (data.sealNo5 != null && data.sealNo5 != undefined && data.sealNo5 != "") {
      dataSealNoArraylist.push(data.sealNo5)
    }

    if (data.sealNo6 != null && data.sealNo6 != undefined && data.sealNo6 != "") {
      dataSealNoArraylist.push(data.sealNo6)
    }

    if (data.sealNo7 != null && data.sealNo7 != undefined && data.sealNo7 != "") {
      dataSealNoArraylist.push(data.sealNo7)
    }

    if (data.sealNo8 != null && data.sealNo8 != undefined && data.sealNo8 != "") {
      dataSealNoArraylist.push(data.sealNo8)
    }

    if (data.sealNo9 != null && data.sealNo9 != undefined && data.sealNo9 != "") {
      dataSealNoArraylist.push(data.sealNo9)
    }

    if (data.sealNo10 != null && data.sealNo10 != undefined && data.sealNo10 != "") {
      dataSealNoArraylist.push(data.sealNo10)
    }

    if (data.sealNo11 != null && data.sealNo11 != undefined && data.sealNo11 != "" ) {
      dataSealNoArraylist.push(data.sealNo11)
    }

    if (data.sealNo12 != null && data.sealNo12 != undefined && data.sealNo12 != "") {
      dataSealNoArraylist.push(data.sealNo12)
    }

    if (data.sealNo13 != null && data.sealNo13 != undefined && data.sealNo13 != "") {
      dataSealNoArraylist.push(data.sealNo13)
    }

    if (data.sealNo14 != null && data.sealNo14 != undefined && data.sealNo14 != "") {
      dataSealNoArraylist.push(data.sealNo14)
    }

    if (data.sealNo15 != null && data.sealNo15 != undefined && data.sealNo15 != "") {
      dataSealNoArraylist.push(data.sealNo15)
    }

    if (data.sealNo16 != null && data.sealNo16 != undefined && data.sealNo16 != "") {
      dataSealNoArraylist.push(data.sealNo16)
    }

    if (data.sealNo17 != null && data.sealNo17 != undefined && data.sealNo17 != "") {
      dataSealNoArraylist.push(data.sealNo17)
    }

    if (data.sealNo18 != null && data.sealNo18 != undefined && data.sealNo18 != "") {
      dataSealNoArraylist.push(data.sealNo18)
    }

    if (data.sealNo19 != null && data.sealNo19 != undefined && data.sealNo19 != "") {
      dataSealNoArraylist.push(data.sealNo19)
    }

    if (data.sealNo20 != null && data.sealNo20 != undefined && data.sealNo20 != "") {
      dataSealNoArraylist.push(data.sealNo20)
    }

    if (data.sealNo21 != null && data.sealNo21 != undefined && data.sealNo21 != "") {
      dataSealNoArraylist.push(data.sealNo21)
    }

    if (data.sealNo22 != null && data.sealNo22 != undefined && data.sealNo22 != "") {
      dataSealNoArraylist.push(data.sealNo22)
    }

    if (data.sealNo23 != null && data.sealNo23 != undefined && data.sealNo23 != "") {
      dataSealNoArraylist.push(data.sealNo23)
    }

    if (data.sealNo24 != null && data.sealNo24 != undefined && data.sealNo24 != "") {
      dataSealNoArraylist.push(data.sealNo24)
    }

    if (data.sealNo25 != null && data.sealNo25 != undefined && data.sealNo25 != "") {
      dataSealNoArraylist.push(data.sealNo25)
    }

    if (data.sealNo26 != null && data.sealNo26 != undefined && data.sealNo26 != "") {
      dataSealNoArraylist.push(data.sealNo26)
    }

    if (data.sealNo27 != null && data.sealNo27 != undefined && data.sealNo27 != "") {
      dataSealNoArraylist.push(data.sealNo27)
    }

    if (data.sealNo28 != null && data.sealNo28 != undefined && data.sealNo28 != "") {
      dataSealNoArraylist.push(data.sealNo28)
    }

    if (data.sealNo29 != null && data.sealNo29 != undefined && data.sealNo29 != "") {
      dataSealNoArraylist.push(data.sealNo29)
    }

    if (data.sealNo30 != null && data.sealNo30 != undefined && data.sealNo30 != "") {
      dataSealNoArraylist.push(data.sealNo30)
    }

    if (data.sealNo31 != null && data.sealNo31 != undefined && data.sealNo31 != "") {
      dataSealNoArraylist.push(data.sealNo31)
    }

    if (data.sealNo32 != null && data.sealNo32 != undefined && data.sealNo32 != "") {
      dataSealNoArraylist.push(data.sealNo32)
    }

    if (data.sealNo33 != null && data.sealNo33 != undefined && data.sealNo33 != "") {
      dataSealNoArraylist.push(data.sealNo33)
    }

    if (data.sealNo34 != null && data.sealNo34 != undefined && data.sealNo34 != "") {
      dataSealNoArraylist.push(data.sealNo34)
    }

    if (data.sealNo35 != null && data.sealNo35 != undefined && data.sealNo35 != "") {
      dataSealNoArraylist.push(data.sealNo35)
    }

    if (data.sealNo36 != null && data.sealNo36 != undefined && data.sealNo36 != "") {
      dataSealNoArraylist.push(data.sealNo36)
    }

    if (data.sealNo37 != null && data.sealNo37 != undefined && data.sealNo37 != "") {
      dataSealNoArraylist.push(data.sealNo38)
    }

    if (data.sealNo38 != null && data.sealNo38 != undefined && data.sealNo38 != "") {
      dataSealNoArraylist.push(data.sealNo38)
    }

    if (data.sealNo39 != null && data.sealNo39 != undefined && data.sealNo39 != "") {
      dataSealNoArraylist.push(data.sealNo39)
    }

    if (data.sealNo40 != null && data.sealNo40 != undefined && data.sealNo40 != "") {
      dataSealNoArraylist.push(data.sealNo40)
    }

    if (dataSealNoArraylist.length > 0) {
      data.sealNo = dataSealNoArraylist.join(":");
    } else {
      data.sealNo = ""
    }
  }
  const tradeParty = watch('tradeParty')

  function handleCloneTradeParty(data) {
    const tradeParty = [...getValues('tradeParty'), data].map(
      (tradeParty, index) => ({ ...tradeParty, _id: index + 1 }))

    setValue('tradeParty', tradeParty, { shouldValidate: true })
  }

  function handleAddTradeParty(data) {
    const tradeParty = [...getValues('tradeParty'), data].map(
      (tradeParty, index) => ({ ...tradeParty, _id: index + 1 }))

    setValue('tradeParty', tradeParty, { shouldValidate: true })
  }

  function handleDeleteTradeParty(data) {
    const tradeParty = [...getValues('tradeParty')].filter(
      (tradeParty) => tradeParty.id ? tradeParty.id !== data.id : tradeParty._id !== data._id
    )

    setValue('tradeParty', tradeParty, { shouldValidate: true })
  }

  function handleEditTradeParty(data) {
    const tradeParty = [...getValues('tradeParty')]
    const index = tradeParty.findIndex(
      (tradeParty) => tradeParty.id ? tradeParty.id === data.id : tradeParty._id === data._id
    )

    tradeParty[index] = data

    setValue('tradeParty', tradeParty, { shouldValidate: true })
  }

  function makeTranslatedTextsObject() {
    let ehblManifest = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TITLE
    )
    let corpid = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CORPID
    )
    let movementType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MOVEMENT_TYPE
    )
    let indicatorMode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.INDICATOR_MODE
    )
    let primaryCcn = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.PRIMARY_CCN
    )
    let carrierCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CARRIER_CODE
    )
    let portOfDischargeCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.PORT_OF_DISCHARGE_CODE
    )
    let portOfDestCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.PORT_OF_DEST_CODE
    )
    let messageFuncCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MESSAGE_FUNC_CODE
    )
    let consolidationInd = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CONSOLIDATION_IND
    )
    let totalWeight = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_WEIGHT
    )
    let status = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.STATUS
    )
    let closeStatus = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CLOSE_STATUS
    )
    let responseDatetime = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.RESPONSE_DATETIME
    )
    let serviceBureauId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SERVICE_BUREAU_ID
    )
    let userRole = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.USER_ROLE
    )
    let email = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.EMAIL
    )
    let mobileNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MOBILE_NO
    )
    let totalWeightUom = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_WEIGHT_UOM
    )
    let myReferenceNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MY_REFERENCE_NO
    )
    let destSubLocCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.DEST_SUB_LOC_CODE
    )
    let discSubLocCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.DISC_SUB_LOC_CODE
    )
    let clStatus = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_STATUS
    )
    let clMsgFuncCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_MSG_FUNC_CODE
    )
    let clAmendCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_AMEND_CODE
    )
    let clUniqueRefNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_UNIQUE_REF_NO
    )
    let containerizedInd = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CONTAINERIZED_IND
    )
    let totalVolume = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_VOLUME
    )
    let totalVolumeUom = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_VOLUME_UOM
    )
    let cargoQuantity = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CARGO_QUANTITY
    )
    let quantityUom = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.QUANTITY_UOM
    )
    let b2bComment = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.B2B_COMMENT
    )
    let totalHbl = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_HBL
    )
    let partyId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SUBMITTED_DATE
    )
    let containerDetails = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.HBL_ID
    )
    let manifestId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.MANIFEST_ID
    )
    let mfId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.MF_ID
    )
    let equipmentId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.EQUIPMENT_ID
    )
    let equipmentType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.EQUIPMENT_TYPE
    )
    let sealNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO
    )
    let tradeParty = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.TITLE
    )
    let partyName = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.PARTY_NAME
    )
    let partyType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.PARTY_TYPE
    )
    let division = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.DIVISION
    )
    let locPortCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.LOC_PORT_CODE
    )
    let poBoxNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.PO_BOX_NO
    )
    let address = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.ADDRESS
    )
    let city = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.CITY
    )
    let stateCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.STATE_CODE
    )
    let countryCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.COUNTRY_CODE
    )
    let postalCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.POSTAL_CODE
    )
    let contactName = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.CONTACT_NAME
    )
    let telephoneNum = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.TELEPHONE_NUM
    )
    let snpType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_TYPE
    )
    let snpUcn = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_UCN
    )
    let snpB2bComment = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_B2B_COMMENT
    )
    let snpIdentifierValue = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_IDENTIFIER_VALUE
    )

    let updateButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.UPDATE_BUTTON
    )

    let saveButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SAVE_BUTTON
    )

    return {
      ehblManifest,
      corpid,
      movementType,
      indicatorMode,
      primaryCcn,
      carrierCode,
      portOfDischargeCode,
      portOfDestCode,
      messageFuncCode,
      consolidationInd,
      totalWeight,
      status,
      closeStatus,
      responseDatetime,
      serviceBureauId,
      userRole,
      email,
      mobileNo,
      totalWeightUom,
      myReferenceNo,
      destSubLocCode,
      discSubLocCode,
      clStatus,
      clMsgFuncCode,
      clAmendCode,
      clUniqueRefNo,
      containerizedInd,
      totalVolume,
      totalVolumeUom,
      cargoQuantity,
      quantityUom,
      b2bComment,
      totalHbl,
      partyId,
      submittedBy,
      submittedDate,
      containerDetails,
      hblId,
      manifestId,
      mfId,
      equipmentId,
      equipmentType,
      sealNo,
      tradeParty,
      partyName,
      partyType,
      division,
      locPortCode,
      poBoxNo,
      address,
      city,
      stateCode,
      countryCode,
      postalCode,
      contactName,
      telephoneNum,
      snpType,
      snpUcn,
      snpB2bComment,
      snpIdentifierValue,
      updateButton,
      saveButton
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection
          title={translatedTextsObject.ehblManifest}
          subheader={
            <>
              <Typography color="error" component="span" variant="caption">
                *
              </Typography>
              <Typography color="textSecondary" component="span" variant="caption">
                Mandatory fields
              </Typography>
            </>
          }
        >
          <CardContent>
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.myReferenceNo}>
                <CngTextField
                  name="myReferenceNo"
                  label={translatedTextsObject.myReferenceNo}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    setValue("myReferenceNo", e.target.value.toUpperCase(), { shouldValidate: true })
                  }}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.movementType}>
                <CngCodeMasterAutocompleteField
                  required
                  name="movementType"
                  label={translatedTextsObject.movementType}
                  disabled={disabled}
                  codeType='EHBL_MOVEMENT_TYPE'
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.indicatorMode}>
                <CngCodeMasterAutocompleteField
                  required
                  name="indicatorMode"
                  label={translatedTextsObject.indicatorMode}
                  disabled={disabled}
                  codeType='EHBL_MODE_INDICATOR'
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.primaryCcn}>
                <CngTextField
                  required
                  name="primaryCcn"
                  label={translatedTextsObject.primaryCcn}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    setValue("primaryCcn", e.target.value.toUpperCase(), { shouldValidate: true })
                  }}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.portOfDestCode}>
                <NaCustomsOfficeAutocompleteField
                  required
                  name="portOfDestCode"
                  label={translatedTextsObject.portOfDestCode}
                  lookupProps={{ filters: [{ field: 'indicator', operator: 'equal', value: 'HBL' }] }}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.destSubLocCode}>
                <NaSublocationAutocompleteField
                  required
                  name="destSubLocCode"
                  label={translatedTextsObject.destSubLocCode}
                  lookupProps={{ filters: [{ field: 'indicator', operator: 'equal', value: 'HBL' }] }}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>


              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.portOfDischargeCode}>
                <NaCustomsOfficeAutocompleteField
                  name="portOfDischargeCode"
                  label={translatedTextsObject.portOfDischargeCode}
                  lookupProps={{ filters: [{ field: 'indicator', operator: 'equal', value: 'HBL' }] }}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.discSubLocCode}>
                <NaSublocationAutocompleteField
                  name="discSubLocCode"
                  label={translatedTextsObject.discSubLocCode}
                  lookupProps={{ filters: [{ field: 'indicator', operator: 'equal', value: 'HBL' }] }}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.consolidationInd}>
                <CngSwitchField
                  name="consolidationInd"
                  label={translatedTextsObject.consolidationInd}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerizedInd}>
                <CngSwitchField
                  name="containerizedInd"
                  label={translatedTextsObject.containerizedInd}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="corpid"
                  label={translatedTextsObject.corpid}
                  disabled={disabled}
                />
              </CngGridItem>


              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="carrierCode"
                  label={translatedTextsObject.carrierCode}
                  disabled={disabled}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="messageFuncCode"
                  label={translatedTextsObject.messageFuncCode}
                  disabled={disabled}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="totalWeight"
                  label={translatedTextsObject.totalWeight}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="status"
                  label={translatedTextsObject.status}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="closeStatus"
                  label={translatedTextsObject.closeStatus}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="responseDatetime"
                  label={translatedTextsObject.responseDatetime}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="serviceBureauId"
                  label={translatedTextsObject.serviceBureauId}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="userRole"
                  label={translatedTextsObject.userRole}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="email"
                  label={translatedTextsObject.email}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="mobileNo"
                  label={translatedTextsObject.mobileNo}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="totalWeightUom"
                  label={translatedTextsObject.totalWeightUom}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="clStatus"
                  label={translatedTextsObject.clStatus}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="clMsgFuncCode"
                  label={translatedTextsObject.clMsgFuncCode}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="clAmendCode"
                  label={translatedTextsObject.clAmendCode}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="clUniqueRefNo"
                  label={translatedTextsObject.clUniqueRefNo}
                  disabled={disabled}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="totalVolume"
                  label={translatedTextsObject.totalVolume}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="totalVolumeUom"
                  label={translatedTextsObject.totalVolumeUom}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="cargoQuantity"
                  label={translatedTextsObject.cargoQuantity}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="quantityUom"
                  label={translatedTextsObject.quantityUom}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="totalHbl"
                  label={translatedTextsObject.totalHbl}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="partyId"
                  label={translatedTextsObject.partyId}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="submittedBy"
                  label={translatedTextsObject.submittedBy}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="submittedDate"
                  label={translatedTextsObject.submittedDate}
                  disabled={disabled}
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.containerDetails}>
          <ContainerDetailsTable
            data={containerDetails}
            onAddContainerDetails={() => setContainerDetailsDialog({ open: true, containerDetails: null, isView: false })}
            onEditContainerDetails={(containerDetails) => setContainerDetailsDialog({ open: true, containerDetails, isView: false })}
            onDeleteContainerDetails={handleDeleteContainerDetails}
            showNotification={showNotification}
          />
          <ContainerDetailsDialog
            containerDetails={containerDetailsDialog.containerDetails}
            isView={containerDetailsDialog.isView}
            onAddContainerDetails={handleAddContainerDetails}
            onEditContainerDetails={handleEditContainerDetails}
            open={containerDetailsDialog.open}
            onClose={() =>
              setContainerDetailsDialog({ open: false, containerDetails: null, isView: false })
            }
            showNotification={showNotification}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.tradeParty}>
          <TradePartyTable
            data={tradeParty}
            onAddTradeParty={() => setTradePartyDialog({ open: true, tradeParty: null, isView: false })}
            onEditTradeParty={(tradeParty) => setTradePartyDialog({ open: true, tradeParty, isView: false })}
            onDeleteTradeParty={handleDeleteTradeParty}
            showNotification={showNotification}
            onCloneTradeParty={handleCloneTradeParty}
          />
          <TradePartyDialog
            tradeParty={tradePartyDialog.tradeParty}
            isView={tradePartyDialog.isView}
            onAddTradeParty={handleAddTradeParty}
            onEditTradeParty={handleEditTradeParty}
            open={tradePartyDialog.open}
            onClose={() =>
              setTradePartyDialog({ open: false, tradeParty: null, isView: false })
            }
            showNotification={showNotification}
            getLookupValue={getLookupValue}
            getCountryStateLabel={getCountryStateLabel}
          />
          <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.b2bComment}>
            <CngTextField
              multiline
              rows={4}
              name="b2bComment"
              label={translatedTextsObject.b2bComment}
              disabled={disabled}
              onChange={(e) => { setValue('b2bComment', e.target.value.toUpperCase().replace(/(\r\n|\r|\n)+/g, ' ')) }}
            />
          </CngGridItem>
        </CngSection>

      </Grid>
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  localData.responseDatetime = DateTimeFormatter.toClientDate(
    localData.responseDatetime
  );
  localData.submittedDate = DateTimeFormatter.toClientDate(
    localData.submittedDate
  );
   localData.isDangerousGoods = localData.isDangerousGoods == "Y" ? true : false
  localData.containerizedInd = localData.containerizedInd == "Y" ? true : false
  localData.consolidationInd = localData.consolidationInd == "Y" ? true : false
  return localData;
}

function toServerDataFormat(localData) {
  localData.responseDatetime = DateTimeFormatter.toServerDate(
    localData.responseDatetime
  );
  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  );
  localData.isDangerousGoods = localData.isDangerousGoods == true ? "Y" : "N"
  localData.containerizedInd = localData.containerizedInd == true ? "Y" : "N"
  localData.consolidationInd = localData.consolidationInd == true ? "Y" : "N"
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
