import React, { useCallback, useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { getStatusMetadata } from '../../../../common/NACommon'
import CngField from '../../../../components/cngcomponents/CngField'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import moment from 'moment'
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Typography,
  TextField 
} from '@material-ui/core'

const {
  button: { CngButton, CngIconButton },
  CngDialog,
  form: {
    field: {
      CngTextField
    },
  },
} = components

function PropagateDialog(props) {
  const {
    amendmentCodeOptions,
    onClose,
    onPropagateHouseBill,
    open,
    houseBills,
    translatedTextsObject,
    showNotification
  } = props

  const [prevCcn,setPrevCcn] = useState(null)

  function updatePrevCcn(){
    houseBills.forEach((houseBill) => {
      houseBill.prevCcn = prevCcn
    })
  }

  return (
    <CngDialog
      dialogContent={
        <Grid container spacing={2}>
           <Grid item xs={12} >
              <StatusItem
                updatePrevCcn={setPrevCcn}
                translatedTextsObject={translatedTextsObject}
              />
              </Grid>
        </Grid>
      }
      dialogAction={
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onClose} size='medium'>
              Back
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container justify='flex-end' spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => {
                    updatePrevCcn()
                    onPropagateHouseBill(houseBills,prevCcn)
                  }}
                  size='medium'
                >
                  {translatedTextsObject.propagate}
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogTitle='Set response message'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default PropagateDialog

function StatusItem(props) {
  const {
    updatePrevCcn,
    translatedTextsObject
  } = props

  return (
    <Paper variant='outlined'>
      <Box padding={2}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs={12} >
            <TextField id="prevCcn" 
            label={translatedTextsObject.prevCcn} 
            variant="outlined" 
            required
            fullWidth
            onChange={(event) =>
              updatePrevCcn(event.target.value)
            }
            />
          </Grid>
        </Grid>  
      </Box>
    </Paper>
  )
}
