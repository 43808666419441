import React, { useState, useRef, useEffect, useCallback } from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import { generatePath } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Chip, Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pathMap from '../../paths/pathMap'
import Namespace from 'src/constants/locale/Namespace'
import EquipmentMasterKeys from 'src/constants/locale/key/EquipmentMaster'
import EquipmentMasterApiUrls from 'src/apiUrls/EquipmentMasterApiUrls'
import MedpidApiUrls from 'src/apiUrls/AceHighwayMedpidApiUrls'
import { getStatusMetadata } from '../../common/NACommon'
import Table from '../../components/aciacehighway/Table'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import _ from 'lodash'
import moment from 'moment-timezone'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";

const {
  button: { CngButton },
  form: {
    field: { CngSelectField }
  },
  table: { useFetchCodeMaintenanceLookup },
  CngDialog
} = components

const {
  CodeMaintenanceType,
  filter: { BETWEEN, EQUAL, LIKE, IN }
} = constants

function TablePage(props) {
  const { deleteRecord, securedSendRequest } = useServices()

  const tableRef = useRef()
  const { history, showNotification } = props

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.EQUIPMEMENT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [lookups, setLookups] = useState(null)
  const [loading, setLoading] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, equipment: null })
  const [equipmentSubmitDialog, setEquipmentSubmitDialog] = useState({ open: false, equipment: [] })
  const [user, setUser] = useState([]);
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'EQ_MASTER_STATUS' }
      ])
    ]).then(([status]) => {
      setLookups({ status })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let acctId = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.ACCT_ID
    )
    let equipmentId = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.EQUIPMENT_ID
    )
    let carrierCode = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.CARRIER_CODE
    )
    let equipmentNo = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.EQUIPMENT_NO
    )
    let equipmentType = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.EQUIPMENT_TYPE
    )
    let aceId = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.ACE_ID
    )
    let status = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.STATUS
    )
    let submissionDate = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.SUBMISSION_DATE
    )

    let responseDate = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.RESPONSE_DATE
    )
    let submitSentRecords = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.SUBMIT_SENT_RECORDS
    )
    let submitSuccessMessage = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.SUBMIT_SUCCESS_MESSAGE
    )

    let submitErrorMessage = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.SUBMIT_ERROR_MESSAGE
    )

    let editButton = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.EDIT_BUTTON
    )

    let editSentRecords = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.EDIT_SENT_RECORDS
    )

    let deleteButton = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.DELETE_BUTTON
    )

    let deleteSentRecords = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.DELETE_SENT_RECORDS
    )

    let submitButton = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.SUBMIT_BUTTON
    )

    return {
      acctId,
      equipmentId,
      carrierCode,
      equipmentNo,
      equipmentType,
      aceId,
      status,
      submissionDate,
      responseDate,
      submitSentRecords,
      submitSuccessMessage,
      submitErrorMessage,
      editButton,
      editSentRecords,
      deleteButton,
      deleteSentRecords,
      submitButton
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  // Currently unused, pending enhancment on Table component
  const getLookupFilterOptions = useCallback((key) => {
    if (!lookups || !lookups?.[key]) return []

    return Object.entries(lookups[key]).reduce(
      (acc, [key, value]) => [
        ...acc,
        {
          label: value,
          value: key,
          filterValue: { value: key }
        }
      ],
      []
    )
  }, [lookups])

  const columns = [
    {
      field: "equipmentId",
      title: translatedTextsObject.equipmentId,
    },
    {
      field: "carrierCode",
      title: translatedTextsObject.carrierCode,
    },
    {
      field: "equipmentNo",
      title: translatedTextsObject.equipmentNo,
    },
    {
      field: "equipmentType",
      title: translatedTextsObject.equipmentType,
    },
    {
      field: "aceId",
      title: translatedTextsObject.aceId,
    },
    {
      field: "status",
      title: translatedTextsObject.status,
      render: (data) => {
        if (!data.status) return

        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={getLookupValue('status', data.status)}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor
            }}
          />
        )
      }
    },
    {
      field: "submittedDate",
      title: translatedTextsObject.submissionDate,
      render: (rowData) =>
        rowData.submittedDate &&
        moment(rowData.submittedDate).tz('Canada/Eastern').format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: "responseDate",
      title: translatedTextsObject.responseDate,
      render: (rowData) =>
        rowData.responseDate &&
        moment(rowData.responseDate).tz('Canada/Eastern').format('DD/MM/YYYY HH:mm:ss')
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.equipmentId,
      type: 'textfield',
      name: 'equipmentId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.carrierCode,
      type: 'textfield',
      name: 'carrierCode',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.equipmentNo,
      type: 'textfield',
      name: 'equipmentNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.equipmentType,
      type: 'textfield',
      name: 'equipmentType',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.aceId,
      type: 'textfield',
      name: 'aceId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: "Draft",
          value: "DR",
          filterValue: {
            value: "DR"
          }
        },
        {
          label: "Sent",
          value: "ST",
          filterValue: {
            value: "ST"
          }
        },
        {
          label: "Accepted",
          value: "AC",
          filterValue: {
            value: "AC"
          }
        },
        {
          label: "Rejected",
          value: "RJ",
          filterValue: {
            value: "RJ"
          }
        },
        {
          label: "Deactivated",
          value: "DA",
          filterValue: {
            value: "DA"
          }
        }
      ]
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.responseDate,
      type: 'daterange',
      name: 'responseDate',
      operatorType: BETWEEN
    }
  ]

  function handleDeleteEquipment() {
    if (confirmDialog.equipment) {
      deleteRecord.execute(EquipmentMasterApiUrls.DELETE, confirmDialog.equipment,
        () => {
          showNotification('success', 'Equipment deleted successfully.')
          setConfirmDialog({ open: false, equipment: null })

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => console.log(error)        
      )
    }
  }

  function handleSubmitEquipment({ messageFunction }) {
    if (_.isEmpty(equipmentSubmitDialog.equipment)) return

    setLoading(true)
    let result = [...equipmentSubmitDialog.equipment]
    const fileForUserDetails = FileForUserGetUserDetails()

    result.forEach((equipment) => {
      if (fileForUserDetails) {
        const { fileForUserId, fileForUserPartyId, fileForUserLoginId } = fileForUserDetails

        equipment['fileForUserId'] = fileForUserId
        equipment['fileForUserPartyId'] = fileForUserPartyId
        equipment['fileForUserLoginId'] = fileForUserLoginId
      }

      equipment['lastMsgAction'] = messageFunction
    })

    try {
      securedSendRequest.execute('POST', MedpidApiUrls.SUBMIT_ACE_EQUIPMENT, result,
        (response) => {
          const { errorMessages } = response.data[0]

          if (!_.isEmpty(errorMessages)) {
            errorMessages.forEach((message) =>
              showNotification('error', message)
            )
          } else {
            showNotification(
              'success',
              translatedTextsObject.submitSuccessMessage
            )
          }
          if (tableRef.current?.performRefresh) {
              tableRef.current.performRefresh()
            }

          setEquipmentSubmitDialog({ open: false, equipment: [] })
        },
        () => showNotification('error', translatedTextsObject.submitErrorMessage),
        () => setLoading(false)
      )
    } catch (error) {
      showNotification('error', translatedTextsObject.submitErrorMessage)
      setLoading(false)
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.EQUIPMENT_MASTER_ADD_VIEW)
            },
            label: 'Create Equipment Master'
          }
        ]}
        checkboxSelection
        columns={columns}
        compact
        fetch={{ url: EquipmentMasterApiUrls.GET }}
        customExportData={{ url: EquipmentMasterApiUrls.EXPORT_CSV, accId: user.loginId, partyId: FileForUserGetPartyId() }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.status === 'ST') {
            history.push(generatePath(pathMap.EQUIPMENT_MASTER_DETAILS_VIEW, { id: rowData.id }), { headerId: rowData.equipmentId })
          } else {
            history.push(generatePath(pathMap.EQUIPMENT_MASTER_EDIT_VIEW, { id: rowData.id }), { headerId: rowData.equipmentId })
          }
        }}
        rowActions={[
          {
            disabled: (rowData) => rowData.status === 'ST',
            label: 'Edit',
            icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
            onClick: (rowData) =>
              history.push(
                generatePath(pathMap.EQUIPMENT_MASTER_EDIT_VIEW, { id: rowData.id }),
                { headerId: rowData.equipmentId }
              ),
            tooltip: (rowData) =>
              rowData.status === 'ST'
                ? translatedTextsObject.editSentRecords
                : null
          },
          {
            disabled: (rowData) => rowData.status && rowData.status !== 'DR',
            label: 'Delete',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (rowData) => setConfirmDialog({ open: true, equipment: rowData }),
            tooltip: (rowData) => rowData.status && rowData.status !== 'DR'
                ? translatedTextsObject.deleteSentRecords
                : null
          }
        ]}
        selectActions={[
          {
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: translatedTextsObject.submitButton,
            onClick: (rows) =>
              setEquipmentSubmitDialog({ open: true, equipment: rows }),
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  includesSentRecords = true
                }
              })

              return includesSentRecords ? translatedTextsObject.submitSentRecords : null
            }
          }
        ]}
        showNotification={showNotification}
        persistSettings
        tableRef={tableRef}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, equipment: null })}
        onCancel={() => setConfirmDialog({ open: false, equipment: null })}
        onConfirm={handleDeleteEquipment}
        title='Discard'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
      <EquipmentSubmitDialog
        onClose={() => setEquipmentSubmitDialog(false)}
        onSubmit={handleSubmitEquipment}
        open={equipmentSubmitDialog.open}
        loading={loading}
      />
    </>
  )
}

function EquipmentSubmitDialog(props) {
  const { onClose, onSubmit, open, loading } = props

  const methods = useForm({
    defaultValues: { messageFunction: '23' }
  })
  const { translate } = useTranslation(Namespace.EQUIPMEMENT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const cancelButton = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.CANCEL_BUTTON
    )
    const submitButton = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.SUBMIT_BUTTON
    )
    const submitDialogTitle = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.SUBMIT_DIALOG_TITLE
    )
    const msgFuncType = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.MSG_FUNC_TYPE
    )
    const msgFuncAdd = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.MSG_FUNC_ADD
    )
    const msgFuncDeactivate = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.MSG_FUNC_DEACTIVATE
    )
    const msgFuncModify = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.MSG_FUNC_MODIFY
    )

    return {
      cancelButton,
      submitButton,
      submitDialogTitle,
      msgFuncType,
      msgFuncAdd,
      msgFuncDeactivate,
      msgFuncModify
    }
  }

  return (
    <CngDialog
      customDialogContent={
        <FormProvider {...methods}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CngSelectField
                  name='messageFunction'
                  label={translatedTextsObject.msgFuncType}
                  items={[
                    { text: translatedTextsObject.msgFuncAdd, value: '23' },
                    { text: translatedTextsObject.msgFuncDeactivate, value: '129' },
                    { text: translatedTextsObject.msgFuncModify, value: '36' }
                  ]}
                  isRequired
                  size='small'
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify='space-between' spacing={2}>
                  <Grid item xs='auto'>
                    <CngButton color='secondary' onClick={onClose} size='medium' disabled={loading}>
                      {translatedTextsObject.cancelButton}
                    </CngButton>
                  </Grid>
                  <Grid item xs='auto'>
                    <CngButton onClick={methods.handleSubmit(onSubmit)} size='medium' disabled={loading}>
                      {translatedTextsObject.submitButton}
                    </CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      }
      dialogTitle={translatedTextsObject.submitDialogTitle}
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
    />
  )
}

export default TablePage
