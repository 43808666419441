import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const errMsgAlphaNumeric = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC
  )
  const errMsgAlphaNumericWithSpecialChar = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR
  )

  const errMsgAlphaNumericWithSpecialCharNoSpace = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR_NO_SPACE
  )

  const errMsgAlphaNumericWithHypen = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_HYPEN
  )

  const errMsgNumeric = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )

  const errMsgMinLength = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_MIN_LENGTH
  )
  const errMsgMaxLength = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )

  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumeric = '^[a-zA-Z0-9 ]*$'
  const regexAlphaNumericWithSpecialChar =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  const regexAlphaNumericWithSpecialCharNoSpace =
    '^[a-zA-Z0-9,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\]+$'

  return Yup.object({
    corpid: Yup.string().nullable(),
    movementType: Yup.string().nullable(),
    indicatorMode: Yup.string().nullable(),
    primaryCcn: Yup.string().required(requiredMessage).matches(regexAlphaNumericWithSpecialCharNoSpace, errMsgAlphaNumericWithSpecialCharNoSpace).max(25, errMsgMaxLength + " 25"),
    carrierCode: Yup.string().nullable(),
    portOfDischargeCode: Yup.string().nullable(),
    portOfDestCode: Yup.string().nullable().required(requiredMessage),
    messageFuncCode: Yup.string().nullable(),
    consolidationInd: Yup.string().nullable(),
    totalWeight: Yup.string().nullable(),
    status: Yup.string().nullable(),
    closeStatus: Yup.string().nullable(),
    responseDatetime: Yup.date().nullable().validFormat().typeError(dateTypeErrorMessage),
    serviceBureauId: Yup.string().nullable(),
    userRole: Yup.string().nullable(),
    email: Yup.string().nullable(),
    mobileNo: Yup.string().nullable(),
    totalWeightUom: Yup.string().nullable(),
    myReferenceNo: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(20, errMsgMaxLength + " 20"),
    destSubLocCode: Yup.string().nullable().required(requiredMessage),
    discSubLocCode: Yup.string().nullable(),
    clStatus: Yup.string().nullable(),
    clMsgFuncCode: Yup.string().nullable(),
    clAmendCode: Yup.string().nullable(),
    clUniqueRefNo: Yup.string().nullable(),
    containerizedInd: Yup.string().nullable(),
    totalVolume: Yup.string().nullable(),
    totalVolumeUom: Yup.string().nullable(),
    cargoQuantity: Yup.string().nullable(),
    quantityUom: Yup.string().nullable(),
    b2bComment: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(256, errMsgMaxLength + " 256"),
    totalHbl: Yup.string().nullable(),
    partyId: Yup.string().nullable(),
    submittedBy: Yup.string().nullable(),
    submittedDate: Yup.date().nullable().validFormat().typeError(dateTypeErrorMessage),
    containerDetails: Yup.array().nullable(),
    tradeParty: Yup.array().nullable(),
  })
}

export default makeValidationSchema
