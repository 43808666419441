import EhblManifestApiUrls from 'src/apiUrls/EhblManifestApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useHistory } from 'react-router-dom'
import ConfirmDialog from '../../common/ConfirmDialog'
import { useFormContext } from 'react-hook-form'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function ManifestEditPage(props) {
  const {
    id,
    manifestData,
    onChangeStep,
    onSetLoading,
    onSetManifestStatus,
    onSubmitManifest,
    showNotification,
    step
  } = props

  const onSuccessCallback = useRef(() => {})

  const [lookups, setLookups] = useState(null)
  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
              getLookupValue={getLookupValue}
              getCountryStateLabel={getCountryStateLabel}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: EhblManifestApiUrls.GET,
            onPreSuccess: (serverData) => {
              manifestData.current = serverData
              onSetManifestStatus(serverData.status)
            }
          }}
          update={{
            url: EhblManifestApiUrls.PUT,
            onPostSubmitSuccess: (e) => {
              manifestData.current = e
              onSetManifestStatus(e.status)
              onSuccessCallback.current()
            }
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <ManifestEditPageButtonSection
              manifestId={id}
              showNotification={showNotification}
              onSaveOnly={() => {
                onSuccessCallback.current = () => {
                  onSetLoading(false)
                }
              }}
              onSaveAndContinue={() => {
                onSuccessCallback.current = () => {
                  onChangeStep(step + 1)
                  onSetLoading(false)
                }
              }}
              loading={loading}
              onSetLoading={onSetLoading}
              onSkip={() => onChangeStep(step + 1)}
              onSubmitManifest={(data) => {
                manifestData.current = data
                onSubmitManifest()
              }}
              
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default ManifestEditPage

function ManifestEditPageButtonSection(props) {
  const {
    loading,
    onSaveOnly,
    onSaveAndContinue,
    onSetLoading,
    onSkip,
    onSubmitManifest
  } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const { getValues, trigger } = useFormContext()
  const history = useHistory()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                disabled={loading}
                onClick={onSkip}
                size='medium'
              >
                Skip
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={onSaveOnly}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Save
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={onSaveAndContinue}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Save &amp; continue
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}e
        confirmDialog={() => history.push(pathMap.EHBL_MANIFEST_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
