import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import pathMap from '../../../paths/pathMap'
import ResponseLog from '../manifest/ResponseLog'
import { Box, Grid } from '@material-ui/core'

const { button: { CngButton } } = components

function ViewLogsHistory(props) {
  const { history, manifestId, onChangeStep, step } = props

  return (
    <>
      <ResponseLog manifestId={manifestId}/>
      <Box marginTop={2}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md='auto'>
            <CngButton
              color='secondary'
              onClick={() =>
                history.push(pathMap.EHBL_MANIFEST_LIST_VIEW)
              }
              size='medium'
            >
              Discard
            </CngButton>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton color='secondary' onClick={() => onChangeStep(step - 1)} size='medium'>
                  Back
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => onChangeStep(step - 3)}
                  size='medium'>
                  Go to Submit
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ViewLogsHistory
