import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import {
  Box,
  Grid,
  Paper} from '@material-ui/core'
import useCustomYupValidationResolver from 'src/components/aciacehighway/hooks/useCustomYupValidationResolver'
import * as Yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
const {
  button: { CngButton },
  CngDialog,
  form: {
    field: {
      CngTextField
    }
  }
} = components
const schema = Yup.object({
  carrierName: Yup.string().nullable()
})
function PrintMasterBillDialog(props) {
  const {
    manifestId,
    onClose,
    onSubmitPrint,
    open,
    translatedTextsObject
  } = props

  const [carrierName, setCarrierName] = useState('')

  return (
    <CngDialog
      dialogContent={
        <Box paddingTop={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <StatusItem
                    carrierName={carrierName}
                    setCarrierName={setCarrierName}
                    translatedTextsObject={translatedTextsObject}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
      dialogAction={
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onClose} size='medium'>
              Back
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container justify='flex-end' spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => {
                    onSubmitPrint({
                      manifestId,
                      carrierName: carrierName
                    })
                  }}
                  size='medium'
                >
                  Print
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogTitle='Print Master Bill'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default PrintMasterBillDialog

function StatusItem(props) {
  const {
    setCarrierName  } = props
  const handleChange = (e) => {
    console.log("CarrierName" + e.target.value);
    setCarrierName(e.target.value)
  };
  const methods = useForm({
    defaultValues: { carreierName: '' },
    resolver: useCustomYupValidationResolver(schema)
  })
  return (
    <Paper variant='outlined'>
      <Box padding={2}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs={12} md>
            <Grid container spacing={2}>
              <Grid item xs={12} md>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormProvider {...methods}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                              <CngTextField
                                name="carreierName"
                                label="Carrier Name (Optional)"
                                disabled={false}
                                size='small'
                                onChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </FormProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper >
  )
}
