import makeValidationSchema from './TradePartyMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React,{useState,useEffect} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'
import {
  components,
  constants
} from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Divider, Grid, IconButton, InputAdornment, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import _ from 'lodash'
import TemplateSelectDialog from '../../../components/aciacehighway/TemplateSelectDialog'
import  PartyMasterApiUrls  from '../../../apiUrls/PartyMasterApiUrls'
import {NaStateCodeAutoCompleteField} from 'src/components/na'
import { NaAcePartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'


const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCheckboxField,
      CngCountryAutocompleteField
    },
  },
  CngGridItem,
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { LIKE, EQUAL,IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  hblId: "",
  manifestId: "",
  mfId: "",
  corpid: "",
  partyId: "",
  partyName: "",
  partyType: "",
  division: "",
  locPortCode: "",
  poBoxNo: "",
  address: "",
  city: "",
  stateCode: "",
  countryCode: "",
  postalCode: "",
  contactName: "",
  telephoneNum: "",
  snpType: "",
  snpUcn: "",
  snpB2bComment: "",
  snpIdentifierValue: "",
  saveFlag: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { setValue, getValues, reset, watch, trigger } = useFormContext();
  const { translate } = useTranslation(Namespace.EHBL_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const saveFlag = watch('saveFlag');
  const countryCode = watch('countryCode');
  const stateCode = watch('stateCode');
  const partyType = watch('partyType')
  const snpType = watch('snpType')
  const [templateDialog, setTemplateDialog] = useState(false)
  const [lookups, setLookups] = useState(null)

  useEffect(() => {
    Promise.all([
      // Party Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_MANIFEST_PARTY_TYPE' }],
        undefined,
        'code'
      )
    ]).then(([partyType]) => {
      setLookups({
        partyType
      })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: 'partyidn',
      title: translatedTextsObject.partyIdentification
    },
    {
      field: 'partytype',
      title: translatedTextsObject.partyType,
      render: data => 
      
      <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
      {getLookupValue('partyType', data.partytype)}
       </Typography>
    },
    {
      title: translatedTextsObject.partyName,
      render: data => [data.partyname1, data.partyname2].join(' ')
    },
    {
      field: 'statecode',
      title: translatedTextsObject.stateCode
    },
    {
      field: 'countrycode',
      title: translatedTextsObject.countryCode
    },
  ]

  const sortOptions = [
    {
      label: translatedTextsObject.partyIdentification,
      value: 'partyidn'
    },
    {
      label: translatedTextsObject.partyType,
      value: 'partyType'
    },
  ]

  function handleApplyTemplate(template) {
    if (template) {
      const {
        partyname1,
        address1,
        cityname,
        postalcode,
        countrycode,
        statecode,
        contactperson,
        telephonenumber,
        partyIdentifier
      } = template

      let partytype = getValues("partyType")
      setValue('partyName', partyname1 || '', { shouldDirty: true })

      if(partytype!=="SNP"){
        setValue('address', address1 || '', { shouldDirty: true })
        setValue('city', cityname || '', { shouldDirty: true })
        setValue('postalCode', postalcode || '', { shouldDirty: true })
        setValue('countryCode', countrycode || '', { shouldDirty: true })
        setValue('stateCode', statecode || '', { shouldDirty: true })
        setValue('contactName', contactperson || '', { shouldDirty: true })
        setValue('telephoneNum', telephonenumber || '', { shouldDirty: true })
      } else {
        if(partyIdentifier!=null && partyIdentifier!=undefined && partyIdentifier!=""){
          for (var i = 0, l = partyIdentifier.length; i < l; i++) {
            var obj = partyIdentifier[i];
            if(obj.partyIdentifierCode=="SNPTYPE"){
              setValue('snpType',obj.partyIdentifierNumber , { shouldDirty: true })
            }

            if(obj.partyIdentifierCode=="SNPVALUE"){
              setValue('snpIdentifierValue',obj.partyIdentifierNumber , { shouldDirty: true })
            }
          }
        }
      }

      trigger()
    } else {
      reset()
    }
  }

  function makeTranslatedTextsObject() {
    let tradeParty = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.HBL_ID
    )
    let manifestId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.MANIFEST_ID
    )
    let mfId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.MF_ID
    )
    let corpid = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.CORPID
    )
    let partyId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.PARTY_ID
    )
    let partyName = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.PARTY_NAME
    )
    let partyType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.PARTY_TYPE
    )
    let division = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.DIVISION
    )
    let locPortCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.LOC_PORT_CODE
    )
    let poBoxNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.PO_BOX_NO
    )
    let address = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.ADDRESS
    )
    let city = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.CITY
    )
    let stateCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.STATE_CODE
    )
    let countryCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.COUNTRY_CODE
    )
    let postalCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.POSTAL_CODE
    )
    let contactName = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.CONTACT_NAME
    )
    let telephoneNum = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.TELEPHONE_NUM
    )
    let snpType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_TYPE
    )
    let snpUcn = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_UCN
    )
    let snpB2bComment = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_B2B_COMMENT
    )
    let snpIdentifierValue = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_IDENTIFIER_VALUE
    )

    let snpAccountSecurityNumber = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_ACCOUNT_SECURITY_NUMBER
    )

    let snpFreightForwarderCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_FREIGHT_FORWARDER_CODE
    )

    let snpCbsaCarrierCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_CBSA_CARRIER_CODE
    )

    let snpWarehouse = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_WAREHOUSE
    )

    let snpBusinessNumber = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_BUSINESS_NUMBER
    )

    let saveFlag = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SAVE_FLAG
    )

    return {
      tradeParty,
      hblId,
      manifestId,
      mfId,
      corpid,
      partyId,
      partyName,
      partyType,
      division,
      locPortCode,
      poBoxNo,
      address,
      city,
      stateCode,
      countryCode,
      postalCode,
      contactName,
      telephoneNum,
      snpType,
      snpUcn,
      snpB2bComment,
      snpIdentifierValue,
      snpAccountSecurityNumber,
      snpFreightForwarderCode,
      snpCbsaCarrierCode,
      snpWarehouse,
      snpBusinessNumber,
      saveFlag
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.partyType}>
              <CngCodeMasterAutocompleteField
                required
                name="partyType"
                key={partyType}
                label={translatedTextsObject.partyType}
                disabled={disabled}
                codeType='EHBL_MANIFEST_PARTY_TYPE'
                onChange={(value) => {
                  setValue('partyType', value, { shouldDirty: true })
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} lg={8}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.saveFlag}>
                  <CngCheckboxField
                    label={
                      <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                        {translatedTextsObject.saveFlag}
                      </Typography>
                    }
                    name='saveFlag'
                    disabled={disabled}
                    onChange={(e) => {
                      setValue('saveFlag', e.target.checked)

                      if (!e.target.checked) {
                        setValue("partyId", "")
                      }
                    }}
                    size='small'
                    style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm shouldHide={shouldHideMap?.partyId}>
                  <CngTextField
                    required
                    name="partyId"
                    inputProps={{
                      style: { textTransform: 'uppercase' },
                      maxLength: 35
                    }}
                    placeholder={translatedTextsObject.partyId}
                    disabled={disabled || !saveFlag}
                    onChange={(e) => {
                      setValue("partyId", e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm='auto'>
                  <Divider orientation='vertical' />
                </CngGridItem>
                <CngGridItem xs={12} sm={4}>
                  <NaAcePartyMasterAutoCompleteField
                    name="partyDropDown"
                    label={translatedTextsObject.partyDropDown}
                    disabled={disabled}
                    onChange={(_, options) => handleApplyTemplate(options.data)}
                    size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                    lookupProps={{
                      filters: [{ field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }]
                    }}
                  />
                </CngGridItem>
              </Grid>
            </CngGridItem>
            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.partyName}>
              <CngTextField
                required
                name="partyName"
                label={translatedTextsObject.partyName}
                disabled={disabled}
                size='small'
                onChange={(e) => {
                  setValue("partyName", e.target.value.toUpperCase(), { shouldValidate: true })
                }}
              />
            </CngGridItem>

            {partyType != 'SNP' &&
          <>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.address}>
                <CngTextField
                  required={partyType != 'SNP' ? true : false}
                  name="address"
                  label={translatedTextsObject.address}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    setValue("address", e.target.value.toUpperCase(), { shouldValidate: true })
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.city}>
                <CngTextField
                  required={partyType != 'SNP' ? true : false}
                  name="city"
                  label={translatedTextsObject.city}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    setValue("city", e.target.value.toUpperCase(), { shouldValidate: true })
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.countryCode}>
                <CngCountryAutocompleteField
                  required={partyType != 'SNP' ? true : false}
                  name='countryCode'
                  label={translatedTextsObject.countryCode}
                  disabled={disabled}
                  key={countryCode}
                  onChange={() => {
                    setValue('stateCode', '', { shouldValidate: true })
                  }}
                  lookupProps={{
                    label: (record) => {
                      return `${record.code};${record.descriptionEn}`
                    }
                  }}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.stateCode}>
                <NaStateCodeAutoCompleteField
                  required={partyType != 'SNP' && (countryCode == 'CA' || countryCode == 'US') ? true : false}
                name='stateCode'
                label={translatedTextsObject.stateCode}
                disabled={disabled}
                countryCode={countryCode}
                key={countryCode + stateCode}
                size='small'
              />
                </CngGridItem>

                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.postalCode}>
                  <CngTextField
                    name="postalCode"
                    label={translatedTextsObject.postalCode}
                    disabled={disabled}
                    size='small'
                    onChange={(e) => {
                      setValue("postalCode", e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.contactName}>
                  <CngTextField
                    name="contactName"
                    label={translatedTextsObject.contactName}
                    disabled={disabled}
                    size='small'
                    onChange={(e) => {
                      setValue("contactName", e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.telephoneNum}>
                  <CngTextField
                    name="telephoneNum"
                    label={translatedTextsObject.telephoneNum}
                    disabled={disabled}
                    size='small'
                    onChange={(e) => {
                      setValue("telephoneNum", e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                  />
                </CngGridItem>
              </>
            }
            {partyType == 'SNP' &&
          <>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.snpType}>
                <CngCodeMasterAutocompleteField
                required
                name="snpType"
                label={translatedTextsObject.snpType}
                disabled={disabled}
                codeType='EHBL_SNP_TYPE'
                onChange={(value) => {
                  setValue('snpType', value, { shouldDirty: true })
                }}
                size='small'
              />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.snpIdentifierValue}>
                <CngTextField
                  name="snpIdentifierValue"
                  label={
                    snpType == 'CB' ? translatedTextsObject.snpAccountSecurityNumber :
                      snpType == 'FW' ? translatedTextsObject.snpFreightForwarderCode :
                        snpType == 'CA' ? translatedTextsObject.snpCbsaCarrierCode :
                          snpType == 'WH' ? translatedTextsObject.snpWarehouse :
                            snpType == 'IM' ? translatedTextsObject.snpBusinessNumber : translatedTextsObject.snpIdentifierValue
                  }
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    setValue("snpIdentifierValue", e.target.value.toUpperCase(), { shouldValidate: true })
                  }}
                />
              </CngGridItem>
          </>
            }
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="snpUcn"
                label={translatedTextsObject.snpUcn}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="snpB2bComment"
                label={translatedTextsObject.snpB2bComment}
                disabled={disabled}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} lg={4} shouldHide={true}>
              <CngTextField
                name="division"
                label={translatedTextsObject.division}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} lg={4} shouldHide={true}>
              <CngTextField
                name="locPortCode"
                label={translatedTextsObject.locPortCode}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} lg={4} shouldHide={true}>
              <CngTextField
                name="poBoxNo"
                label={translatedTextsObject.poBoxNo}
                disabled={disabled}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="hblId"
                label={translatedTextsObject.hblId}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="manifestId"
                label={translatedTextsObject.manifestId}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="mfId"
                label={translatedTextsObject.mfId}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="corpid"
                label={translatedTextsObject.corpid}
                disabled={disabled}
              />
            </CngGridItem>
          </Grid>
        </Grid>
      </Grid>
      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'partyidn', direction: 'ASC' }]}
        fetch={{ url: PartyMasterApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'partyidn', operator: LIKE }}
        sortOptions={sortOptions}
        title='Template'
      />
    </>
  )
}

const TradePartyFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TradePartyFormProperties
