import React, { useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import FormProperties from '../../closemsg/FormProperties'
import { Box } from '@material-ui/core'
import moment from 'moment'
import CloseMsgViewContent from '../../closemsg/ViewContent'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function CloseMsgDialog(props) {
  const {
    closeMsg,
    isView,
    getLookupValue,
    getCountryStateLabel,
    manifestId,
    loading,
    onClose,
    onCreateCloseMsg,
    onEditCloseMsg,
    open,
    showNotification
  } = props
  const { initialValues, makeValidationSchema } = FormProperties.formikProps

  const { translate } = useTranslation(Namespace.EHBL_CLOSE_MSG)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let updateButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.UPDATE_BUTTON
    )
    let saveButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.SAVE_BUTTON
    )


    return {
      updateButton,
      saveButton,
    }
  }

  let viewContent = (
    <CloseMsgViewContent
      data={closeMsg}
    />
  )

  if(!open){
    return null
  }
  return (
    <>
      <CngDialog
        dialogContent={
          closeMsg && isView ? (
            viewContent
          ) : (
            <CngForm
              fieldLevel='form'
              hookFormProps={{
                initialValues: {
                  ...(closeMsg || initialValues),
                  manifestId: manifestId
                },
                makeValidationSchema: makeValidationSchema,
                onSubmit: (data) => {
                    onEditCloseMsg(data)
                }
              }}
              formState={FormState.COMPLETED}
              renderBodySection={(labelMap, shouldHideMap) => (
                <FormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              )}
              innerForm={true}
              renderButtonSection={() => (
                <Box display='flex' justifyContent='flex-end'>
                  <CngButton
                    color='primary'
                    size='medium'
                    type='submit'
                    disabled={loading}
                    shouldShowProgress={loading}
                  >
                    {translatedTextsObject.updateButton}
                  </CngButton>
                </Box>
              )}
            />
          )
        }
        dialogAction={
          isView ? <CngButton onClick={onClose}>OK</CngButton> : null
        }
        dialogTitle='Close Message'
        open={open}
        onClose={onClose}
        maxWidth='lg'
        fullWidth
      />
    </>
  )
}

export default CloseMsgDialog
