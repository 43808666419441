import React from 'react'
import FaxTrackerAddPage from './FaxTrackerAddPage'
import withFormState from 'src/components/aciacehighway/hocs/withFormState'

function AddPage({ history, showNotification, onSetLoading }) {

  return (
    <FaxTrackerAddPage
      history={history}
      showNotification={showNotification}
      onSetLoading={onSetLoading}
    />
  )
}

export default withFormState(AddPage)
