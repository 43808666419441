import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { components, DataFlattener, useServices } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import FaxTrackerApiUrls from 'src/apiUrls/FaxTrackerApiUrls'
import { useFormContext } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import sweetalert from 'sweetalert'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function FaxTrackerAddPage({ history, showNotification, onSetLoading }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: FaxTrackerApiUrls.SAVE,
            successRedirect: pathMap.MAN_FAX_TRAC_LIST_VIEW
          }}
          renderButtonSection={(_, loading) => (
            <CustomButtonSection
              showNotification={showNotification}
              loading={loading}
              onSetLoading={onSetLoading}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default FaxTrackerAddPage

function CustomButtonSection(props) {
  const {
    loading,
    onSetLoading,
    showNotification,
  } = props

  const { createRecord } = useServices()
  const history = useHistory()
  const { getValues, trigger } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  async function handleSubmitFAX() {
    const values = getValues()
    onSetLoading(true)

    var serverData = FormProperties.toServerDataFormat(values)
    let result = await trigger()
    if(result){
      createRecord.execute(
        FaxTrackerApiUrls.SAVE,
        DataFlattener.unflatten(serverData),
        (result) => {
          console.log("res"+JSON.stringify(result))
          var errorMessages = result.errorMessages
          if (result !== null && (errorMessages === null || errorMessages === "" || !errorMessages)) {
            showNotification('success', 'Saved successfully')
             // history.push(pathMap.MAN_FAX_TRAC_LIST_VIEW)
             console.log("inside on "+JSON.stringify(result));
             history.push(pathMap.MAN_FAX_TRAC_MAST+`/edit/${result.id}`)
          } else {
            showNotification('error', errorMessages)
          }
        },
        (error) => {
          console.log(error)
          showNotification('error', 'Fail to save')
        },
        () => onSetLoading(false)
      )
    }else{
      onSetLoading(false)
      sweetalert("Invalid field" ,"", "error")
    }
  }

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                disabled={loading}
                onClick={handleSubmitFAX}
                size='medium'
                variant='contained'
              >
                {'Save'}
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}