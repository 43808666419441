import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import EhblResponseApiUrls from 'src/apiUrls/EhblResponseApiUrls'
import EhblResponseKeys from 'src/constants/locale/key/EhblResponse'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import { Grid } from '@material-ui/core'
import LocalTable from 'src/components/aciacehighway/LocalTable'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL },
  locale: {
    key: { UiComponentKeys }
  }
} = constants


function HouseBillResponse(props) {
  const { showNotification, id, isViewOnly = false, manifestId, housbillPage, ccn } = props

  const [data, setData] = useState([])
  const [lookups, setLookups] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  const { fetchRecords } = useServices()
  const { translate } = useTranslation([Namespace.EHBL_RESPONSE])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  function fetchHouseBillResponse() {

    let filters = [];
    if(ccn!=null && ccn!=undefined && ccn!=""){
      filters.push({
        field: 'hblCcn',
        operator: 'equal',
        value: ccn
      })
    }
    return fetchRecords.execute(EhblResponseApiUrls.GET_HOUSE_BILL_RESPONSE,
      {
        filters: [
          {
            field: 'manifestId',
            operator: 'equal',
            value: manifestId ? manifestId : (housbillPage ? manifestId : id)
          },
          ...filters
        ]
      }, (res) => res)
  }

  function showHouseBillResponse() {
    setIsFetching(true)
    Promise.all([fetchHouseBillResponse()]).then(([respData]) => {
      setData(respData)
      setIsFetching(false)
    })
  }

  useEffect(() => {
    showHouseBillResponse();
    Promise.all([
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_CBSA_PROCESSING_IND' }],
        undefined, 'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_SUB_ITEM_IDENTIFIER' }],
        undefined, 'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_MSG_FUNC_CODE' }],
        undefined, 'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'CBSA_ERROR_CODE' }],
        undefined, 'code'
      )
    ]).then(([cbsaProceessingInd, subItemIdentifier, msgFunc, cbsaErrorDesc]) => {
      setLookups({ cbsaProceessingInd, subItemIdentifier, msgFunc, cbsaErrorDesc })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let ehblResponse = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: ehblResponse
      }
    )
    let hblId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.HBL_ID
    )
    let manifestId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.MANIFEST_ID
    )
    let closemsgId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.CLOSEMSG_ID
    )
    let receiveDatetime = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.RECEIVE_DATETIME
    )
    let transSetIdentifierCode = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.TRANS_SET_IDENTIFIER_CODE
    )
    let transSetControlNo = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.TRANS_SET_CONTROL_NO
    )
    let originalTransSetStatus = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ORIGINAL_TRANS_SET_STATUS
    )
    let originalTransSetControlNo = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ORIGINAL_TRANS_SET_CONTROL_NO
    )
    let originalTransSetDatetime = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ORIGINAL_TRANS_SET_DATETIME
    )
    let reportType = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.REPORT_TYPE
    )
    let reportStatus = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.REPORT_STATUS
    )
    let primaryCcn = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.PRIMARY_CCN
    )
    let hblCcn = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.HBL_CCN
    )
    let cbsaMsgType = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.CBSA_MSG_TYPE
    )
    let cbsaRefNo = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.CBSA_REF_NO
    )
    let cbsaMsgFuncCode = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.CBSA_MSG_FUNC_CODE
    )
    let dateTime = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.DATE_TIME
    )
    let secondaryBusinessId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.SECONDARY_BUSINESS_ID
    )
    let typeOfResp = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.TYPE_OF_RESP
    )
    let erpMsgSectionCode = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ERP_MSG_SECTION_CODE
    )
    let erpUniqueRefNo = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ERP_UNIQUE_REF_NO
    )
    let erpSubitemId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ERP_SUBITEM_ID
    )
    let d4ResponseId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.D4_RESPONSE_ID
    )
    let userIdInfo = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.USER_ID_INFO
    )

    let status = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.STATUS
    )

    let responseText = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.RESPONSE_TEXT
    )

     let remarks = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.REMARKS
    )

    return {
      ehblResponse,
      tableTitle,
      hblId,
      manifestId,
      closemsgId,
      receiveDatetime,
      transSetIdentifierCode,
      transSetControlNo,
      originalTransSetStatus,
      originalTransSetControlNo,
      originalTransSetDatetime,
      reportType,
      reportStatus,
      primaryCcn,
      hblCcn,
      cbsaMsgType,
      cbsaRefNo,
      cbsaMsgFuncCode,
      dateTime,
      secondaryBusinessId,
      typeOfResp,
      erpMsgSectionCode,
      erpUniqueRefNo,
      erpSubitemId,
      d4ResponseId,
      userIdInfo,
      status,
      responseText,
      remarks
    }
  }

  const columns = [
    {
      field: 'hblCcn',
      title: translatedTextsObject.hblCcn,
      filterConfig: { type: 'textfield' },
      sortKey: 'hblCcn'
    },
    {
      field: 'erpMsgSectionCode',
      title: translatedTextsObject.erpMsgSectionCode,
      render: (data) =>
        lookups
          ? lookups['msgFunc'][data.erpMsgSectionCode]
          : data.erpMsgSectionCode
    },
    {
      field: 'reportStatus',
      title: translatedTextsObject.status,
      render: (data) =>
        lookups
          ? lookups['cbsaProceessingInd'][data.reportStatus]
          : data.reportStatus
    },
    {
      field: 'reportStatus',
      title: translatedTextsObject.reportStatus,
    },
    {
      field: 'reportStatus',
      title: translatedTextsObject.responseText,
      render: (data) => (
        <Grid container spacing={1}>
          {data.responseError.map((response, index) => (
            <Grid item xs={12}>
              {(response.errorSegment == 'ERC' && lookups)
                ? (lookups['cbsaErrorDesc'][response.errorCode]) ? (lookups['cbsaErrorDesc'][response.errorCode]) : response.errorDesc
                : (response.errorCode == "AAO" && response.errorSegment == "FTX" && lookups) ? (lookups['subItemIdentifier'][response.errorDesc]) ? lookups['subItemIdentifier'][response.errorDesc] : response.errorDesc
                  : response.errorDesc
              }
            </Grid>
          ))}
        </Grid>
      )
    },
    {
      field: 'cbsaRefNo',
      title: translatedTextsObject.cbsaRefNo
    },
    {
      field: 'dateTime',
      title: translatedTextsObject.dateTime,
      sortKey: 'dateTime',
      render: (data) =>
        data.dateTime &&
        moment(data.dateTime).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
    {
      field: 'remarks',
      title: translatedTextsObject.remarks,
      render: (data) => {
        let remarks = ''
        data.responseError.forEach((response) => {
          if (response.errorSegment === 'ERC') {
            remarks += (response.errorCode + ' / ')
          }
        })

        return (
          <Grid container spacing={1}>
            {remarks != '' && (
              <Grid>{remarks.slice(0, -3)}</Grid>
            )}

            {data.erpMsgSectionCode != null && data.erpMsgSectionCode != undefined && data.erpMsgSectionCode != "" &&
              <Grid>HBL sent to CBSA, TransID: {data.transSetControlNo}</Grid>
            }
          </Grid>
        )
      }
    }
  ]

  return (
    <LocalTable
      columns={columns}
      data={data}
      loading={isFetching}
    />
  )
}

export default HouseBillResponse
