import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import FaxTrackerApiUrls from 'src/apiUrls/FaxTrackerApiUrls'
import React, { useState,useRef } from 'react'
import FaxTrackerKeys from 'src/constants/locale/key/FaxTracker'
import Namespace from 'src/constants/locale/Namespace'
import Box from '@material-ui/core/Box';
import SendIcon from '@material-ui/icons/Send'
import { useHistory,useLocation } from 'react-router-dom'
import { DettachAll, SubmitAll,SubmitMultiplefromManifest } from 'src/common/NACommon'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from 'moment'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup,
    DateRangeFilter: CngDateRangeFilter,
    DateRangeFilter
  },
  button: { CngPrimaryButton, CngSecondaryButton },
  CngDialog,
  CngGridItem
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {

  const {securedSendRequest } = useServices();

  const cngTableRef = useRef()
  const row = useRef()
  const history = useHistory()

  const {
    location: { pathname },
    showNotification
  } = props

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.FAX_TRACKER
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  const [openDialog,setOpenDialog] = useState(false);

  //23 >> Add
  const [messageFunctionState,setMessageFunctionState] = useState("23");
  const handleMessageFunctionChange = (event) => {
    setMessageFunctionState(event.target.value)
  }

  const DisableSubmitButton = (rowData) =>{
    
    var isDisable = false
    for(let record in rowData){
      var row = rowData[record]
      if(row.status === "ST"){
        isDisable = true
        break
      }
    }
    return isDisable
  }

  function makeTranslatedTextsObject() {
    let FaxTracker = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.TITLE
    )
    let status = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.STATUS
    )
    let tripNo = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.TRIP_NO
    )
    let eta = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.ETA
    )
    let firstPort = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.FIRST_PORT
    )
    let noOfPages = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.NO_OF_PAGES
    )
    let noOfShipments = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.NO_OF_SHIPMENTS
    )
    let filerUserId = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.FILER_USER_ID
    )
    let faxUserId = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.FAX_USER_ID
    )
    let createdDate = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.CREATED_DATE
    )
    let updatedDate = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.UPDATED_DATE
    )

    return {
      FaxTracker,
      status,
      tripNo,
      eta,
      firstPort,
      noOfPages,
      noOfShipments,
      filerUserId,
      faxUserId,
      createdDate,
      updatedDate
    }
  }

  const columns = [
    {
      field: "faxUserId",
      title: translatedTextsObject.faxUserId,
    },
    {
      field: "acctId",
      title: translatedTextsObject.filerUserId,
    },
    {
      field: "tripNo",
      title: translatedTextsObject.tripNo,
    },
    {
      field: "firstPort",
      title: translatedTextsObject.firstPort,
    },
    {
      field: "eta",
      title: translatedTextsObject.eta,
      render: (rowData) => {
        return moment(rowData.eta).format('DD/MM/YYYY HH:mm:ss')
      },
      type: "date",
      filterComponent: DateRangeFilter
    },
    {
      field: "createdDate",
      title: translatedTextsObject.createdDate,
      render: (rowData) => {
        return moment(rowData.createdDate).format('DD/MM/YYYY HH:mm:ss')
      },
      type: "date",
      filterComponent: DateRangeFilter,
      defaultSort: 'desc'
    },
    {
      field: "updatedDate",
      title: translatedTextsObject.updatedDate,
      render: (rowData) => {
        return moment(rowData.updatedDate).format('DD/MM/YYYY HH:mm:ss')
      },
      type: "date",
      filterComponent: DateRangeFilter
    },
    {
      field: "status",
      title: translatedTextsObject.status,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: FaxTrackerApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              fetch={{ url: FaxTrackerApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
              options={{
                selection: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 500, 1000]
              }}
              cngTableRef={cngTableRef}
              viewButtonProps={{
                onClick: (e, rowData) => { 
                  const recordId = rowData.id
                  history.push(`${pathname}/view/`+ recordId)
                }
              }}
              editButtonProps={{
                onClick: (e, rowData) => { 
                  const recordId = rowData.id
                  history.push(`${pathname}/edit/`+ recordId)
                }, 
                customTooltip: () => {
                  return translatedTextsObject.editButton
                }
              }}
              deleteButtonProps={{
                customTooltip: () => {
                  return translatedTextsObject.deleteButton
                }
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
