import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import EhblResponseApiUrls from 'src/apiUrls/EhblResponseApiUrls'
import EhblResponseKeys from 'src/constants/locale/key/EhblResponse'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import { Grid } from '@material-ui/core'
import LocalTable from 'src/components/aciacehighway/LocalTable'
import Table from 'src/components/aciacehighway/Table'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL,LIKE },
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function CloseMessageResponse(props) {
  const { showNotification, id, isViewOnly = false, manifestId, housbillPage,ccn } = props

  const [data, setData] = useState([])
  const [lookups, setLookups] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  const { fetchRecords } = useServices()
  const { translate } = useTranslation([Namespace.EHBL_RESPONSE])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [newFilters,setNewFilters] = useState([])
  function fetchCloseMessageResponse() {
    

    return fetchRecords.execute(EhblResponseApiUrls.GET_D4_NOTICE_RESPONSE,
      {
        filters: [
          {
            field: 'manifestId',
            operator: 'equal',
            value: manifestId ? manifestId : (housbillPage ? manifestId : id)
          },
          ...filters
        ]
      }, (res) => res)
  }

  function showCloseMessageResponse() {
    setIsFetching(true)
    /*Promise.all([fetchCloseMessageResponse()]).then(([respData]) => {
      setData(respData)
      setIsFetching(false)
    })*/
  }

  useEffect(() => {
    if(ccn!=null && ccn!=undefined && ccn!=""){
     
      setNewFilters([{
        field: 'hblCcn',
        operator: 'equal',
        value: ccn
      }]);
    }

    showCloseMessageResponse();
    Promise.all([
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACI_HW_D4_NOTICE_REASON_CODE'
          }
        ],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_SUB_ITEM_IDENTIFIER' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'EHBL_MSG_FUNC_CODE'
          }
        ],
        undefined,
        'code'
      ),
    ]).then(([cbsaProceessingInd, subItemIdentifier,msgFunc]) => {
      setLookups({ cbsaProceessingInd, subItemIdentifier,msgFunc })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let ehblResponse = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: ehblResponse
      }
    )
    let hblId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.HBL_ID
    )
    let manifestId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.MANIFEST_ID
    )
    let closemsgId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.CLOSEMSG_ID
    )
    let receiveDatetime = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.RECEIVE_DATETIME
    )
    let transSetIdentifierCode = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.TRANS_SET_IDENTIFIER_CODE
    )
    let transSetControlNo = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.TRANS_SET_CONTROL_NO
    )
    let originalTransSetStatus = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ORIGINAL_TRANS_SET_STATUS
    )
    let originalTransSetControlNo = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ORIGINAL_TRANS_SET_CONTROL_NO
    )
    let originalTransSetDatetime = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ORIGINAL_TRANS_SET_DATETIME
    )
    let reportType = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.REPORT_TYPE
    )
    let reportStatus = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.REPORT_STATUS
    )
    let primaryCcn = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.PRIMARY_CCN
    )
    let hblCcn = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.HBL_CCN
    )
    let cbsaMsgType = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.CBSA_MSG_TYPE
    )
    let cbsaRefNo = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.CBSA_REF_NO
    )
    let cbsaMsgFuncCode = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.CBSA_MSG_FUNC_CODE
    )
    let dateTime = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.DATE_TIME
    )
    let secondaryBusinessId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.SECONDARY_BUSINESS_ID
    )
    let typeOfResp = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.TYPE_OF_RESP
    )
    let erpMsgSectionCode = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ERP_MSG_SECTION_CODE
    )
    let erpUniqueRefNo = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ERP_UNIQUE_REF_NO
    )
    let erpSubitemId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.ERP_SUBITEM_ID
    )
    let d4ResponseId = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.D4_RESPONSE_ID
    )
    let userIdInfo = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.USER_ID_INFO
    )

    let status = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.STATUS
    )

    let responseText = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.RESPONSE_TEXT
    )

     let remarks = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.REMARKS
    )

    let prevCcn = translate(
      Namespace.EHBL_RESPONSE,
      EhblResponseKeys.PREV_CCN
    )

    return {
      ehblResponse,
      tableTitle,
      hblId,
      manifestId,
      closemsgId,
      receiveDatetime,
      transSetIdentifierCode,
      transSetControlNo,
      originalTransSetStatus,
      originalTransSetControlNo,
      originalTransSetDatetime,
      reportType,
      reportStatus,
      primaryCcn,
      hblCcn,
      cbsaMsgType,
      cbsaRefNo,
      cbsaMsgFuncCode,
      dateTime,
      secondaryBusinessId,
      typeOfResp,
      erpMsgSectionCode,
      erpUniqueRefNo,
      erpSubitemId,
      d4ResponseId,
      userIdInfo,
      status,
      responseText,
      remarks,
      prevCcn
    }
  }

  const columns = [
    {
      field: 'cbsaRefNo',
      title: translatedTextsObject.cbsaRefNo,
      filterConfig: { type: 'textfield' },
      sortKey: 'cbsaRefNo'
    },
    {
      field: 'reportStatus',
      title: translatedTextsObject.status,
      render: (data) =>
        lookups
          ? lookups['cbsaProceessingInd'][data.reportStatus]
          : data.reportStatus
    },
    {
      field: 'reportStatus',
      title: translatedTextsObject.responseText,
      render: (data) => (
        <Grid container spacing={1}>
          {data.responseError.map((response, index) => (
            <Grid>
            {response.errorCode=="AAO" && response.errorSegment == "FTX"  && lookups
          ? lookups['subItemIdentifier'][response.errorDesc] == null ? response.errorDesc : lookups['subItemIdentifier'][response.errorDesc]
          : response.errorDesc
            }
            </Grid>
          ))}
        </Grid>
      )
    },
    {
      field: 'receiveDatetime',
      title: translatedTextsObject.receiveDatetime,
      sortKey: 'receiveDatetime',
      render: (data) =>
        data.receiveDatetime &&
        moment(data.receiveDatetime).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
    {
      field: 'dateTime',
      title: translatedTextsObject.dateTime,
      sortKey: 'dateTime',
      render: (data) =>
        data.dateTime &&
        moment(data.dateTime).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
    {
      field: 'remarks',
      title: translatedTextsObject.remarks
    }
  ]

  const filters = [
    {
      name: "cbsaRefNo",
      label: translatedTextsObject.cbsaRefNo,
      type: 'textfield',
      operatorType: LIKE
    }
  ]

  return (
    <Table
      key={newFilters}
      actions={[
      ]}
      columns={columns}
      compact
      fetch={{ url: EhblResponseApiUrls.GET_D4_NOTICE_RESPONSE }}
      fetchFilters={[
        {
          field: 'manifestId',
          operator: 'equal',
          value: manifestId ? manifestId : (housbillPage ? manifestId : id)
        },
        ...newFilters
      ]}
      filters={filters}
      onRowClick={(rowData) => {
      
      }
      }
      rowActions={[
        
      ]}
      showNotification={showNotification}
      sortConfig={{
        type: 'column',
        defaultField: 'cbsaProcessDateTime',
        defaultDirection: 'DESC'
      }}
      persistSettings
      selectActions={[
      ]}
    />
  )
}

export default CloseMessageResponse
