import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )


  const errMsgAlphaNumericWithSpecialChar = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR
  )

  const errMsgAlphaNumericWithSpecialCharNoSpace = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR_NO_SPACE
  )

  const errMsgAlphaNumericWithHypen = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_HYPEN
  )

  const errMsgNumeric = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )

  const errMsgMinLength = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_MIN_LENGTH
  )
  const errMsgMaxLength = translate(
    Namespace.EHBL_MANIFEST,
    EhblManifestKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )

  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumeric = '^[a-zA-Z0-9 ]*$'
  const regexAlphaNumericWithSpecialChar = '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const regexAlphaNumericWithSpecialCharNoSpace = '^[a-zA-Z0-9,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\]+$'

  return Yup.object({
    hblId: Yup.string().nullable(),
    manifestId: Yup.string().nullable(),
    mfId: Yup.string().nullable(),
    equipmentId: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(11, errMsgMaxLength + " 11"),
    equipmentType: Yup.string().nullable(),
    sealNo: Yup.string().nullable(),
    sealNo1: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo2: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo3: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo4: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo5: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo6: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo7: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo8: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo9: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo10: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo11: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo12: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo13: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo14: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo15: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo16: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo17: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo18: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo19: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo20: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo21: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo22: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo23: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo24: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo25: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo26: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo27: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo28: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo29: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo30: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo31: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo32: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo33: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo34: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo35: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo36: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo37: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo38: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo39: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
    sealNo40: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15")
  })
}

export default makeValidationSchema
