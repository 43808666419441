import React, { useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'
import { Grid, Card, CardContent, Divider, Typography, Box, useTheme, InputAdornment, IconButton } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
  useTranslation
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import ContainerDetailsFormProperties from './ContainerDetailsFormProperties'
import TradePartyFormProperties from './TradePartyFormProperties'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import { useFormContext, useWatch } from "react-hook-form";
import ContainerDetailsTable from './ContainerDetailsTable'
import TradePartyTable from './TradePartyTable'
import { NaCustomsOfficeAutocompleteField, NaSublocationAutocompleteField } from 'src/components/na'
import CngField from '../../../components/cngcomponents/CngField'
import TradePartyDialog from './TradePartyDialog'
import ContainerDetailsDialog from './ContainerDetailsDialog'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import HouseBillViewTable from './housebill/HouseBillViewTable'
import ActivityLogs from './ActivityLogs'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSwitchField,
      CngCodeMasterAutocompleteField
    },
  },
  table: {
    CngLocalModeDialogFormTable,
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  corpid: "",
  movementType: "",
  indicatorMode: "",
  primaryCcn: "",
  carrierCode: "",
  portOfDischargeCode: "",
  portOfDestCode: "",
  messageFuncCode: "",
  consolidationInd: "",
  totalWeight: "",
  status: "",
  closeStatus: "",
  responseDatetime: "",
  serviceBureauId: "",
  userRole: "",
  email: "",
  mobileNo: "",
  totalWeightUom: "",
  myReferenceNo: "",
  destSubLocCode: "",
  discSubLocCode: "",
  clStatus: "",
  clMsgFuncCode: "",
  clAmendCode: "",
  clUniqueRefNo: "",
  containerizedInd: "",
  totalVolume: "",
  totalVolumeUom: "",
  cargoQuantity: "",
  quantityUom: "",
  b2bComment: "",
  totalHbl: "",
  partyId: "",
  submittedBy: "",
  submittedDate: "",
  containerDetails: [],
  tradeParty: [],
})

function ManifestViewContent(props) {

  const { data, statusNotificationData, getLookupValue, getCountryStateLabel, disabled } = props
  const { translate } = useTranslation(Namespace.EHBL_HOUSE_BILL)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  const [viewTradePartyDialog, setViewTradePartyDialog] = useState({
    open: false,
    tradeParty: null
  })

  const [viewContainerDetailsDialog, setViewContainerDetailsDialog] = useState({
    open: false,
    containerDetails: null
  })

  function makeTranslatedTextsObject() {
    let ehblManifest = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TITLE
    )
    let corpid = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CORPID
    )
    let movementType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MOVEMENT_TYPE
    )
    let indicatorMode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.INDICATOR_MODE
    )
    let primaryCcn = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.PRIMARY_CCN
    )
    let carrierCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CARRIER_CODE
    )
    let portOfDischargeCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.PORT_OF_DISCHARGE_CODE
    )
    let portOfDestCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.PORT_OF_DEST_CODE
    )
    let messageFuncCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MESSAGE_FUNC_CODE
    )
    let consolidationInd = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CONSOLIDATION_IND
    )
    let totalWeight = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_WEIGHT
    )
    let status = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.STATUS
    )
    let closeStatus = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CLOSE_STATUS
    )
    let responseDatetime = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.RESPONSE_DATETIME
    )
    let serviceBureauId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SERVICE_BUREAU_ID
    )
    let userRole = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.USER_ROLE
    )
    let email = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.EMAIL
    )
    let mobileNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MOBILE_NO
    )
    let totalWeightUom = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_WEIGHT_UOM
    )
    let myReferenceNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MY_REFERENCE_NO
    )
    let destSubLocCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.DEST_SUB_LOC_CODE
    )
    let discSubLocCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.DISC_SUB_LOC_CODE
    )
    let clStatus = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_STATUS
    )
    let clMsgFuncCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_MSG_FUNC_CODE
    )
    let clAmendCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_AMEND_CODE
    )
    let clUniqueRefNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_UNIQUE_REF_NO
    )
    let containerizedInd = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CONTAINERIZED_IND
    )
    let totalVolume = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_VOLUME
    )
    let totalVolumeUom = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_VOLUME_UOM
    )
    let cargoQuantity = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CARGO_QUANTITY
    )
    let quantityUom = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.QUANTITY_UOM
    )
    let b2bComment = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.B2B_COMMENT
    )
    let totalHbl = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_HBL
    )
    let partyId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SUBMITTED_DATE
    )
    let containerDetails = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.HBL_ID
    )
    let manifestId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.MANIFEST_ID
    )
    let mfId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.MF_ID
    )
    let equipmentId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.EQUIPMENT_ID
    )
    let equipmentType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.EQUIPMENT_TYPE
    )
    let sealNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO
    )
    let tradeParty = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.TITLE
    )
    let partyName = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.PARTY_NAME
    )
    let partyType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.PARTY_TYPE
    )
    let division = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.DIVISION
    )
    let locPortCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.LOC_PORT_CODE
    )
    let poBoxNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.PO_BOX_NO
    )
    let address = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.ADDRESS
    )
    let city = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.CITY
    )
    let stateCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.STATE_CODE
    )
    let countryCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.COUNTRY_CODE
    )
    let postalCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.POSTAL_CODE
    )
    let contactName = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.CONTACT_NAME
    )
    let telephoneNum = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.TELEPHONE_NUM
    )
    let snpType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_TYPE
    )
    let snpUcn = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_UCN
    )
    let snpB2bComment = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_B2B_COMMENT
    )
    let snpIdentifierValue = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TradeParty.SNP_IDENTIFIER_VALUE
    )

    let updateButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.UPDATE_BUTTON
    )

    let saveButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SAVE_BUTTON
    )

    let statusNotifyParties = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.TITLE
    )
    let name = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.NAME
    )
    let snType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.SN_TYPE
    )
    let hblCcn = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.HBL_CCN
    )
    let hpNum = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.HP_NUM
    )
    let module = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.StatusNotifyParties.MODULE
    )

    return {
      ehblManifest,
      corpid,
      movementType,
      indicatorMode,
      primaryCcn,
      carrierCode,
      portOfDischargeCode,
      portOfDestCode,
      messageFuncCode,
      consolidationInd,
      totalWeight,
      status,
      closeStatus,
      responseDatetime,
      serviceBureauId,
      userRole,
      email,
      mobileNo,
      totalWeightUom,
      myReferenceNo,
      destSubLocCode,
      discSubLocCode,
      clStatus,
      clMsgFuncCode,
      clAmendCode,
      clUniqueRefNo,
      containerizedInd,
      totalVolume,
      totalVolumeUom,
      cargoQuantity,
      quantityUom,
      b2bComment,
      totalHbl,
      partyId,
      submittedBy,
      submittedDate,
      containerDetails,
      hblId,
      manifestId,
      mfId,
      equipmentId,
      equipmentType,
      sealNo,
      tradeParty,
      partyName,
      partyType,
      division,
      locPortCode,
      poBoxNo,
      address,
      city,
      stateCode,
      countryCode,
      postalCode,
      contactName,
      telephoneNum,
      snpType,
      snpUcn,
      snpB2bComment,
      snpIdentifierValue,
      updateButton,
      saveButton,
      statusNotifyParties,
      name,
      snType,
      hblCcn,
      hpNum,
      module
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.ehblManifest} >
          <CardContent>
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.myReferenceNo}>
                  {data.myReferenceNo}
                </CngField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.movementType}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('movementType', data.movementType)}
                    <TinyChip label={data.movementType} variant='outlined' />
                  </Typography>
                </CngField>

              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.indicatorMode}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('modeIndicator', data.indicatorMode)}
                    <TinyChip label={data.indicatorMode} variant='outlined' />
                  </Typography>
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.primaryCcn}>
                  {data.primaryCcn}
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={translatedTextsObject.portOfDestCode}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('customsOffice', data.portOfDestCode)}
                    <TinyChip label={data.portOfDestCode} variant='outlined' />
                  </Typography>
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={translatedTextsObject.destSubLocCode}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('sublocation', data.destSubLocCode)}
                    <TinyChip label={data.destSubLocCode} variant='outlined' />
                  </Typography>
                </CngField>
              </CngGridItem>


              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={translatedTextsObject.portOfDischargeCode}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('customsOffice', data.portOfDischargeCode)}
                    <TinyChip label={data.portOfDischargeCode} variant='outlined' />
                  </Typography>
                </CngField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} >
                <CngField label={translatedTextsObject.discSubLocCode}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('sublocation', data.discSubLocCode)}
                    <TinyChip label={data.discSubLocCode} variant='outlined' />
                  </Typography>
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.consolidationInd}>
                  {data.consolidationInd ? 'Yes' : 'No'}
                </CngField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.containerizedInd}>
                  {data.containerizedInd ? 'Yes' : 'No'}
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="corpid"
                  label={translatedTextsObject.corpid}
                />
              </CngGridItem>


              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="carrierCode"
                  label={translatedTextsObject.carrierCode}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="messageFuncCode"
                  label={translatedTextsObject.messageFuncCode}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="totalWeight"
                  label={translatedTextsObject.totalWeight}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="status"
                  label={translatedTextsObject.status}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="closeStatus"
                  label={translatedTextsObject.closeStatus}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="responseDatetime"
                  label={translatedTextsObject.responseDatetime}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="serviceBureauId"
                  label={translatedTextsObject.serviceBureauId}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="userRole"
                  label={translatedTextsObject.userRole}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="email"
                  label={translatedTextsObject.email}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="mobileNo"
                  label={translatedTextsObject.mobileNo}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="totalWeightUom"
                  label={translatedTextsObject.totalWeightUom}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="clStatus"
                  label={translatedTextsObject.clStatus}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="clMsgFuncCode"
                  label={translatedTextsObject.clMsgFuncCode}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="clAmendCode"
                  label={translatedTextsObject.clAmendCode}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="clUniqueRefNo"
                  label={translatedTextsObject.clUniqueRefNo}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="totalVolume"
                  label={translatedTextsObject.totalVolume}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="totalVolumeUom"
                  label={translatedTextsObject.totalVolumeUom}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="cargoQuantity"
                  label={translatedTextsObject.cargoQuantity}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="quantityUom"
                  label={translatedTextsObject.quantityUom}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="totalHbl"
                  label={translatedTextsObject.totalHbl}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="partyId"
                  label={translatedTextsObject.partyId}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="submittedBy"
                  label={translatedTextsObject.submittedBy}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="submittedDate"
                  label={translatedTextsObject.submittedDate}
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.containerDetails}>
          <ContainerDetailsTable
            data={data.containerDetails}
            onViewContainerDetails={(containerDetails) => {
              setViewContainerDetailsDialog({ open: true, containerDetails: containerDetails })
            }}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.tradeParty}>
          <TradePartyTable
            data={data.tradeParty}
            onViewTradeParty={(tradeParty) => {
              setViewTradePartyDialog({ open: true, tradeParty: tradeParty })
            }}
          />
          <CngGridItem xs={12} sm={12}>
            <CngField label={translatedTextsObject.b2bComment}>
              {data.b2bComment}
            </CngField>
          </CngGridItem>
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.ehblHouseBill}>
          <HouseBillViewTable
            manifestId={data.id}
            getLookupValue={getLookupValue}
            getCountryStateLabel={getCountryStateLabel}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.statusNotifyParties}>
          {statusNotificationData.length > 0 ? (
            <Grid container spacing={3}>
              {statusNotificationData.map((notification, index) => (
                <React.Fragment key={notification.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.snType}>
                          <Typography component='div' variant='inherit'>
                            {getLookupValue('snType', notification.snType)}
                            <TinyChip label={notification.snType} variant='outlined' />
                          </Typography>
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.email}>
                          {notification.email}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.hpNum}>
                          {notification.hpNum}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {statusNotificationData.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography variant='inherit' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      <Grid item xs={12}>
          <ActivityLogs manifestId={data.id} />
      </Grid>
      <ContainerDetailsDialog
        isView
        open={viewContainerDetailsDialog.open}
        onClose={() =>
          setViewContainerDetailsDialog({ open: false, containerDetails: null })
        }
        containerDetails={viewContainerDetailsDialog.containerDetails}
        translatedTextsObject={translatedTextsObject}
        getCountryStateLabel={getCountryStateLabel}
        getLookupValue={getLookupValue}
      />
      <TradePartyDialog
        isView
        open={viewTradePartyDialog.open}
        onClose={() => {
          setViewTradePartyDialog({ open: false, tradeParty: null })
        }}
        tradeParty={viewTradePartyDialog.tradeParty}
        translatedTextsObject={translatedTextsObject}
        getCountryStateLabel={getCountryStateLabel}
        getLookupValue={getLookupValue}
      />

    </Grid>
  )
}

export default ManifestViewContent
