import EhblManifestApiUrls from 'src/apiUrls/EhblManifestApiUrls'
import FormProperties from './FormProperties'
import { Grid,Box, Card, useTheme } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import Stepper from '../../../components/aciacehighway/Stepper'
import ManifestAddForm from './ManifestAddPage'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'


const {
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification, onSetLoading }) {

  const theme = useTheme()

  return (
    <Card>
      <Stepper steps={['Create manifest']} activeStep={0} />
      <Box padding={2}>
        <ManifestAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => console.log('submit success')}
          onSetLoading={onSetLoading}
        />
      </Box>
    </Card>
  )
  /*return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: EhblManifestApiUrls.POST,
            successRedirect: pathMap.EHBL_MANIFEST_LIST_VIEW
          }}
        />
      </Grid>
    </Grid>
  )*/
}

export default withFormState(AddPage)
