import makeValidationSchema from './ContainerDetailsMakeValidationSchema'
import React, {useState} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContainerDetailsDialog from './ContainerDetailsDialog'

import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.mode === 'dark' ? '#282C34' : theme.palette.grey[100],
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  hblId: "",
  manifestId: "",
  mfId: "",
  equipmentId: "",
  equipmentType: "",
  sealNo: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function ContainerDetailsTable({
  data,
  onAddContainerDetails,
  onDeleteContainerDetails,
  onEditContainerDetails,
  onViewContainerDetails,
  showNotification
}) {

  const { translate } = useTranslation(Namespace.EHBL_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const [searchTerm, setSearchTerm] = useState('')

  const classes = useStyles()

  const filtered =
    searchTerm !== ''
      ? data.filter((containerDetails) =>
          containerDetails.equipmentId
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      : data

  function makeTranslatedTextsObject() {
    let containerDetails = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.HBL_ID
    )
    let manifestId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.MANIFEST_ID
    )
    let mfId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.MF_ID
    )
    let equipmentId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.EQUIPMENT_ID
    )
    let equipmentType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.EQUIPMENT_TYPE
    )
    let sealNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO
    )
    let updateButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.UPDATE_BUTTON
    )

    let saveButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SAVE_BUTTON
    )

    return {
      containerDetails,
      hblId,
      manifestId,
      mfId,
      equipmentId,
      equipmentType,
      sealNo,
      updateButton,
      saveButton
    }
  }

  return (
    <>
    <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {data.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddContainerDetails && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={() =>
                 onAddContainerDetails()
                }
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add Container
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{translatedTextsObject.equipmentId}</TableCell>
              {(onEditContainerDetails || onDeleteContainerDetails) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.length > 0 ? (
              filtered.map((containerDetails) => (
                <TableRow
                  key={Math.random()}
                  hover
                  onClick={() => {
                    if (onViewContainerDetails) {
                      onViewContainerDetails(containerDetails)
                    }
                  }}
                >
                  <TableCell>{containerDetails.equipmentId}</TableCell>
                  {(onEditContainerDetails || onDeleteContainerDetails) && (
                    <TableCell align='right'>
                      <Grid container justify='flex-end' spacing={1}>
                        {onEditContainerDetails && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onEditContainerDetails(containerDetails)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                        {onDeleteContainerDetails && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onDeleteContainerDetails(containerDetails)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={3 + (onEditContainerDetails || onDeleteContainerDetails ? 1 : 0)}
                >
                  <Typography variant='inherit' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
	/*return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.containerDetails} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.hblId}>
            <CngTextField
              name="hblId"
              label={translatedTextsObject.hblId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.manifestId}>
            <CngTextField
              name="manifestId"
              label={translatedTextsObject.manifestId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.mfId}>
            <CngTextField
              name="mfId"
              label={translatedTextsObject.mfId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.equipmentId}>
            <CngTextField
              name="equipmentId"
              label={translatedTextsObject.equipmentId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.equipmentType}>
            <CngTextField
              name="equipmentType"
              label={translatedTextsObject.equipmentType}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNo}>
            <CngTextField
              name="sealNo"
              label={translatedTextsObject.sealNo}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)*/
}

export default ContainerDetailsTable
