import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import CngSection from 'src/components/cngcomponents/CngSection'
import HouseBillResponse from './response/HouseBillResponse'
import CloseMessageResponse from './response/CloseMessageResponse'
import D4NoticeResponse from './response/D4NoticeResponse'

const { CngTabs } = components
const {
  filter: { EQUAL }
} = constants

function ResponseLog(props) {
  const { manifestId } = props

  return (
    <CngSection title='Response log'>
      <CngTabs
        headerColor='primary'
        tabs={[
          {
            tabName: 'House Bill',
            tabContent: <HouseBillResponse id={manifestId} />
          },
          {
            tabName: 'Close Message',
            tabContent: <CloseMessageResponse id={manifestId} />
          },
          {
            tabName: 'D4 Notice',
            tabContent: <D4NoticeResponse id={manifestId} />
          }
        ]}
      />
    </CngSection>
  )
}

export default ResponseLog
