import React from 'react'
import EquipmentAddForm from './EquipmentAddPage'

function AddPage({ history, showNotification }) {
  return (
    <EquipmentAddForm
      history={history}
      showNotification={showNotification}
    />
  )
}

export default AddPage
