import React, { useEffect, useRef, useState } from 'react'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import EhblCloseMsgApiUrls from 'src/apiUrls/EhblCloseMsgApiUrls'
import {
  Box,
  Grid,
  makeStyles,
  Select,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment'
import Table from '../../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getStatusMetadata } from '../../../../common/NACommon'
import CloseMsgDialog from './CloseMsgDialog'
import CloseMsgFormProperties from '../../closemsg/FormProperties'
import ConfirmDialog from '../../../common/ConfirmDialog'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import SetResponseMessageDialog from './SetResponseMessageDialog'
import ChargeTypeDialog from '../../../common/ChargeTypeDialog'
import { format } from 'date-fns'
import ErrorMessageDialog from 'src/views/common/ErrorMessageDialog'

const {
  button: { CngButton, CngIconButton },
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { EQUAL, BETWEEN, IN, LIKE }
} = constants

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.name === 'ONE_DARK' ? 'transparent' : theme.palette.common.white,
    fontSize: 14,
    maxWidth: '100%',
    width: 150,
    '& > .MuiOutlinedInput-input': {
      padding: 8
    },
    '& > .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 32
    }
  }
}))

function CloseMsgAttachTable(props) {
  const {
    history,
    manifestId,
    onChangeStep,
    loading,
    onSetLoading,
    showNotification,
    step,
    manifestData
  } = props

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.EHBL_CLOSE_MSG
  ])
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()
  const tableRef = useRef(null)
  const {
    createRecord,
    deleteRecord,
    fetchRecords,
    securedSendRequest,
    updateRecord
  } = useServices()

  const [closeMsgDialog, setCloseMsgDialog] = useState({
    open: false,
    closeMsg: null,
    isView: false
  })

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    closeMsg: null
  })

  const [chargeTypes, setChargeTypes] = useState([])
  const [isSuperParty, setSuperParty] = useState(false);
  const [submitDialog, setSubmitDialog] = useState({ open: false, closeMsgs: [] })
  const [amendmentCodeOptions, setAmendmentCodeOptions] = useState([])
  const [setResponseMsgDialog, setSetResponseMsgDialog] = useState({ open: false, closeMsgs: [] })
  const [errorMessageDialog, setErrorMessageDialog] = useState({ open: false, errorMessages: [] })

  const [lookups, setLookups] = useState(null)

  useEffect(() => {
    Promise.all([
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      ),
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Amendment codes
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
        {
          customData: { codeMType: constants.CodeMaintenanceType.CODE_MASTER },
          filters: [
            {
              field: 'active',
              operator: EQUAL,
              value: 'true'
            },
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'EHBL_CLOSE_MSG_AMENDMENT_CODE'
            }
          ]
        },
        (response) => {
          return response.content.map((status) => ({
            code: status.code,
            desc: status.descriptionEn
          }))
        },
        () => {
          showNotification('error', 'Failed to fetch Amendment Code options')
        }
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_HB_STATUS' }],
        undefined,
        'code'
      ),
      // Countries
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACIHWY_MANIFEST_COUNTRY'
          }
        ],
        undefined,
        'code'
      ),
      // Country states
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'ACI' }],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      // Party type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_PARTY_TYPE' }],
        undefined,
        'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'HBL' }],
          customData: { codeMType: 'CustomsOffice' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      // Arrival date time zone
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'SCHEDULER_TIME_ZONE' }],
        undefined,
        'code'
      ),
      // Sublocation
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'HBL' }],
          customData: { codeMType: 'Sublocation' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.intlcode] = item.descriptionEn
          })

          return result
        }
      ),
      // Movement type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_MOVEMENT_TYPE' }],
        undefined,
        'code'
      ), //Mode indicator
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_MODE_INDICATOR' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_SNP_TYPE' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_WEIGHT_UOM' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_VOLUME_UOM' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_SN_TYPE' }],
        undefined,
        'code'
      ),
    ]).then(
      ([
        billingSuperParty,
        billingChargeTypes,
        amendmentCodes,
        status,
        countries,
        countryStates,
        partyType,
        customsOffice,
        arrivalDateAndTimeZone,
        sublocation,
        movementType,
        modeIndicator,
        snpType,
        weightUOM,
        volumeUOM,
        snType
      ]) => {
        setAmendmentCodeOptions(amendmentCodes)
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })

        const states = Object.keys(countries).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        setLookups({
          status,
          countries,
          countryStates: states,
          partyType,
          customsOffice,
          arrivalDateAndTimeZone,
          sublocation,
          movementType,
          modeIndicator,
          snpType,
          weightUOM,
          volumeUOM,
          snType
        })
      });
  }, []);

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function handleEditCloseMsg(data) {
    onSetLoading(true)

    updateRecord.execute(
      EhblCloseMsgApiUrls.PUT,
      CloseMsgFormProperties.toServerDataFormat(data),
      () => {
        tableRef.current.performRefresh()

        setCloseMsgDialog({ open: false, closeMsg: null, isView: false })
        showNotification('success', 'Close message updated.')
      },
      (error) => {
        if (error.response.data.errorMessages) {
          error.response.data.errorMessages.forEach((errorMessage) => {
            showNotification('error', errorMessage)
          })
        }
      },
      () => onSetLoading(false)
    )
  }

  function handleDeleteCloseMsg() {
    if (confirmDialog.closeMsg) {
      deleteRecord.execute(
        EhblCloseMsgApiUrls.DELETE,
        confirmDialog.closeMsg,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, closeMsg: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function preSubmitCloseMsgs(closeMsgs) {
    if (isSuperParty) {
      setSubmitDialog({ open: true, closeMsgs: closeMsgs })
    } else {
      closeMsgs.forEach(e => {
        e.chargeAs = 'REGULAR'
      })
      handleSubmitCloseMsgs(closeMsgs)
    }
  }

  function handleSubmitCloseMsgs(closeMsgs) {
    try {
      tableRef.current.setLoading(true)
      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        closeMsgs.forEach(data => {
          data.fileForUserId = fileForUserDetails.fileForUserId;
          data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
          data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
        });
      }

      securedSendRequest.execute(
        'POST',
        EhblCloseMsgApiUrls.SUBMIT_ALL,
        closeMsgs,
        (response) => {
          const data = response.data
          var errorMessages = []
          var errorFlag = false
          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessages.push(obj.errorMessages)
              errorFlag = true
            }
          }

          if (errorFlag) {
            setErrorMessageDialog({ open: true, errorMessages })
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMessage)
            tableRef.current.performRefresh()
          }
        },
        error => {
          console.log(error)
          showNotification('error', translatedTextsObject.submitErrorMessage)
        },
        () => tableRef.current.setLoading(false)
      )
    } catch (error) {
      console.log(error)
    }
  }

  function handleModifyCloseMsg(closeMsgs) {

    if (tableRef.current) {
      tableRef.current.updateData(closeMsgs)
    }
  }
  

  function handlePrintCloseMessage(closeMsgs){
    function onSuccess(response) {
      let blob = new Blob([response.data]);

      let first = "EHBL_CLMSG_";
      let curDate = format(new Date(), 'yyyyMMddHHmmss');

      let filename  = first + curDate+".pdf";
      if(closeMsgs.length>1){
        filename = first + curDate+".zip";
      }

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    }

    function onError(error) {
      console.error(error);
    }
  
    function onComplete(){
    }

    const config = {
      responseType: 'blob'
    }

    let prevCcnList = closeMsgs.map((x)=> x.prevCcn);

    let fileForUserDetails = FileForUserGetUserDetails();
      
    const data = {
      prevCcnList: prevCcnList,
      fileForUserId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserId : null,
      fileForUserLoginId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserLoginId : null,
      fileForUserPartyId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserPartyId : null
    }
    securedSendRequest.execute('POST',EhblCloseMsgApiUrls.PRINT_CLOSE_MESSAGE,data, onSuccess, onError, onComplete,config,null);  
  
  }

  function makeTranslatedTextsObject() {
    let ehblCloseMsg = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.TITLE
    )

    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: ehblCloseMsg
      }
    )

    let previousCcn = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.PREVIOUS_CCN
    )

    let primaryCcn = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.PRIMARY_CCN
    )

    let uniqueRefNo = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.UNIQUE_REFERENCE_NO
    )

    let manifestId = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.MANIFEST_ID
    )

    let status = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.STATUS
    )

    let createdDate = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.CREATED_DATE
    )

    let updatedDate = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.UPDATED_DATE
    )

    let submittedBy = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.SUBMITTED_BY
    )

    let submittedDate = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.SUBMITTED_DATE
    )

    let saveButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.SAVE_BUTTON
    )

    let editButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.EDIT_BUTTON
    )

    let viewButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.VIEW_BUTTON
    )

    let cloneButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.CLONE_BUTTON
    )

    let deleteButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.DELETE_BUTTON
    )

    let updateButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.UPDATE_BUTTON
    )

    let submitButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.SUBMIT_BUTTON
    )

    let editSentRecord = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.EDIT_SENT_RECORDS
    )

    let msgFuncOriginal = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.MSG_FUNC_ORIGINAL
    )

    let msgFuncChange = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.MSG_FUNC_CHANGE
    )

    let msgFuncCancel = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.MSG_FUNC_CANCEL
    )

    let msgFuncProposedAmendment = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.MSG_FUNC_PROPOSED_AMENDMENT
    )

    let printButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.PRINT_BUTTON
    )

    let submitSuccessMessage = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblCloseMsgKeys.SUBMIT_SUCCESS_MSG
    )

    let submitSentRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblCloseMsgKeys.SUBMIT_SENT_RECORD
    )

    return {
      ehblCloseMsg,
      tableTitle,
      previousCcn,
      primaryCcn,
      uniqueRefNo,
      manifestId,
      status,
      createdDate,
      updatedDate,
      submittedBy,
      submittedDate,
      saveButton,
      editButton,
      viewButton,
      cloneButton,
      deleteButton,
      updateButton,
      submitButton,
      editSentRecord,
      msgFuncOriginal,
      msgFuncChange,
      msgFuncCancel,
      msgFuncProposedAmendment,
      printButton,
      submitSuccessMessage,
      submitSentRecord
    }
  }

  const columns = [
    {
      field: "prevCcn",
      sortKey: "prevCcn",
      title: translatedTextsObject.previousCcn,
    },
    {
      field: "primaryCcn",
      sortKey: "primaryCcn",
      title: translatedTextsObject.primaryCcn,
    },
    {
      field: "uniqueRefNo",
      sortKey: "uniqueRefNo",
      title: translatedTextsObject.uniqueRefNo,
    },
    {
      field: "status",
      sortKey: "status",
      title: translatedTextsObject.status,
      render: (data) => {

        let status = getStatusMetadata(data.status === 'AC' ? '1000' : data.status)

        let content = [
          <Box>
            <Typography variant='inherit' style={{ color: status.color }}>
              {status.label}
            </Typography>
          </Box>
        ]

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            {content}
          </Box>
        )
      }
    },
    {
      title: 'Response message / Amendment code',
      render: (rowData) => {
        const selectedRows = tableRef.current.selectedRows
        const isSelected =
          selectedRows.length > 0
            ? selectedRows.map((rowData) => rowData.id).includes(rowData.id)
            : false

        return (
          <MessageFuncAndAmendmentCode
            amendmentCodeOptions={amendmentCodeOptions}
            isSelected={isSelected}
            isSentStatus={rowData.status === 'ST'}
            onUpdateCloseMsg={handleModifyCloseMsg}
            onSubmitCloseMsg={(data) => preSubmitCloseMsgs([data])}
            closeMsg={rowData}
            translatedTextsObject={translatedTextsObject}
          />
        )
      }
    },
  ]

  return (
    <>
      <Table
        actions={[
        ]}
        //checkboxSelection
        columns={columns}
        compact
        customRowActions={[
        ]}
        fetch={{ url: EhblCloseMsgApiUrls.GET }}
        fetchFilters={[
          { field: 'manifestId', operator: EQUAL, value: manifestId }
        ]}
        onRowClick={(rowData) =>
          setCloseMsgDialog({ open: true, closeMsg: CloseMsgFormProperties.toClientDataFormat(rowData), isView: true })
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === 'ST',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) => {
              rowData.cloneFromManifest = "N"
              setCloseMsgDialog({
                open: true,
                closeMsg: CloseMsgFormProperties.toClientDataFormat(rowData),
                isView: false
              })},
            tooltip: (rowData) =>
              rowData.status === 'ST'
                ? translatedTextsObject.editSentRecord
                : null
          },
          {
            disabled: (rowData) => rowData.status !== 'DR',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) =>
              setConfirmDialog({ open: true, closeMsg: rowData }),
            tooltip: (rowData) =>
              rowData.status !== 'DR'
                ? translatedTextsObject.deleteNotDraftRecord
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.printButton,
            onClick: (rowData) => {
              let list = []
              list.push(rowData)
              handlePrintCloseMessage(list)
            }
          },
        ]}
        selectActions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                minWidth: 'auto'
              }
            },
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: translatedTextsObject.submitButton,
            onClick: (rows) => preSubmitCloseMsgs(rows),
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  includesSentRecords = true
                }
              })

              return includesSentRecords
                ? translatedTextsObject.submitSentRecord
                : null
            }
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                minWidth: 'auto'
              }
            },
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'cog']} />,
            label: 'Set response message',
            onClick: (rows) =>
              setSetResponseMsgDialog({ open: true, closeMsgs: rows }),
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  includesSentRecords = true
                }
              })

              return includesSentRecords
                ? translatedTextsObject.submitSentRecord
                : translatedTextsObject.submitButton
            }
          },
          /*{
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                minWidth: 'auto'
              }
            },
            icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
            label: 'Export',
            onClick: () => { }
          },*/ 
        ]}
        search={{ field: 'previousCcn', operator: LIKE }}
        showNotification={showNotification}
        tableRef={tableRef}
      />
      <CloseMsgDialog
        getCountryStateLabel={getCountryStateLabel}
        getLookupValue={getLookupValue}
        isView={closeMsgDialog.isView}
        manifestId={manifestId}
        loading={loading}
        onClose={() =>
          setCloseMsgDialog({ open: false, closeMsg: null, isView: false })
        }
        onCreateCloseMsg={handleEditCloseMsg}
        onEditCloseMsg ={handleEditCloseMsg}
        open={closeMsgDialog.open}
        closeMsg={closeMsgDialog.closeMsg}
        showNotification={showNotification}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, closeMsg: null })}
        confirmDialog={handleDeleteCloseMsg}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <SetResponseMessageDialog
        amendmentCodeOptions={amendmentCodeOptions}
        onClose={() => setSetResponseMsgDialog({ open: false, closeMsgs: [] })}
        onSaveCloseMsg={(closeMsgs) => {
          handleModifyCloseMsg(closeMsgs)
          setSetResponseMsgDialog({ open: false, closeMsgs: [] })
        }}
        onSubmitCloseMsg={preSubmitCloseMsgs}
        open={setResponseMsgDialog.open}
        closeMsgs={setResponseMsgDialog.closeMsgs}
        translatedTextsObject={translatedTextsObject}
      />
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          handleSubmitCloseMsgs(data)
        }}
        open={submitDialog.open}
        items={submitDialog.closeMsgs}
      />
      <ErrorMessageDialog
        errorMessages={errorMessageDialog.errorMessages}
        open={errorMessageDialog.open}
        onClose={() => setErrorMessageDialog({ open: false })} />
    </>
  )
}

export default CloseMsgAttachTable


function MessageFuncAndAmendmentCode(props) {
  const {
    amendmentCodeOptions,
    isSelected,
    isSentStatus,
    onSubmitCloseMsg,
    closeMsg,
    onUpdateCloseMsg,
    translatedTextsObject
  } = props

  const theme = useTheme()
  const classes = useStyles()

  function handleSubmitButtonClick(event) {
    event.stopPropagation()
    onSubmitCloseMsg(closeMsg)
  }

  return (
    <Grid alignItems='center' container spacing={1} wrap='nowrap'>
      <Grid item xs='auto'>
        <Select
          className={classes.select }
          disabled={isSentStatus }
          native
          onChange={(event) =>
            onUpdateCloseMsg({ ...closeMsg, msgFuncCode: event.target.value })
          }
          onClick={(event) => event.stopPropagation()}
          size='small'
          value={closeMsg.msgFuncCode || ''}
          variant='outlined'
        >
          <option value="" disabled>
            {translatedTextsObject.msgFuncCode}
          </option>
          <option value='9'>{translatedTextsObject.msgFuncOriginal}</option>
          <option value='4'>{translatedTextsObject.msgFuncChange}</option>
          <option value='1'>{translatedTextsObject.msgFuncCancel}</option>
          <option value='52'>{translatedTextsObject.msgFuncProposedAmendment}</option>
        </Select>
      </Grid>
      {closeMsg.msgFuncCode === '52' && (
        <Grid item xs='auto'>
          <Select
            className={classes.select}
            disabled={isSentStatus}
            native
            onChange={(event) =>
              onUpdateCloseMsg({ ...closeMsg, amendmentCode: event.target.value })
            }
            onClick={(event) => event.stopPropagation()}
            size='small'
            value={closeMsg.amendmentCode || ''}
            variant='outlined'
          >
            <option value="" disabled>
              {translatedTextsObject.amendmentCode}
            </option>
            {amendmentCodeOptions
              .map((option) => (
                <option key={option.code} value={option.code}>
                  {option.desc}
                </option>
              ))}
          </Select>
        </Grid>
      )}
      <Grid item xs='auto'>
        <Tooltip
          arrow
          placement='top'
          title={
            isSentStatus
              ? translatedTextsObject.submitSentRecord
              : translatedTextsObject.submitButton
          }
        >
          <span>
            <CngIconButton
              disabled={isSentStatus || !closeMsg.msgFuncCode}
              size='small'
              icon={['fal', 'arrow-alt-right']}
              onClick={handleSubmitButtonClick}
              style={{
                color:
                  isSentStatus
                    ? theme.palette.action.disabled
                    : theme.palette.primary.main
              }}
              type='outlined'
            />
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
