import { Box, Typography, Chip, Tooltip, useTheme, Grid, Paper } from '@material-ui/core'
import {
  components,
  constants,
  useServices,
  useTranslation
} from 'cng-web-lib'
import moment from 'moment-timezone'
import { useParams } from 'react-router-dom'

import EhblManifestForwardApiUrls from 'src/apiUrls/EhblManifestForwardApiUrls'
import React, { useEffect, useRef, useState } from 'react'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import Namespace from 'src/constants/locale/Namespace'
import Table from '../../../components/aciacehighway/Table'
import { FileForUserGetPartyId, FileForUserGetUserDetails,GetFilterByUserIdCriteria } from 'src/common/FileForUserCommon'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import { getStatusMetadata } from '../../../common/NACommon'
import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls";

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  filter: { BETWEEN, EQUAL, IN, LIKE,NOT_EQUAL }
} = constants

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const { history, location, manifestId, showNotification } = props

  const { id } = useParams()
  const tableRef = useRef(null)
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.EHBL_HOUSE_BILL
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  const [lookups, setLookups] = useState(null)
  const [sbPartyIdList, setSBPartyIdList] = useState([])
  const [sbPartyIdFetched, setSBPartyIdFetched] = useState(false)

  useEffect(() => {
    Promise.all([
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_HB_STATUS' }],
        undefined,
        'code'
      ),
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        { customData: { codeMType: 'CustomsOffice' } },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      )
    ]).then(([status,portCode]) => {
      console.log(status)
      let statusCode = []
      if(status!=null){
        Object.keys(status).forEach((key) => {
          let statusCodeObject = {}
          statusCodeObject.label = status[key]
          statusCodeObject.value = status[key]
          statusCodeObject.filterValue = {
            "value" : key
          }
          statusCode.push(statusCodeObject)
        });
      }
      setLookups({ status,portCode,statusCode})
    })

    let loginId = "";
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setSBPartyIdList([])
      setSBPartyIdFetched(true);
    } else {
      let userPreferenceJSON = JSON.parse(userPreference);
      loginId = userPreferenceJSON.tprUserDetail.userProfile.loginId;
    }
    console.log("loginId: " + JSON.stringify(loginId));

    if (loginId !== "") {
      function onSuccess(response) {
        setSBPartyIdList(response.data)
        setSBPartyIdFetched(true);
        console.log("sbPartyIdList: " + JSON.stringify(sbPartyIdList));
      }

      function onError(error) {
        console.log("sbPartyIdList Error: " + JSON.stringify(error));
      }

      function onComplete() { }
      const config = {}

      securedSendRequest.execute('POST', UserProfileApiUrls.SB_PARTYID_LIST, { loginId }, onSuccess, onError, onComplete, config, null);

    }
  }, []);

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  let filter = GetFilterByUserIdCriteria("EHBL")

  function makeTranslatedTextsObject() {
    let ehblHouseBill = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: ehblHouseBill
      }
    )
    let manifestId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MANIFEST_ID
    )
    let corpid = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CORPID
    )
    let movementType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MOVEMENT_TYPE
    )
    let indicatorMode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.INDICATOR_MODE
    )
    let primaryCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRIMARY_CCN
    )
    let carrierCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CARRIER_CODE
    )
    let ccn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CCN
    )
    let portOfDischargeCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PORT_OF_DISCHARGE_CODE
    )
    let portOfDestCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PORT_OF_DEST_CODE
    )
    let messageFuncCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MESSAGE_FUNC_CODE
    )
    let consolidationInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONSOLIDATION_IND
    )
    let totalWeight = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TOTAL_WEIGHT
    )
    let status = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.STATUS
    )
    let closeStatus = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CLOSE_STATUS
    )
    let responseDatetime = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.RESPONSE_DATETIME
    )
    let serviceBureauId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SERVICE_BUREAU_ID
    )
    let upsClientId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPS_CLIENT_ID
    )
    let userRole = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.USER_ROLE
    )
    let email = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EMAIL
    )
    let mobileNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MOBILE_NO
    )
    let totalWeightUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TOTAL_WEIGHT_UOM
    )
    let shipperName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SHIPPER_NAME
    )
    let consigneeName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONSIGNEE_NAME
    )
    let entryNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ENTRY_NO
    )
    let amendmentCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.AMENDMENT_CODE
    )
    let ucn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UCN
    )
    let b2bComment = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.B2B_COMMENT
    )
    let destSubLocCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DEST_SUB_LOC_CODE
    )
    let discSubLocCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DISC_SUB_LOC_CODE
    )
    let undgContactName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNDG_CONTACT_NAME
    )
    let undgContactNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNDG_CONTACT_NO
    )
    let specialInstruction = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SPECIAL_INSTRUCTION
    )
    let isDangerousGoods = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.IS_DANGEROUS_GOODS
    )
    let hanInstruction = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.HAN_INSTRUCTION
    )
    let volume = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.VOLUME
    )
    let volumeUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.VOLUME_UOM
    )
    let containerizedInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONTAINERIZED_IND
    )
    let mfInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MF_IND
    )
    let mfDatetime = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MF_DATETIME
    )
    let businessName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.BUSINESS_NAME
    )
    let freightFwdCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FREIGHT_FWD_CODE
    )
    let uniqueRefNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNIQUE_REF_NO
    )
    let clMsgInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CL_MSG_IND
    )
    let prevCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PREV_CCN
    )
    let noticeReasonCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.NOTICE_REASON_CODE
    )
    let houseRefNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.HOUSE_REF_NO
    )
    let usPortOfExit = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.US_PORT_OF_EXIT
    )
    let usPortOfExitDesc = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.US_PORT_OF_EXIT_DESC
    )
    let frnPortOfLoading = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FRN_PORT_OF_LOADING
    )
    let frnPortOfLoadingDesc = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FRN_PORT_OF_LOADING_DESC
    )
    let remarks = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.REMARKS
    )
    let printDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_DATE
    )
    let accountOff = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ACCOUNT_OFF
    )
    let storageDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.STORAGE_DATE
    )
    let partyId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMITTED_DATE
    )
    let templateName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TEMPLATE_NAME
    )
    let modeOfSubmission = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MODE_OF_SUBMISSION
    )

    let createdBy = translate(
      Namespace.EHBL_MANIFEST,
      EhblHouseBillKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.EHBL_MANIFEST,
      EhblHouseBillKeys.CREATED_DATE
    )

    let updatedBy = translate(
      Namespace.EHBL_MANIFEST,
      EhblHouseBillKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.EHBL_MANIFEST,
      EhblHouseBillKeys.UPDATED_DATE
    )

    let editButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EDIT_BUTTON
    )

    let editSentRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EDIT_SENT_RECORD
    )

    let cloneButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CLONE_BUTTON
    )

    let deleteButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DELETE_BUTTON
    )

    let deleteNotDraftRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DELETE_NOT_DRAFT_RECORD
    )

    return {
      ehblHouseBill,
      tableTitle,
      manifestId,
      corpid,
      movementType,
      indicatorMode,
      primaryCcn,
      carrierCode,
      ccn,
      portOfDischargeCode,
      portOfDestCode,
      messageFuncCode,
      consolidationInd,
      totalWeight,
      status,
      closeStatus,
      responseDatetime,
      serviceBureauId,
      upsClientId,
      userRole,
      email,
      mobileNo,
      totalWeightUom,
      shipperName,
      consigneeName,
      entryNo,
      amendmentCode,
      ucn,
      b2bComment,
      destSubLocCode,
      discSubLocCode,
      undgContactName,
      undgContactNo,
      specialInstruction,
      isDangerousGoods,
      hanInstruction,
      volume,
      volumeUom,
      containerizedInd,
      mfInd,
      mfDatetime,
      businessName,
      freightFwdCode,
      uniqueRefNo,
      clMsgInd,
      prevCcn,
      noticeReasonCode,
      houseRefNo,
      usPortOfExit,
      usPortOfExitDesc,
      frnPortOfLoading,
      frnPortOfLoadingDesc,
      remarks,
      printDate,
      accountOff,
      storageDate,
      partyId,
      submittedBy,
      submittedDate,
      templateName,
      modeOfSubmission,
      updatedBy,
      updatedDate,
      createdBy,
      createdDate,
      editButton,
      editSentRecord,
      cloneButton,
      deleteButton,
      deleteNotDraftRecord
    }
  }

  const columns = [
    {
      field: "primaryCcn",
      sortKey: "primaryCcn",
      title: translatedTextsObject.primaryCcn,
    },
    {
      field: "ccn",
      sortKey: "ccn",
      title: translatedTextsObject.ccn,
    },
    {
      field: "shipperName",
      sortKey: "shipperName",
      title: translatedTextsObject.shipperName,
    },
    {
      field: "consigneeName",
      sortKey: "consigneeName",
      title: translatedTextsObject.consigneeName,
    },
    {
      field: "status",
      sortKey: "status",
      title: translatedTextsObject.status,
      render: (data) => {

        let status = getStatusMetadata(data.status === 'AC' ? '1000' : data.status)

        let content = [
          <Box>
            <Typography variant='inherit' style={{ color: status.color }}>
              {status.label}
            </Typography>
            <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {data.responseDatetime && moment(data.responseDatetime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')}
            </Typography>
          </Box>
        ]

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            {content}
          </Box>
        )
      }
    },
    {
      field: "createdDate",
      sortKey: "createdDate",
      title: translatedTextsObject.createdDate,
      render: (data) =>
      data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    /*{
      field: "manifestId",
      title: translatedTextsObject.manifestId,
    },
    {
      field: "corpid",
      title: translatedTextsObject.corpid,
    },
    {
      field: "movementType",
      title: translatedTextsObject.movementType,
    },
    {
      field: "indicatorMode",
      title: translatedTextsObject.indicatorMode,
    },
    
    {
      field: "carrierCode",
      title: translatedTextsObject.carrierCode,
    },
    
    {
      field: "portOfDischargeCode",
      title: translatedTextsObject.portOfDischargeCode,
    },
    {
      field: "portOfDestCode",
      title: translatedTextsObject.portOfDestCode,
    },
    {
      field: "messageFuncCode",
      title: translatedTextsObject.messageFuncCode,
    },
    {
      field: "consolidationInd",
      title: translatedTextsObject.consolidationInd,
    },
    {
      field: "totalWeight",
      title: translatedTextsObject.totalWeight,
    },
   
    {
      field: "closeStatus",
      title: translatedTextsObject.closeStatus,
    },
    {
      field: "responseDatetime",
      title: translatedTextsObject.responseDatetime,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "serviceBureauId",
      title: translatedTextsObject.serviceBureauId,
    },
    {
      field: "upsClientId",
      title: translatedTextsObject.upsClientId,
    },
    {
      field: "userRole",
      title: translatedTextsObject.userRole,
    },
    {
      field: "email",
      title: translatedTextsObject.email,
    },
    {
      field: "mobileNo",
      title: translatedTextsObject.mobileNo,
    },
    {
      field: "totalWeightUom",
      title: translatedTextsObject.totalWeightUom,
    },
    {
      field: "shipperName",
      title: translatedTextsObject.shipperName,
    },
    {
      field: "consigneeName",
      title: translatedTextsObject.consigneeName,
    },
    {
      field: "entryNo",
      title: translatedTextsObject.entryNo,
    },
    {
      field: "amendmentCode",
      title: translatedTextsObject.amendmentCode,
    },
    {
      field: "ucn",
      title: translatedTextsObject.ucn,
    },
    {
      field: "b2bComment",
      title: translatedTextsObject.b2bComment,
    },
    {
      field: "destSubLocCode",
      title: translatedTextsObject.destSubLocCode,
    },
    {
      field: "discSubLocCode",
      title: translatedTextsObject.discSubLocCode,
    },
    {
      field: "undgContactName",
      title: translatedTextsObject.undgContactName,
    },
    {
      field: "undgContactNo",
      title: translatedTextsObject.undgContactNo,
    },
    {
      field: "specialInstruction",
      title: translatedTextsObject.specialInstruction,
    },
    {
      field: "isDangerousGoods",
      title: translatedTextsObject.isDangerousGoods,
    },
    {
      field: "hanInstruction",
      title: translatedTextsObject.hanInstruction,
    },
    {
      field: "volume",
      title: translatedTextsObject.volume,
    },
    {
      field: "volumeUom",
      title: translatedTextsObject.volumeUom,
    },
    {
      field: "containerizedInd",
      title: translatedTextsObject.containerizedInd,
    },
    {
      field: "mfInd",
      title: translatedTextsObject.mfInd,
    },
    {
      field: "mfDatetime",
      title: translatedTextsObject.mfDatetime,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "businessName",
      title: translatedTextsObject.businessName,
    },
    {
      field: "freightFwdCode",
      title: translatedTextsObject.freightFwdCode,
    },
    {
      field: "uniqueRefNo",
      title: translatedTextsObject.uniqueRefNo,
    },
    {
      field: "clMsgInd",
      title: translatedTextsObject.clMsgInd,
    },
    {
      field: "prevCcn",
      title: translatedTextsObject.prevCcn,
    },
   
    
    {
      field: "usPortOfExit",
      title: translatedTextsObject.usPortOfExit,
    },
    {
      field: "usPortOfExitDesc",
      title: translatedTextsObject.usPortOfExitDesc,
    },
    {
      field: "frnPortOfLoading",
      title: translatedTextsObject.frnPortOfLoading,
    },
    {
      field: "frnPortOfLoadingDesc",
      title: translatedTextsObject.frnPortOfLoadingDesc,
    },
    {
      field: "remarks",
      title: translatedTextsObject.remarks,
    },
    {
      field: "printDate",
      title: translatedTextsObject.printDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "accountOff",
      title: translatedTextsObject.accountOff,
    },
    {
      field: "storageDate",
      title: translatedTextsObject.storageDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "partyId",
      title: translatedTextsObject.partyId,
    },
    {
      field: "submittedBy",
      title: translatedTextsObject.submittedBy,
    },
    {
      field: "submittedDate",
      title: translatedTextsObject.submittedDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "templateName",
      title: translatedTextsObject.templateName,
    },
    {
      field: "modeOfSubmission",
      title: translatedTextsObject.modeOfSubmission,
    }*/
  ]

  let statusCode = [
    {
      label: 'Draft',
      value: 'draft',
      filterValue: {
        value: 'DR'
      }
    },
    {
      label: 'Accepted',
      value: 'accepted',
      filterValue: {
        value: 'AC'
      }
    },
    {
      label: 'Sent',
      value: 'sent',
      filterValue: {
        value: 'ST'
      }
    },
    {
      label: 'Rejected',
      value: 'rejected',
      filterValue: {
        value: 'RJ'
      }
    },
    /*{
      label: 'Template',
      value: 'Template',
      filterValue: {
        value: 'TP'
      }
    },*/
    {
      label: 'Status Notification',
      value: 'status_notification',
      filterValue: {
        value: 'SN'
      }
    },
    {
      label: 'Cancellation Accepted',
      value: 'cancellation_accepted',
      filterValue: {
        value: 'CL'
      }
    },
    {
      label: 'Cancellation Rejected',
      value: 'cancellation_rejected',
      filterValue: {
        value: 'CR'
      }
    },
    {
      label: 'Modification Rejected',
      value: 'modification_rejected',
      filterValue: {
        value: 'MR'
      }
    }
  ]

  const filters = [
    {
      name: "primaryCcn",
      label: translatedTextsObject.primaryCcn,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: "ccn",
      label: translatedTextsObject.ccn,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: "shipperName",
      label: translatedTextsObject.shipperName,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: "consigneeName",
      label: translatedTextsObject.consigneeName,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: "noticeReasonCode",
      label: translatedTextsObject.noticeReasonCode,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: "status",
      label: translatedTextsObject.status,
      type: 'checkbox',
      operatorType: IN,
      options: statusCode
    },
    {
      name: "updatedDate",
      label: translatedTextsObject.updatedDate,
      type: 'daterange',
      operatorType: LIKE
    },
    {
      name: "createdDate",
      label: translatedTextsObject.updatedDate,
       type: 'daterange',
      operatorType: LIKE
    },
    {
      name: "houseRefNo",
      label: translatedTextsObject.houseRefNo,
      type: 'textfield',
      operatorType: LIKE
    }
  ]

  return (
    <> { sbPartyIdFetched && (
    <Table
        actions={[
        ]}
        columns={columns}
        compact
        exportData={{ url: EhblManifestForwardApiUrls.EXPORT }}
        fetch={{ url: EhblManifestForwardApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: IN, value: sbPartyIdList.length > 0 ? sbPartyIdList : FileForUserGetPartyId() },
          ...filter     
        ]}
        filters={filters}
        onRowClick={(rowData) => {
            history.push(`${location.pathname}/view/${rowData.id}`)
        }
        }
        rowActions={[
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />)}
    </>
  )
}

export default TablePage
