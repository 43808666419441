import React, { useEffect,useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EquipmentMasterKeys from 'src/constants/locale/key/EquipmentMaster'
import { Grid, Card, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import LicensePlatesFormProperties from './LicensePlatesFormProperties'
import { useFormContext } from "react-hook-form";
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'
import CngSection from '../../components/cngcomponents/CngSection'
import LocalTable from '../../components/aciacehighway/LocalTable'
import DialogForm from '../../components/aciacehighway/DialogForm'
import AlertDialog from '../../components/aciacehighway/AlertDialog'

const {
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  acctId: "",
  equipmentId: "",
  carrierCode: "",
  equipmentNo: "",
  equipmentType: "",
  aceId: "",
  status: "DR",
  licensePlates: [],
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, isView, shouldHideMap }) {
  const [formDialog, setFormDialog] = useState({ open: false, licensePlate: null })
  const [confirmDialog, setConfirmDialog] = useState({ open: false, licensePlate: null })
  const { setValue, getValues, watch } = useFormContext()
  const status = watch('status')
  const licensePlates = watch('licensePlates')

  const { translate } = useTranslation(Namespace.EQUIPMEMENT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      const { fileForUserId, fileForUserPartyId, fileForUserLoginId } =
        fileForUserDetails

      setValue('fileForUserId', fileForUserId)
      setValue('fileForUserPartyId', fileForUserPartyId)
      setValue('fileForUserLoginId', fileForUserLoginId)
    }
  }, [])

  function makeTranslatedTextsObject() {
    let equipmentMaster = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.TITLE
    )
    let acctId = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.ACCT_ID
    )
    let equipmentId = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.EQUIPMENT_ID
    )
    let carrierCode = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.CARRIER_CODE
    )
    let equipmentNo = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.EQUIPMENT_NO
    )
    let equipmentType = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.EQUIPMENT_TYPE
    )
    let aceId = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.ACE_ID
    )
    let status = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.STATUS
    )
    let licensePlates = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.LicensePlates.TITLE
    )
    let licensePlateNo = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.LicensePlates.LICENSE_PLATE_NO
    )
    let countryofRegistration = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.LicensePlates.COUNTRYOF_REGISTRATION
    )
    let state = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.LicensePlates.STATE
    )

    return {
      equipmentMaster,
      acctId,
      equipmentId,
      carrierCode,
      equipmentNo,
      equipmentType,
      licensePlates,
      licensePlateNo,
      countryofRegistration,
      state,
      aceId,
      status
    }
  }

  function handleAddLicensePlate(data) {
    const licensePlates = [...getValues('licensePlates'), data]

    setValue('licensePlates', licensePlates)
    setFormDialog({ open: false, licensePlate: null })
  }

  function handleDeleteLicensePlate() {
    if (!confirmDialog.licensePlate) return

    const licensePlates = [...getValues('licensePlates')].filter(
      (licensePlate) => licensePlate._id !== confirmDialog.licensePlate._id
    )

    setValue('licensePlates', licensePlates)
    setConfirmDialog({ open: false, licensePlate: null })
  }

  function handleEditLicensePlate(data) {
    const licensePlates = [...getValues('licensePlates')]
    const index = licensePlates.findIndex(
      (licensePlate) => licensePlate._id === data._id
    )

    licensePlates[index] = data

    setValue('licensePlates', licensePlates)
    setFormDialog({ open: false, licensePlate: null })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CngSection
            title={translatedTextsObject.equipmentMaster}
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>*</Typography>
                Mandatory fields
              </Typography>
            }
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.equipmentId}>
                <CngTextField
                  name="equipmentId"
                  label={translatedTextsObject.equipmentId}
                  isRequired
                  disabled={disabled}
                  size='small'
                  onBlur={(e) => setValue('equipmentId', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierCode}>
                <CngTextField
                  name="carrierCode"
                  label={translatedTextsObject.carrierCode}
                  isRequired
                  disabled={disabled}
                  size='small'
                  onBlur={(e) => setValue('carrierCode', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.equipmentNo}>
                <CngTextField
                  name="equipmentNo"
                  label={translatedTextsObject.equipmentNo}
                  isRequired
                  disabled={disabled}
                  size='small'
                  onBlur={(e) => setValue('equipmentNo', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.equipmentType}>
                <CngCodeMasterAutocompleteField name="equipmentType"
                  label={translatedTextsObject.equipmentType}
                  isRequired
                  disabled={disabled}
                  size='small'
                  codeType='EQ_MASTER_EQUIPMENT_TYPE'
                  lookupProps={{
                    label: (record) =>
                      [record.code, record.descriptionEn].join('; ')
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.aceId}>
                <CngTextField
                  name="aceId"
                  label={translatedTextsObject.aceId}
                  disabled={status === 'AC' || disabled}
                  size='small'
                  onBlur={(e) => setValue('aceId', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.status}>
                <CngCodeMasterAutocompleteField 
                  name="status"
                  label={translatedTextsObject.status}
                  disabled
                  size='small'
                  codeType='EQ_MASTER_STATUS'
                />
              </CngGridItem>
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <LocalTable
              columns={[
                {
                  title: translatedTextsObject.licensePlateNo,
                  field: 'licensePlateNo'
                },
                {
                  title: translatedTextsObject.countryofRegistration,
                  field: 'countryofRegistration'
                },
                {
                  title: translatedTextsObject.state,
                  field: 'state'
                }
              ]}
              data={licensePlates}
              header={
                <Typography style={{ fontWeight: 600 }}>
                  {translatedTextsObject.licensePlates}
                </Typography>
              }
              {...(!isView && {
                actions: [
                  {
                    color: 'primary',
                    buttonProps: { color: 'secondary' },
                    label: 'Add License Plate',
                    icon: ['fal', 'plus-circle'],
                    onClick: () => setFormDialog({ open: true, licensePlate: null })
                  }
                ],
                rowActions: [
                  {
                    icon: ['fal', 'pen'],
                    onClick: (rowData) =>
                      setFormDialog({ open: true, licensePlate: rowData }),
                    tooltip: 'Edit'
                  },
                  {
                    icon: ['fal', 'trash'],
                    onClick: (rowData) =>
                      setConfirmDialog({ open: true, licensePlate: rowData }),
                    tooltip: 'Delete'
                  }
                ]
              })}
            />
          </Card>
        </Grid>
      </Grid>
      <DialogForm
        formProperties={LicensePlatesFormProperties}
        fullWidth
        isView={isView}
        maxWidth='md'
        open={formDialog.open}
        onClose={() => setFormDialog({ open: false, licensePlate: null })}
        onSubmit={
          formDialog.licensePlate
            ? handleEditLicensePlate
            : handleAddLicensePlate
        }
        title={`${formDialog.licensePlate ? 'Edit' : 'Add New'} License Plate`}
        value={formDialog.licensePlate}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, licensePlate: null })}
        onCancel={() => setConfirmDialog({ open: false, licensePlate: null })}
        onConfirm={handleDeleteLicensePlate}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData.licensePlates.forEach((datum, index) => {
    datum['_id'] = index
  })

  return localData
}

function toServerDataFormat(localData) {
  return localData
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
