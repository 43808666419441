import React from 'react'
import EquipmentEditForm from './EquipmentEditPage'
import ResponseForm from '../acehighway/response/MedpidResponse'
import Namespace from 'src/constants/locale/Namespace'
import { components, useTranslation } from 'cng-web-lib'
import { useParams, useLocation } from 'react-router-dom'
import EquipmentMasterKeys from 'src/constants/locale/key/EquipmentMaster'


const { CngTabs } = components

function EditPage({ showNotification, history }) {
  const { id } = useParams()
  const loc = useLocation()
  const { translate } = useTranslation([Namespace.EQUIPMEMENT_MASTER])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let equipment = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.TITLE
    )

    let response = translate(
      Namespace.EQUIPMEMENT_MASTER,
      EquipmentMasterKeys.RESPONSE_TITLE
    )
    return {
      equipment,
      response
    }
  }


  return (
    <CngTabs
      headerColor='primary'
      tabs={[
        {
          tabName: translatedTextsObject.equipment,
          tabContent: (
            <EquipmentEditForm
              showNotification={showNotification}
              id={id}
              history={history}
            />
          )
        },
        {
          tabName: translatedTextsObject.response,
          tabContent: (
            <ResponseForm
              showNotification={showNotification}
              id={id}
              headerId={loc.state.headerId}
              docType='ACE_MEDPID_EQUI'
            />
          )
        }
      ]}
    />
  );
}

export default EditPage
