import { Grid } from '@material-ui/core'
import React,{useState} from 'react'
import { components,useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import ManifestViewForm from './ManifestViewPage'
import Namespace from 'src/constants/locale/Namespace'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'
import ViewLogsHistory from './ViewLogsHistory'

const {
  form: { CngViewForm },
  CngTabs
} = components

function ViewPage({ history, loading, location, onSetLoading, showNotification }) {
  const { id } = useParams()
  const [data, setData] = useState(null)
  const { state } = location
  const { translate } = useTranslation([Namespace.EHBL_MANIFEST])
  const translatedTextsObject = makeTranslatedTextsObject()
  
  function makeTranslatedTextsObject() {
    let viewManifest = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.VIEW_MANIFEST
    )
    let manifestResponse= translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.RESPONSE_TITLE
    )

    return {
      viewManifest,
      manifestResponse
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.viewManifest,
              tabContent: (
                <ManifestViewForm
                  showNotification={showNotification}
                  id={id}
                  data={data}
                  setData={setData}
                />
              )
            },
            {
              tabName: translatedTextsObject.manifestResponse,
              tabContent: (
                <ViewLogsHistory
                  manifestId={id}
                  history={history}
                  //onChangeStep={(step) => setStep(step)}
                  //onSubmitManifest={handleSubmitManifest}
                  //step={step}
                  //tripNo={manifestData.current?.tripNum}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );

}

export default ViewPage
