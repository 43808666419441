import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'

import EhblHouseBillApiUrls from 'src/apiUrls/EhblHouseBillApiUrls'
import EhblManifestApiUrls from 'src/apiUrls/EhblManifestApiUrls'
import {
  Box,
  Grid,
  makeStyles,
  Select,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import Table from '../../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AttachHouseBillDialog from './AttachHouseBillDialog'
import { getStatusMetadata } from '../../../../common/NACommon'
import HouseBillDialog from './HouseBillDialog'
import HouseBillFormProperties from '../../housebill/FormProperties'
import ConfirmDialog from '../../../common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import SetResponseMessageDialog from './SetResponseMessageDialog'
import PropageteDialog from './PropagateDialog'
import HouseBillCSVUploadDialog from './HouseBillCSVUploadDialog'
import EhblStatusNotificationApiUrls from 'src/apiUrls/EhblStatusNotificationApiUrls'
import ChargeTypeDialog from '../../../common/ChargeTypeDialog'
import { format } from 'date-fns'
import FormProperties from '../../housebill/FormProperties'
import PrintMasterBillDialog from './PrintMasterBillDialog'
import MassSubmitHouseBillDialog from './MassSubmitHouseBillDialog'
import ErrorMessageDialog from 'src/views/common/ErrorMessageDialog'
import sweetalert from 'sweetalert'

const {
  button: { CngIconButton },
  card: { CngSimpleCardHeader },
  table: {
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  },
  CngGridItem,
  form: {
    field: {
      CngTextField
    }
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { EQUAL, BETWEEN, IN, LIKE }
} = constants

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.name === 'ONE_DARK' ? 'transparent' : theme.palette.common.white,
    fontSize: 14,
    maxWidth: '100%',
    width: 150,
    '& > .MuiOutlinedInput-input': {
      padding: 8
    },
    '& > .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 32
    }
  }
}))

function HouseBillAttachTable(props) {
  const {
    history,
    manifestId,
    loading,
    onSetLoading,
    showNotification,
    manifestData
  } = props

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.EHBL_HOUSE_BILL
  ])
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()
  const tableRef = useRef(null)
  const [attachHouseBillDialog, setAttachHouseBillDialog] = useState(false)
  const {
    createRecord,
    deleteRecord,
    fetchRecords,
    securedSendRequest,
    updateRecord
  } = useServices()
  const [printMasterBillDialog, setPrintMasterBillDialog] = useState({ open: false })
  const [houseBillDialog, setHouseBillDialog] = useState({
    open: false,
    houseBill: null,
    isView: false
  })

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    houseBill: null
  })

  const [chargeTypes, setChargeTypes] = useState([])
  const [isSuperParty, setSuperParty] = useState(false)
  const [isMassSubmit, setIsMassSubmit] = useState(false)
  const [submitDialog, setSubmitDialog] = useState({ open: false, houseBills: [] })
  const [amendmentCodeOptions, setAmendmentCodeOptions] = useState([])
  const [statusNotificationData, setStatusNotificationData] = useState([])
  const [propagateDialog, setPropagateDialog] = useState({ open: false, houseBills: [] })
  const [setResponseMsgDialog, setSetResponseMsgDialog] = useState({ open: false, houseBills: [] })
  const [uploadCSVHouseBillDialog, setUploadCSVHouseBillDialog] = useState({ open: false, tableRef: null })
  const [massDeleteConfirmDialog, setMassDeleteConfirmDialog] = useState({ open: false, idList: null })
  const [massSubmitDialog, setMassSubmitDialog] = useState({ open: false })
  const [errorMessageDialog, setErrorMessageDialog] = useState({ open: false, errorMessages: [] })

  const [lookups, setLookups] = useState(null)

  useEffect(() => {

    let manifestId = 0;
    if (manifestData != null && manifestData != undefined && manifestData != "") {
      manifestId = manifestData.current.id;
    }
    const filter = {
      baseFilterDTO: {
        filterType: "AND",
        filterProperties: [
          {
            fieldName: "manifestId",
            operatorType: "EQUAL",
            value1: manifestId
          }
        ],
        sortProperties: []
      }
    }

    Promise.all([
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      ),
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Amendment codes
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
        {
          customData: { codeMType: constants.CodeMaintenanceType.CODE_MASTER },
          filters: [
            {
              field: 'active',
              operator: EQUAL,
              value: 'true'
            },
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'EHBL_AMENDMENT_CODE'
            }
          ]
        },
        (response) => {
          return response.content.map((status) => ({
            code: status.code,
            desc: status.descriptionEn
          }))
        },
        () => {
          showNotification('error', 'Failed to fetch Amendment Code options')
        }
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_HB_STATUS' }],
        undefined,
        'code'
      ),
      // Countries
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACIHWY_MANIFEST_COUNTRY'
          }
        ],
        undefined,
        'code'
      ),
      // Country states
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'ACI' }],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      // Party type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_PARTY_TYPE' }],
        undefined,
        'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'HBL' }],
          customData: { codeMType: 'CustomsOffice' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      // Arrival date time zone
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'SCHEDULER_TIME_ZONE' }],
        undefined,
        'code'
      ),
      // Sublocation
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'HBL' }],
          customData: { codeMType: 'Sublocation' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.intlcode] = item.descriptionEn
          })

          return result
        }
      ),
      // Movement type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_MOVEMENT_TYPE' }],
        undefined,
        'code'
      ), //Mode indicator
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_MODE_INDICATOR' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_SNP_TYPE' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_WEIGHT_UOM' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_VOLUME_UOM' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_SN_TYPE' }],
        undefined,
        'code'
      ),
      // Status Notification
      securedSendRequest.execute(
        'POST',
        EhblStatusNotificationApiUrls.GET,
        filter,
        (res) => res.data.content
      ),
    ]).then(
      ([
        billingSuperParty,
        billingChargeTypes,
        amendmentCodes,
        status,
        countries,
        countryStates,
        partyType,
        customsOffice,
        arrivalDateAndTimeZone,
        sublocation,
        movementType,
        modeIndicator,
        snpType,
        weightUOM,
        volumeUOM,
        snType,
        statusNotificationData
      ]) => {
        console.log(billingChargeTypes)
        setAmendmentCodeOptions(amendmentCodes)
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })

        const states = Object.keys(countries).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        setStatusNotificationData(statusNotificationData)
        setLookups({
          status,
          countries,
          countryStates: states,
          partyType,
          customsOffice,
          arrivalDateAndTimeZone,
          sublocation,
          movementType,
          modeIndicator,
          snpType,
          weightUOM,
          volumeUOM,
          snType
        })
      });
  }, []);

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function handleDetachHouseBills(rows) {
    const result = {
      manifestId: Number(manifestId),
      houseBillId: rows.map((houseBill) => houseBill.id)
    }

    securedSendRequest.execute(
      'POST',
      EhblManifestApiUrls.DETACH,
      result,
      (response) => {
        const data = response.data
        if (data.errorMessages != null && data.errorMessages.length > 0) {
          showNotification('error', data.errorMessages)
        } else if (
          data.errorMessages === null ||
          data.errorMessages === undefined
        ) {
          if (tableRef.current) {
            tableRef.current.performRefresh()
          }

          showNotification('success', translatedTextsObject.detachSuccessMsg)
        }
      },
      (error) => console.log(error)
    )
  }

  function handleCreateHouseBill(data) {
    onSetLoading(true)

    createRecord.execute(
      EhblHouseBillApiUrls.POST,
      HouseBillFormProperties.toServerDataFormat(data),
      (res) => {
        //handleAttachHouseBill(res)

        const data = res
        if (data.errorMessages != null && data.errorMessages.length > 0) {
          showNotification('error', data.errorMessages)
        } else {
          tableRef.current.performRefresh()
          setHouseBillDialog({ open: false, houseBill: null, isView: false })
          showNotification('success', translatedTextsObject.saveSuccessMsg)
        }

      },
      (error) => {
        const { errorMessages } = error.response.data

        errorMessages.forEach((message) => {
          showNotification('error', message)
        })
      },
      () => onSetLoading(false)
    )
  }

  function handleEditHouseBill(data) {
    onSetLoading(true)

    updateRecord.execute(
      EhblHouseBillApiUrls.PUT,
      HouseBillFormProperties.toServerDataFormat(data),
      () => {
        tableRef.current.performRefresh()

        setHouseBillDialog({ open: false, houseBill: null, isView: false })
        showNotification('success', 'House Bill updated.')
      },
      (error) => {
        if (error.response.data.errorMessages) {
          error.response.data.errorMessages.forEach((errorMessage) => {
            showNotification('error', errorMessage)
          })
        }
      },
      () => onSetLoading(false)
    )
  }

  function printMasterBill(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      EhblHouseBillApiUrls.PRINT_MASTER_BILL,
      data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'EHBL_MASTER_BILL_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + ".pdf"

        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  function handleDeleteHouseBill() {
    if (confirmDialog.houseBill) {
      deleteRecord.execute(
        EhblHouseBillApiUrls.DELETE,
        confirmDialog.houseBill,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, houseBill: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function handleMassDeleteHouseBill() {
    if (massDeleteConfirmDialog.idList) {
      setMassDeleteConfirmDialog({ open: false })
      sweetalert("House Bill deletion in progress...", "", "success");
      securedSendRequest.execute('POST',
        EhblHouseBillApiUrls.MASS_DELETE,
        massDeleteConfirmDialog.idList,
        () => {
          showNotification('success', 'Mass delete succeeded')
          setMassDeleteConfirmDialog({ open: false, idList: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong. Mass delete failed.')
          console.log(error)
        }
      )
    }
  }

  function handleCloneHouseBill(data) {

    createRecord.execute(
      EhblHouseBillApiUrls.CLONE,
      data,
      (res) => {
        res.manifestId = manifestId
        res.cloneFromManifest = "Y"

        history.push({
          pathname: pathMap.EHBL_HOUSE_BILL_ADD_VIEW,
          state: res
        })
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function preSubmitHouseBills(houseBills) {
    if (isSuperParty) {
      setIsMassSubmit(false)
      setSubmitDialog({ open: true, houseBills: houseBills })
    } else {
      houseBills.forEach(e => {
        e.chargeAs = 'REGULAR'
      })
      handleSubmithouseBills(houseBills)
    }
  }

  function handleSubmithouseBills(houseBills) {
    try {
      tableRef.current.setLoading(true)
      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        houseBills.forEach(data => {
          console.log("data", data)
          if (typeof data.consolidationInd == "boolean") {
            data = FormProperties.toServerDataFormat(data)
          }
          data.fileForUserId = fileForUserDetails.fileForUserId;
          data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
          data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;

        });
      } else {
        houseBills.forEach(data => {
          if (typeof data.consolidationInd == "boolean") {
            data = FormProperties.toServerDataFormat(data)
          }
        });
      }

      securedSendRequest.execute('POST', EhblManifestApiUrls.SUBMIT_ALL, houseBills,
        (response) => {
          const data = response.data
          var errorMessages = []
          var errorFlag = false

          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessages.push(obj.errorMessages)
              errorFlag = true
            }
          }

          if (errorFlag) {
            setErrorMessageDialog({ open: true, errorMessages })
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMessage)
            tableRef.current.performRefresh()
          }
        },
        error => {
          console.log(error)
          showNotification('error', translatedTextsObject.submitErrorMessage)
        },
        () => tableRef.current.setLoading(false)
      )
    } catch (error) {
      console.log(error)
    }
  }

  function preMassSubmit(houseBills) {
    if (isSuperParty) {
      setIsMassSubmit(true)
      setSubmitDialog({ open: true, houseBills: houseBills })
    } else {
      houseBills.chargeAs = 'REGULAR'
      handleMassSubmit(houseBills)
    }
  }

  function handleMassSubmit(houseBills) {
    try {
      tableRef.current.setLoading(true)

      const formData = new FormData()
      formData.append('manifestId', houseBills.manifestId)
      formData.append('status', houseBills.status)
      formData.append('messageFunction', houseBills.messageFunction)
      formData.append('chargeAs', houseBills.chargeAs)

      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        formData.append("fileForUserDetails", JSON.stringify(fileForUserDetails));
      } else {
        formData.append("fileForUserDetails", "{}");
      }

      securedSendRequest.execute('POST', EhblManifestApiUrls.SUBMIT_ALL, formData,
        (response) => {
          const data = response.data
          var errorMessages = []
          var errorFlag = false
          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessages.push(obj.errorMessages)
              errorFlag = true
            }
          }

          if (errorFlag) {
            setErrorMessageDialog({ open: true, errorMessages })
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMessage)
            tableRef.current.performRefresh()
          }
        },
        error => { showNotification('error', translatedTextsObject.submitErrorMessage) },
        () => tableRef.current.setLoading(false)
      )
    } catch (error) {
      console.log(error)
    }
  }

  function preCreateCloseMessage(houseBills) {

    try {
      tableRef.current.setLoading(true)
      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        houseBills.forEach(data => {
          data.fileForUserId = fileForUserDetails.fileForUserId;
          data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
          data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
        });
      }

      securedSendRequest.execute('POST', EhblHouseBillApiUrls.CREATE_CLOSE_MESSAGE, houseBills,
        (response) => {
          const data = response.data
          var errorMessages = []
          var errorFlag = false
          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessages.push(obj.errorMessages)
              errorFlag = true
            }
          }

          if (errorFlag) {
            setErrorMessageDialog({ open: true, errorMessages })
          } else {
            showNotification('success', translatedTextsObject.createCloseMessageSuccessMessage)
            tableRef.current.performRefresh()
          }
        },
        error => {
          console.log(error)
          showNotification('error', translatedTextsObject.createCloseMessageErrorMessage)
        },
        () => tableRef.current.setLoading(false)
      )
    } catch (error) {
      console.log(error)
    }
  }

  function handleModifyHouseBill(houseBills) {

    if (tableRef.current) {
      tableRef.current.updateData(houseBills)
    }
  }

  function handlePropagateHouseBill(houseBills, prevCcn) {

    if (prevCcn == null || prevCcn == undefined || prevCcn == "") {

      showNotification('error', translatedTextsObject.prevCcnEmpty)
    } else {
      try {
        tableRef.current.setLoading(true)
        let fileForUserDetails = FileForUserGetUserDetails();
        if (fileForUserDetails != null && fileForUserDetails != undefined) {
          houseBills.forEach(data => {
            data.fileForUserId = fileForUserDetails.fileForUserId;
            data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
            data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
          });
        }

        securedSendRequest.execute(
          'POST',
          EhblManifestApiUrls.PROPAGATE,
          houseBills,
          (response) => {
            const data = response.data
            var errorMessages = []
            var errorFlag = false
            for (var key in data) {
              var obj = data[key]
              if (obj.errorMessages != null && obj.errorMessages.length > 0) {
                errorMessages.push(obj.errorMessages)
                errorFlag = true
              }
            }

            if (errorFlag) {
              setErrorMessageDialog({ open: true, errorMessages })
            } else {
              showNotification('success', translatedTextsObject.propagateSuccessMessage)
              setPropagateDialog({ open: false, houseBills: [] })
              tableRef.current.performRefresh()
            }

          },
          error => {
            console.log(error)
            showNotification('error', translatedTextsObject.propagateErrorMessage)
          },
          () => tableRef.current.setLoading(false)
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  function handlePrintHouseBill(houseBills) {
    function onSuccess(response) {
      let blob = new Blob([response.data]);

      let first = "EHBL_HBL_";
      let curDate = format(new Date(), 'yyyyMMddHHmmss');

      let filename = first + curDate + ".pdf";
      if (houseBills.length > 1) {
        filename = first + curDate + ".zip";
      }

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    }

    function onError(error) {
      console.error(error);
    }

    function onComplete() {
    }

    const config = {
      responseType: 'blob'
    }

    let ccnList = houseBills.map((x) => x.ccn);

    let fileForUserDetails = FileForUserGetUserDetails();

    const data = {
      ccnList: ccnList,
      fileForUserId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserId : null,
      fileForUserLoginId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserLoginId : null,
      fileForUserPartyId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserPartyId : null
    }
    securedSendRequest.execute('POST', EhblHouseBillApiUrls.PRINT_HBL, data, onSuccess, onError, onComplete, config, null);

  }

  function handlePrintA8A(houseBills) {
    function onSuccess(response) {
      let blob = new Blob([response.data]);

      let first = "EHBL_A8A_";
      let curDate = format(new Date(), 'yyyyMMddHHmmss');

      let filename = first + curDate + ".pdf";
      if (houseBills.length > 1) {
        filename = first + curDate + ".zip";
      }

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    }

    function onError(error) {
      console.error(error);
    }

    function onComplete() {
    }

    const config = {
      responseType: 'blob'
    }

    let ccnList = houseBills.map((x) => x.ccn);

    let fileForUserDetails = FileForUserGetUserDetails();

    const data = {
      ccnList: ccnList,
      fileForUserId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserId : null,
      fileForUserLoginId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserLoginId : null,
      fileForUserPartyId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserPartyId : null
    }
    securedSendRequest.execute('POST', EhblHouseBillApiUrls.PRINT_A8A, data, onSuccess, onError, onComplete, config, null);

  }

  function makeTranslatedTextsObject() {
    let ehblHouseBill = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: ehblHouseBill
      }
    )
    let manifestId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MANIFEST_ID
    )
    let corpid = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CORPID
    )
    let movementType = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MOVEMENT_TYPE
    )
    let indicatorMode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.INDICATOR_MODE
    )
    let primaryCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRIMARY_CCN
    )
    let carrierCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CARRIER_CODE
    )
    let ccn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CCN
    )
    let portOfDischargeCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PORT_OF_DISCHARGE_CODE
    )
    let portOfDestCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PORT_OF_DEST_CODE
    )
    let messageFuncCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MESSAGE_FUNC_CODE
    )
    let consolidationInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONSOLIDATION_IND
    )
    let totalWeight = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TOTAL_WEIGHT
    )
    let status = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.STATUS
    )
    let closeStatus = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CLOSE_STATUS
    )
    let responseDatetime = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.RESPONSE_DATETIME
    )
    let serviceBureauId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SERVICE_BUREAU_ID
    )
    let upsClientId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPS_CLIENT_ID
    )
    let userRole = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.USER_ROLE
    )
    let email = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EMAIL
    )
    let mobileNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MOBILE_NO
    )
    let totalWeightUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TOTAL_WEIGHT_UOM
    )
    let shipperName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SHIPPER_NAME
    )
    let consigneeName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONSIGNEE_NAME
    )
    let entryNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ENTRY_NO
    )
    let amendmentCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.AMENDMENT_CODE
    )
    let ucn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UCN
    )
    let b2bComment = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.B2B_COMMENT
    )
    let destSubLocCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DEST_SUB_LOC_CODE
    )
    let discSubLocCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DISC_SUB_LOC_CODE
    )
    let undgContactName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNDG_CONTACT_NAME
    )
    let undgContactNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNDG_CONTACT_NO
    )
    let specialInstruction = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SPECIAL_INSTRUCTION
    )
    let isDangerousGoods = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.IS_DANGEROUS_GOODS
    )
    let hanInstruction = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.HAN_INSTRUCTION
    )
    let volume = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.VOLUME
    )
    let volumeUom = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.VOLUME_UOM
    )
    let containerizedInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CONTAINERIZED_IND
    )
    let mfInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MF_IND
    )
    let mfDatetime = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MF_DATETIME
    )
    let businessName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.BUSINESS_NAME
    )
    let freightFwdCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FREIGHT_FWD_CODE
    )
    let uniqueRefNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UNIQUE_REF_NO
    )
    let clMsgInd = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CL_MSG_IND
    )
    let prevCcn = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PREV_CCN
    )
    let noticeReasonCode = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.NOTICE_REASON_CODE
    )
    let houseRefNo = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.HOUSE_REF_NO
    )
    let usPortOfExit = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.US_PORT_OF_EXIT
    )
    let usPortOfExitDesc = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.US_PORT_OF_EXIT_DESC
    )
    let frnPortOfLoading = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FRN_PORT_OF_LOADING
    )
    let frnPortOfLoadingDesc = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.FRN_PORT_OF_LOADING_DESC
    )
    let remarks = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.REMARKS
    )
    let printDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_DATE
    )
    let accountOff = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.ACCOUNT_OFF
    )
    let storageDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.STORAGE_DATE
    )
    let partyId = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMITTED_DATE
    )
    let templateName = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.TEMPLATE_NAME
    )
    let modeOfSubmission = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MODE_OF_SUBMISSION
    )

    let createdBy = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CREATED_DATE
    )

    let updatedBy = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPDATED_DATE
    )

    let msgFuncOriginal = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MSG_FUNC_ORIGINAL
    )

    let msgFuncChange = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MSG_FUNC_CHANGE
    )

    let msgFuncCancel = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MSG_FUNC_CANCEL
    )

    let msgFuncProposedAmendment = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.MSG_FUNC_PROPOSED_AMENDMENT
    )

    let submitButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMIT_BUTTON
    )

    let submitSentRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMIT_SENT_RECORD
    )

    let detachButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DETACH_BUTTON
    )

    let detachSentRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DETACH_SENT_RECORD
    )

    let editButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EDIT_BUTTON
    )

    let editSentRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.EDIT_SENT_RECORD
    )

    let cloneButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CLONE_BUTTON
    )

    let deleteButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DELETE_BUTTON
    )

    let deleteNotDraftRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DELETE_NOT_DRAFT_RECORD
    )

    let createCloseMessage = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CREATE_CLOSE_MESSAGE
    )

    let createCloseMessageSuccessMessage = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CREATE_CLOSE_MESSAGE_SUCCESS_MESSAGE
    )

    let createCloseMessageErrorMessage = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CREATE_CLOSE_MESSAGE_ERROR_MESSAGE
    )

    let propagate = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PROPAGATE
    )

    let propagateSuccessMessage = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PROPAGATE_SUCCESS_MESSAGE
    )

    let propagateErrorMessage = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PROPAGATE_ERROR_MESSAGE
    )

    let prevCcnEmpty = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PREV_CCN_EMPTY
    )

    let propagateSentRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PROPAGATE_SENT_RECORD
    )

    let backButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.BACK_BUTTON
    )

    let createCloseMessageSentRecord = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.CREATE_CLOSE_MESSAGE_SENT_RECORD
    )

    let detachSuccessMsg = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.DETACH_SUCCESS_MESSAGE
    )


    let uploadCSVButton = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.UPLOAD_CSV_BUTTON
    )

    let printHBL = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_HBL
    )

    let printA8A = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.PRINT_A8A
    )

    let saveSuccessMsg = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SAVE_SUCCESS_MSG
    )

    let submitSuccessMessage = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMIT_SUCCESS_MSG
    )
    let submitErrorMessage = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SUBMIT_ERROR_MSG
    )
    let selectRecordSubmit = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SELECT_RECORD_SUBMIT
    )

    return {
      ehblHouseBill,
      tableTitle,
      manifestId,
      corpid,
      movementType,
      indicatorMode,
      primaryCcn,
      carrierCode,
      ccn,
      portOfDischargeCode,
      portOfDestCode,
      messageFuncCode,
      consolidationInd,
      totalWeight,
      status,
      closeStatus,
      responseDatetime,
      serviceBureauId,
      upsClientId,
      userRole,
      email,
      mobileNo,
      totalWeightUom,
      shipperName,
      consigneeName,
      entryNo,
      amendmentCode,
      ucn,
      b2bComment,
      destSubLocCode,
      discSubLocCode,
      undgContactName,
      undgContactNo,
      specialInstruction,
      isDangerousGoods,
      hanInstruction,
      volume,
      volumeUom,
      containerizedInd,
      mfInd,
      mfDatetime,
      businessName,
      freightFwdCode,
      uniqueRefNo,
      clMsgInd,
      prevCcn,
      noticeReasonCode,
      houseRefNo,
      usPortOfExit,
      usPortOfExitDesc,
      frnPortOfLoading,
      frnPortOfLoadingDesc,
      remarks,
      printDate,
      accountOff,
      storageDate,
      partyId,
      submittedBy,
      submittedDate,
      templateName,
      modeOfSubmission,
      updatedBy,
      updatedDate,
      createdBy,
      createdDate,

      msgFuncOriginal,
      msgFuncChange,
      msgFuncCancel,
      msgFuncProposedAmendment,
      submitButton,
      submitSentRecord,
      detachButton,
      detachSentRecord,
      editButton,
      editSentRecord,
      cloneButton,
      deleteButton,
      deleteNotDraftRecord,
      createCloseMessage,
      propagate,
      createCloseMessageSuccessMessage,
      createCloseMessageErrorMessage,
      propagateSuccessMessage,
      propagateErrorMessage,
      prevCcnEmpty,
      propagateSentRecord,
      backButton,
      createCloseMessageSentRecord,
      detachSuccessMsg,
      uploadCSVButton,
      printHBL,
      printA8A,
      saveSuccessMsg,
      submitSuccessMessage,
      submitErrorMessage,
      selectRecordSubmit
    }
  }

  function disableMassDelete(rows) {
    let shouldDisable = false

    rows.forEach((rowData) => {
      if (rowData.status !== 'DR') {
        shouldDisable = true
      }
    })

    return shouldDisable
  }

  const columns = [
    {
      field: "prevCcn",
      sortKey: "prevCcn",
      title: translatedTextsObject.prevCcn,
    },
    {
      field: "ccn",
      sortKey: "ccn",
      title: translatedTextsObject.ccn,
    },
    {
      field: "shipperName",
      sortKey: "shipperName",
      title: translatedTextsObject.shipperName,
    },
    {
      field: "consigneeName",
      sortKey: "consigneeName",
      title: translatedTextsObject.consigneeName,
    },
    {
      field: "noticeReasonCode",
      sortKey: "noticeReasonCode",
      title: translatedTextsObject.noticeReasonCode,
    },
    {
      field: "status",
      sortKey: "status",
      title: translatedTextsObject.status,
      render: (data) => {

        let status = getStatusMetadata(data.status === 'AC' ? '1000' : data.status)

        let content = [
          <Box>
            <Typography variant='inherit' style={{ color: status.color }}>
              {status.label}
            </Typography>
            <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {data.responseDatetime && moment(data.responseDatetime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')}
            </Typography>
          </Box>
        ]

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            {content}
          </Box>
        )
      }
    },
    {
      title: 'Submission message / Amendment code',
      render: (rowData) => {
        const selectedRows = tableRef.current.selectedRows
        const isSelected =
          selectedRows.length > 0
            ? selectedRows.map((rowData) => rowData.id).includes(rowData.id)
            : false

        return (
          <MessageFuncAndAmendmentCode
            amendmentCodeOptions={amendmentCodeOptions}
            isSelected={isSelected}
            isSentStatus={rowData.status === 'ST'}
            onUpdateHouseBill={handleModifyHouseBill}
            onSubmitHouseBill={(data) => preSubmitHouseBills([data])}
            houseBill={rowData}
            translatedTextsObject={translatedTextsObject}
          />
        )
      }
    },
  ]

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'secondary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'print']} />,
              onClick: () => setPrintMasterBillDialog({ open: true }),
            },
            label: 'Print Master Bill'
          },
          {
            buttonProps: {
              color: 'secondary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
              onClick: () => setMassSubmitDialog({ open: true }),
            },
            label: 'Mass Submit'
          },
          {
            buttonProps: {
              color: 'secondary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => {

                let hbl = HouseBillFormProperties.formikProps.initialValues;

                if (manifestData != null && manifestData != undefined && manifestData != "") {
                  hbl.movementType = manifestData.current.movementType
                  hbl.indicatorMode = manifestData.current.indicatorMode
                  hbl.primaryCcn = manifestData.current.primaryCcn
                  hbl.portOfDestCode = manifestData.current.portOfDestCode
                  hbl.destSubLocCode = manifestData.current.destSubLocCode
                  hbl.portOfDischargeCode = manifestData.current.portOfDischargeCode
                  hbl.discSubLocCode = manifestData.current.discSubLocCode
                  hbl.consolidationInd = manifestData.current.consolidationInd == "Y" ? true : false
                  hbl.containerizedInd = manifestData.current.containerizedInd == "Y" ? true : false
                  hbl.cloneFromManifest = "Y"
                  let containerDetails = manifestData.current.containerDetails

                  containerDetails = containerDetails.map(x => {
                    return {
                      ...x,
                      id: null,
                      manifestId: null
                    }
                  })
                  hbl.containerDetails = containerDetails

                  let tradeParty = manifestData.current.tradeParty
                  tradeParty = tradeParty.map(x => {
                    return {
                      ...x,
                      id: null,
                      manifestId: null
                    }
                  })
                  hbl.tradeParty = tradeParty

                  let statusNotifyParties = statusNotificationData;

                  statusNotifyParties = statusNotifyParties.map(x => {
                    return {
                      ...x,
                      id: null,
                      manifestId: null
                    }
                  })

                  hbl.statusNotifyParties = statusNotifyParties

                }
                console.log("hbl", hbl)
                setHouseBillDialog({ open: true, houseBill: hbl, isView: false })
              }
            },
            label: 'Create new house bill'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'link']} />,
              onClick: () => setAttachHouseBillDialog(true)
            },
            label: 'Attach house bill'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => setUploadCSVHouseBillDialog({ open: true, tableRef: tableRef })
            },
            label: translatedTextsObject.uploadCSVButton
          },
        ]}
        checkboxSelection
        columns={columns}
        compact
        customRowActions={[
          {
            disabled: (rowData) => (rowData.status === 'ST' || rowData.status === 'AC' || rowData.status === 'SN'),
            iconButtonProps: {
              icon: ['fal', 'minus-circle'],
              style: { color: theme.palette.error.main }
            },
            onClick: (rowData) => handleDetachHouseBills([rowData]),
            tooltip: (rowData) => (rowData.status === 'ST' || rowData.status === 'AC' || rowData.status === 'SN')
              ? translatedTextsObject.detachSentRecord
              : null
          }
        ]}
        fetch={{ url: EhblHouseBillApiUrls.GET }}
        fetchFilters={[
          { field: 'manifestId', operator: EQUAL, value: manifestId }
        ]}
        onRowClick={(rowData) => {

          if (typeof rowData.consolidationInd == "boolean") {
            setHouseBillDialog({ open: true, houseBill: rowData, isView: true })
          } else {
            setHouseBillDialog({ open: true, houseBill: HouseBillFormProperties.toClientDataFormat(rowData), isView: true })
          }
        }}
        rowActions={[
          {
            disabled: (rowData) => rowData.status === 'ST',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) => {
              rowData.cloneFromManifest = "N"

              if (typeof rowData.consolidationInd == "boolean") {
                setHouseBillDialog({
                  open: true,
                  houseBill: rowData,
                  isView: false
                })
              } else {
                setHouseBillDialog({
                  open: true,
                  houseBill: HouseBillFormProperties.toClientDataFormat(rowData),
                  isView: false
                })
              }
            },
            tooltip: (rowData) =>
              rowData.status === 'ST'
                ? translatedTextsObject.editSentRecord
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneHouseBill
          },
          {
            disabled: (rowData) => rowData.status !== 'DR',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) =>
              setConfirmDialog({ open: true, houseBill: rowData }),
            tooltip: (rowData) =>
              rowData.status !== 'DR'
                ? translatedTextsObject.deleteNotDraftRecord
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.printHBL,
            onClick: (rowData) => {
              let list = []
              list.push(rowData)
              handlePrintHouseBill(list)
            }
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.printA8A,
            onClick: (rowData) => {
              let list = []
              list.push(rowData)
              handlePrintA8A(list)
            }
          }
        ]}
        selectActions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                minWidth: 'auto'
              }
            },
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.printHBL,
            onClick: (rows) => handlePrintHouseBill(rows),
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                minWidth: 'auto'
              }
            },
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.printA8A,
            onClick: (rows) => handlePrintA8A(rows),
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                minWidth: 'auto'
              }
            },
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: translatedTextsObject.submitButton,
            onClick: (rows) => preSubmitHouseBills(rows),
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  includesSentRecords = true
                }
              })

              return includesSentRecords
                ? translatedTextsObject.submitSentRecord
                : null
            }
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                minWidth: 'auto'
              }
            },
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'cog']} />,
            label: 'Set response message',
            onClick: (rows) =>
              setSetResponseMsgDialog({ open: true, houseBills: rows }),
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  includesSentRecords = true
                }
              })

              return includesSentRecords
                ? translatedTextsObject.submitSentRecord
                : null
            }
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                minWidth: 'auto'
              }
            },
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: translatedTextsObject.createCloseMessage,
            onClick: (rows) => preCreateCloseMessage(rows),
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  includesSentRecords = true
                }
              })

              return includesSentRecords
                ? translatedTextsObject.createCloseMessageSentRecord
                : null
            }
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                minWidth: 'auto'
              }
            },
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'cog']} />,
            label: translatedTextsObject.propagate,
            onClick: (rows) =>
              setPropagateDialog({ open: true, houseBills: rows }),
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  includesSentRecords = true
                }
              })

              return includesSentRecords
                ? translatedTextsObject.propagateSentRecord
                : null
            }
          },
          {
            buttonProps: {
              size: 'medium',
              color: 'secondary',
              style: { color: theme.palette.error.main, minWidth: 'auto' }
            },
            label: "Mass Delete",
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            disabled: (rows) => disableMassDelete(rows),
            onClick: (rows) => {
              let idList = rows.map(e => e.id)
              setMassDeleteConfirmDialog({ open: true, idList: idList });
            },
            tooltip: (rows) => disableMassDelete(rows) ? translatedTextsObject.deleteNotDraftRecord : null
          },
          {
            buttonProps: {
              size: 'medium',
              color: 'secondary',
              style: { color: theme.palette.error.main, minWidth: 'auto' }
            },
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (
                  rowData.status === 'ST' || rowData.status === 'AC' || rowData.status === 'SN'
                ) {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'minus-circle']} />,
            label: translatedTextsObject.detachButton,
            onClick: handleDetachHouseBills,
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (
                  rowData.status === 'ST' || rowData.status === 'AC' || rowData.status === 'SN'
                ) {
                  includesSentRecords = true
                }
              })

              return includesSentRecords ? translatedTextsObject.detachSentRecord : null
            }
          }
        ]}
        search={{ field: 'ccn', operator: LIKE }}
        showNotification={showNotification}
        tableRef={tableRef}
      />
      <AttachHouseBillDialog
        columns={columns}
        manifestId={manifestId}
        open={attachHouseBillDialog}
        onAttachHouseBill={() => {
          if (tableRef.current) {
            tableRef.current.performRefresh()
          }
        }}
        onClose={() => setAttachHouseBillDialog(false)}
        showNotification={showNotification}
      />
      <HouseBillDialog
        getCountryStateLabel={getCountryStateLabel}
        getLookupValue={getLookupValue}
        isView={houseBillDialog.isView}
        manifestId={manifestId}
        //firstExpectedPortValue={firstExpectedPortValue}
        //expectedArrivalDateTimeValue={expectedArrivalDateTimeValue}
        //expectedArrivalTimeZoneValue={expectedArrivalTimeZoneValue}
        loading={loading}
        onClose={() =>
          setHouseBillDialog({ open: false, houseBill: null, isView: false })
        }
        onCreateHouseBill={handleCreateHouseBill}
        onEditHouseBill={handleEditHouseBill}
        open={houseBillDialog.open}
        houseBill={houseBillDialog.houseBill}
        showNotification={showNotification}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, houseBill: null })}
        confirmDialog={handleDeleteHouseBill}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <ConfirmDialog
        isConfirmDialogOpen={massDeleteConfirmDialog.open}
        closeDialog={() => setMassDeleteConfirmDialog({ open: false, idList: null })}
        confirmDialog={handleMassDeleteHouseBill}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <SetResponseMessageDialog
        amendmentCodeOptions={amendmentCodeOptions}
        onClose={() => setSetResponseMsgDialog({ open: false, houseBills: [] })}
        onSaveHouseBill={(houseBills) => {
          handleModifyHouseBill(houseBills)
          setSetResponseMsgDialog({ open: false, houseBills: [] })
        }}
        onSubmitHouseBill={preSubmitHouseBills}
        open={setResponseMsgDialog.open}
        houseBills={setResponseMsgDialog.houseBills}
        translatedTextsObject={translatedTextsObject}
      />
      <PropageteDialog
        onClose={() => setPropagateDialog({ open: false, houseBills: [] })}
        onPropagateHouseBill={handlePropagateHouseBill}
        open={propagateDialog.open}
        houseBills={propagateDialog.houseBills}
        translatedTextsObject={translatedTextsObject}
      />
      <HouseBillCSVUploadDialog
        manifestId={manifestId}
        open={uploadCSVHouseBillDialog}
        onClose={() => setUploadCSVHouseBillDialog({ open: false, tableRef: null })}
        showNotification={showNotification}
      />
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, houseBills: [] })}
        onSubmit={isMassSubmit ? handleMassSubmit : handleSubmithouseBills}
        open={submitDialog.open}
        items={submitDialog.houseBills}
      />
      <MassSubmitHouseBillDialog
        manifestId={manifestId}
        onClose={() => setMassSubmitDialog({ open: false })}
        onSubmit={preMassSubmit}
        open={massSubmitDialog.open}
        translatedTextsObject={translatedTextsObject}
      />
      <PrintMasterBillDialog
        manifestId={manifestId}
        onClose={() => setPrintMasterBillDialog({ open: false })}
        onSubmitPrint={printMasterBill}
        open={printMasterBillDialog.open}
        translatedTextsObject={translatedTextsObject}
      />
      <ErrorMessageDialog
        errorMessages={errorMessageDialog.errorMessages}
        open={errorMessageDialog.open}
        onClose={() => setErrorMessageDialog({ open: false })} />
    </>
  )
}

export default HouseBillAttachTable


function MessageFuncAndAmendmentCode(props) {
  const {
    amendmentCodeOptions,
    isSelected,
    isSentStatus,
    onSubmitHouseBill,
    houseBill,
    onUpdateHouseBill,
    translatedTextsObject
  } = props

  const theme = useTheme()
  const classes = useStyles()

  function handleSubmitButtonClick(event) {
    event.stopPropagation()
    onSubmitHouseBill(houseBill)
  }

  return (
    <Grid alignItems='center' container spacing={1} wrap='nowrap'>
      <Grid item xs='auto'>
        <Select
          className={classes.select}
          disabled={isSentStatus || !isSelected}
          native
          onChange={(event) =>
            onUpdateHouseBill({ ...houseBill, messageFuncCode: event.target.value })
          }
          onClick={(event) => event.stopPropagation()}
          size='small'
          value={houseBill.messageFuncCode || ''}
          variant='outlined'
        >
          <option value="" disabled>
            {translatedTextsObject.messageFunction}
          </option>
          <option value='9'>{translatedTextsObject.msgFuncOriginal}</option>
          <option value='4'>{translatedTextsObject.msgFuncChange}</option>
          <option value='1'>{translatedTextsObject.msgFuncCancel}</option>
          <option value='52'>{translatedTextsObject.msgFuncProposedAmendment}</option>
        </Select>
      </Grid>
      {houseBill.messageFuncCode === '52' && (
        <Grid item xs='auto'>
          <Select
            className={classes.select}
            disabled={isSentStatus || !isSelected}
            native
            onChange={(event) =>
              onUpdateHouseBill({ ...houseBill, amendmentCode: event.target.value })
            }
            onClick={(event) => event.stopPropagation()}
            size='small'
            value={houseBill.amendmentCode || ''}
            variant='outlined'
          >
            <option value="" disabled>
              {translatedTextsObject.amendmentCode}
            </option>
            {amendmentCodeOptions
              .map((option) => (
                <option key={option.code} value={option.code}>
                  {option.desc}
                </option>
              ))}
          </Select>
        </Grid>
      )}
      <Grid item xs='auto'>
        <Tooltip
          arrow
          placement='top'
          title={
            isSentStatus
              ? translatedTextsObject.submitSentRecord
              : !isSelected ? translatedTextsObject.selectRecordSubmit : translatedTextsObject.submitButton
          }
        >
          <span>
            <CngIconButton
              disabled={isSentStatus || !isSelected || !houseBill.messageFuncCode}
              size='small'
              icon={['fal', 'arrow-alt-right']}
              onClick={handleSubmitButtonClick}
              style={{
                color:
                  isSentStatus || !isSelected
                    ? theme.palette.action.disabled
                    : theme.palette.primary.main
              }}
              type='outlined'
            />
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
