import { Grid } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import { useParams, useLocation } from 'react-router-dom'
import FaxTrackerKeys from 'src/constants/locale/key/FaxTracker'
import Namespace from 'src/constants/locale/Namespace'
import FaxTrackerEditPage from './FaxTrackerEditPage'
import withFormState from 'src/components/aciacehighway/hocs/withFormState'

const { CngTabs } = components

function EditPage({ showNotification, onSetLoading }) {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.FAX_TRACKER])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let faxTracker = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.TITLE
    )
    return {
      faxTracker
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.faxTracker,
              tabContent: (
                <FaxTrackerEditPage
                  showNotification={showNotification}
                  id={id}
                  onSetLoading={onSetLoading}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default withFormState(EditPage)
