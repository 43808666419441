import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import FaxTrackerKeys from 'src/constants/locale/key/FaxTracker'
import { Grid, Card, CardContent, Typography } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation, useServices } from 'cng-web-lib'
import { useFormContext } from "react-hook-form";
import { NaAceHwyPOEPortAutocompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { NaCustomsOfficeAutocompleteField } from 'src/components/na'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import moment from 'moment-timezone'
  
const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngCheckboxField,
      CngSelectField,
      CngTextField,
      CngDateTimeField,
      CngCodeMasterAutocompleteField,
      CngSwitchField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id:"",
  acctId:"",
  manifestId:"",
  faxUserId: "",
  faxReceivedDate: moment().tz("Canada/Eastern").format('YYYY-MM-DD HH:mm'),
  noNotification: false,
  email: "",
  phoneNo: "",
  faxType: "",
  scac: "",
  carrierCode:"",
  tripNo: "",
  eta: moment().tz("Canada/Eastern").format('YYYY-MM-DD HH:mm'),
  transitIndicator: "I",
  firstPort: "",
  status: "DR",
  noOfPages: 0,
  noOfShipments: 0,
  fwdToBroker: false,
  brokerName: "",
  faxNumber: "",
  brokerEmail: "",
  remarks: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { setValue, getValues, watch } = useFormContext();
  const { securedSendRequest } = useServices();
  
  const tripNo = watch("tripNo")
  const [user, setUser] = useState([]);
  useEffect(() => { fetchUser(setUser, securedSendRequest) }, []);
  const [aceScacList, setAceScacList] = useState([]);
  const [aciScacList, setAciScacList] = useState([]);
  useEffect(() => {
    var scacCode = "";
    var module = "";
    var aceJsonArray = [];
    var aciJsonArray = [];
    if (user && user.scacs) {
      Object.entries(user.scacs).forEach((item) => {
        for (const key in item[1]) {
          if (key == "scacCode") {
            scacCode = item[1][key];
          }
          if (key == "module") {
            module = item[1][key];
          }
        }

        if (module == "ACEHW") {
          var aceJsonObject = { text: scacCode, value: scacCode };
          aceJsonArray.push(aceJsonObject);
        }

        if (module == "ACIHW") {
          var aciJsonObject = { text: scacCode, value: scacCode };
          aciJsonArray.push(aciJsonObject);
        }
      })
      setAceScacList(aceJsonArray);
      setAciScacList(aciJsonArray);
    }
  }, [user]);

  const { translate } = useTranslation(Namespace.FAX_TRACKER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let FaxTracker = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.TITLE
    )
    let userId = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.USER_ID
    )
    let faxReceivedDate = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.FAX_RECEIVED_DATE
    )
    let notification = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.NOTIFICATION
    )
    let driverEmail = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.DRIVER_EMAIL
    )
    let driverPhoneNo = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.DRIVER_PHONE_NO
    )
    let faxType = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.FAX_TYPE
    )
    let carrierScac = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.CARRIER_SCAC
    )
    let carrierCode = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.CARRIER_CODE
    )
    let tripNo = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.TRIP_NO
    )
    let expectedArrivalDate = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.EXPECTED_ARRIVAL_DATE
    )
    let transitIndicator = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.TRANSIT_INDICATOR
    )
    let firstPort = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.FIRST_PORT
    )
    let noOfPages = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.NO_OF_PAGES
    )
    let noOfShipments = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.NO_OF_SHIPMENTS
    )
    let fwdToBroker = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.FWD_TO_BROKER
    )
    let brokerName = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.BROKER_NAME
    )
    let brokerFax = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.BROKER_FAX
    )
    let brokerEmail = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.BROKER_EMAIL
    )
    let remarks = translate(
      Namespace.FAX_TRACKER,
      FaxTrackerKeys.REMARKS
    )

    return {
      FaxTracker,
      userId,
      faxReceivedDate,
      notification,
      driverEmail,
      driverPhoneNo,
      faxType,
      carrierScac,
      carrierCode,
      tripNo,
      expectedArrivalDate,
      transitIndicator,
      firstPort,
      noOfPages,
      noOfShipments,
      fwdToBroker,
      brokerName,
      brokerFax,
      brokerEmail,
      remarks
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.FaxTracker} />
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userId}>
                <CngTextField
                  required
                  name="faxUserId"
                  label={translatedTextsObject.userId}
                  disabled={disabled}
                  onChange={(e) => {
                    setValue("faxUserId", e.target.value.toUpperCase(), { shouldValidate: true })
                  }}
                  size='small'
                  inputProps={{ maxLength: 15 }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.faxReceivedDate}>
                <CngDateTimeField
                  size='small'
                  name='faxReceivedDate'
                  label={translatedTextsObject.faxReceivedDate}
                  format='YYYY-MM-DD HH:mm'
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.notification}>
                <CngSwitchField
                  name="noNotification"
                  label={translatedTextsObject.notification}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.driverEmail}>
                <CngTextField
                  required={true === getValues("noNotification") && "" === getValues("phoneNo")}
                  name="email"
                  label={translatedTextsObject.driverEmail}
                  disabled={disabled}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    setValue("email", e.target.value.toUpperCase(), { shouldValidate: true })
                    setValue("phoneNo", getValues("phoneNo"), { shouldValidate: true })
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.driverPhoneNo}>
                <CngTextField
                  required={true === getValues("noNotification") && "" === getValues("email")}
                  name="phoneNo"
                  label={translatedTextsObject.driverPhoneNo}
                  disabled={disabled}
                  inputProps={
                    {maxLength: 15}
                  }
                  onChange={(e) =>
                    setValue('email', getValues("email"),{shouldValidate: true})
                  }
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.faxType}>
                <CngCodeMasterAutocompleteField 
                  required
                  name="faxType" 
                  label={translatedTextsObject.faxType} 
                  disabled={disabled}
                  codeType='FAX_TRACKER_TYPE'
                  size='small'
                  onChange={(e) => {
                    setValue('firstPort', "", {shouldValidate: true})
                    setValue('scac', "", {shouldValidate: true})
                  }}
                />
              </CngGridItem>
              {"ACEHW" === getValues("faxType") ?
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.carrierScac}>
                  <CngTextField
                    required
                    name="scac"
                    inputProps={
                      {maxLength: 4}
                    }
                    label={translatedTextsObject.carrierScac}
                    disabled={disabled}
                    onChange={(e) => {
                      setValue("scac",e.target.value.toUpperCase(), { shouldValidate: true })
                      if (getValues('tripNo') === '' || getValues('tripNo').length <= 4) {
                        setValue("tripNo", e.target.value.toUpperCase(), { shouldValidate: true })
                      }
                    }}
                    
                    // onBlur={(e) => {
                    //     if(getValues('tripNo') === '' || getValues('tripNo').length <= 4) {
                    //       setValue("tripNo", e.target.value.toUpperCase(), { shouldValidate: true })
                    //     }
                    // }}
                  />
                </CngGridItem>
                :
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.carrierScac}>
                  <CngTextField
                    required
                    name="scac"
                    inputProps={
                      {maxLength: 4}
                    }
                    label={translatedTextsObject.carrierCode}
                    disabled={disabled}
                    onChange={(e) => {
                      setValue("scac",e.target.value.toUpperCase(), { shouldValidate: true })
                      if (getValues('tripNo') === '' || getValues('tripNo').length <= 4) {
                        setValue("tripNo", e.target.value.toUpperCase(), { shouldValidate: true })
                      }
                    }}
                  />
                </CngGridItem>
              }
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tripNo}>
                <CngTextField
                  size='small'
                  required
                  name='tripNo'
                  label={translatedTextsObject.tripNo}
                  disabled={disabled}
                  inputProps={{ maxLength: 25 }}
                  onChange={(e) => {
                    setValue('tripNo', e.target.value.toUpperCase(), {
                      shouldValidate: true
                    })
                  }}
                  onBlur={(e) => {
                    if (e.target.value !== undefined) {
                      setValue('tripNo', e.target.value.trim(), {
                        shouldValidate: true
                      })
                    }
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.expectedArrivalDate}>
                <CngDateTimeField
                  size='small'
                  required
                  name='eta'
                  label={translatedTextsObject.expectedArrivalDate}
                  format='YYYY-MM-DD HH:mm'
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.transitIndicator}>
                <CngCodeMasterAutocompleteField
                  size='small'
                  required
                  name='transitIndicator'
                  label={translatedTextsObject.transitIndicator}
                  disabled={disabled}
                  codeType='ACE_HW_TRANSPORT_INDICATOR'
                />
              </CngGridItem>
              
              {"ACEHW" === getValues("faxType") ?
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.firstPort}>
                  <NaAceHwyPOEPortAutocompleteField
                    size='small'
                    required
                    name='firstPort'
                    label={translatedTextsObject.firstPort}
                    disabled={disabled}
                  />
                </CngGridItem>
                :
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.firstPort}>
                  <NaCustomsOfficeAutocompleteField
                    required
                    name="firstPort"
                    label={translatedTextsObject.firstPort}
                    disabled={disabled}
                    size='small'
                    lookupProps= {{
                      filters: [{
                      field: 'indicator',
                      operator: 'equal',
                      value: 'ACI'
                      }]
                    }}
                  />
                </CngGridItem>
              }
              
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.noOfPages}>
                <CngTextField
                  required={getValues("fwdToBroker")}
                  name="noOfPages"
                  label={translatedTextsObject.noOfPages}
                  disabled={disabled}
                  size='small'
                  type='number'
                  inputProps={{ maxLength: 4 }}
                  onChange={(e) => {
                    setValue("noOfPages", parseInt(e.target.value), { shouldValidate: true })
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.noOfShipments}>
                <CngTextField
                  required={getValues("fwdToBroker")}
                  name='noOfShipments'
                  label={translatedTextsObject.noOfShipments}
                  disabled={disabled}
                  size='small'
                  type='number'
                  inputProps={{ maxLength: 4 }}
                  onChange={(e) => {
                    setValue("noOfShipments", parseInt(e.target.value), { shouldValidate: true })
                  }}
                />
              </CngGridItem>
              <Grid item xs={12}>
                <CngCheckboxField
                  label={
                    <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                      {translatedTextsObject.fwdToBroker}
                    </Typography>
                  }
                  name='fwdToBroker'
                  disabled={disabled}
                  onChange={(e) =>
                    setValue('fwdToBroker', e.target.checked, {
                      shouldValidate: true
                    })
                  }
                  size='small'
                  style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                />
              </Grid>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.brokerName}>
                <CngTextField
                  name="brokerName"
                  label={translatedTextsObject.brokerName}
                  disabled={disabled}
                  size='small'
                  inputProps={{ maxLength: 30 }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.brokerFax}>
                <CngTextField
                  // required={getValues("fwdToBroker") && "" === getValues("brokerEmail")}
                  name="faxNumber"
                  label={translatedTextsObject.brokerFax+ " **"}
                  disabled={disabled}
                  size='small'
                  inputProps={{ maxLength: 14 }}
                  onChange={(e) =>
                    setValue('brokerEmail', getValues("brokerEmail"),{shouldValidate: true})
                  }
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.brokerEmail}>
                <CngTextField
                  // required={getValues("fwdToBroker") && "" === getValues("faxNumber")}
                  name="brokerEmail"
                  label={translatedTextsObject.brokerEmail+ " **"}
                  disabled={disabled}
                  size='small'
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    setValue("brokerEmail", e.target.value.toUpperCase(), { shouldValidate: true })
                    setValue('faxNumber', getValues("faxNumber"),{shouldValidate: true})
                  }}
                />
              </CngGridItem>
              <h5>** Denotes Either Broker Fax or Broker Email is required when Fwd to Broker is selected.</h5>
              <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.remarks}>
                <CngTextField
                  multiline
                  rows={4}
                  name="remarks"
                  label={translatedTextsObject.remarks}
                  disabled={disabled}
                  size='small'
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => { setValue('remarks', e.target.value.toUpperCase().replace(/(\r\n|\r|\n)+/g, ' ')) }}
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  if(localData.noNotification === '1'){
    localData.noNotification = true
  }else{
    localData.noNotification = false
  }

  if(localData.fwdToBroker === '1'){
    localData.fwdToBroker = true
  }else{
    localData.fwdToBroker = false
  }
  return localData;
}

function toServerDataFormat(localData) {
  if(localData.noNotification === true){
    localData.noNotification = 1
  }else{
    localData.noNotification = 0
  }

  if(localData.fwdToBroker === true){
    localData.fwdToBroker = 1
  }else{
    localData.fwdToBroker = 0
  }
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
