import makeValidationSchema from './ContainerDetailsMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import CollapsibleSection from '../../../components/ui/CollapsibleSection'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  hblId: "",
  manifestId: "",
  mfId: "",
  equipmentId: "",
  equipmentType: "",
  sealNo: "",
  sealNo1: "",
  sealNo2: "",
  sealNo3: "",
  sealNo4: "",
  sealNo5: "",
  sealNo6: "",
  sealNo7: "",
  sealNo8: "",
  sealNo9: "",
  sealNo10: "",
  sealNo11: "",
  sealNo12: "",
  sealNo13: "",
  sealNo14: "",
  sealNo15: "",
  sealNo16: "",
  sealNo17: "",
  sealNo18: "",
  sealNo19: "",
  sealNo20: "",
  sealNo21: "",
  sealNo22: "",
  sealNo23: "",
  sealNo24: "",
  sealNo25: "",
  sealNo26: "",
  sealNo27: "",
  sealNo28: "",
  sealNo29: "",
  sealNo30: "",
  sealNo21: "",
  sealNo22: "",
  sealNo23: "",
  sealNo24: "",
  sealNo25: "",
  sealNo26: "",
  sealNo27: "",
  sealNo38: "",
  sealNo39: "",
  sealNo40: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.EHBL_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, reset, watch,getValues } = useFormContext();
  let sealNo = getValues("sealNo");

  const sealNos = watch([
    'sealNo1',
    'sealNo2',
    'sealNo3',
    'sealNo4',
    'sealNo5',
    'sealNo6',
    'sealNo7',
    'sealNo8',
    'sealNo9',
    'sealNo10',
    'sealNo11',
    'sealNo12',
    'sealNo13',
    'sealNo14',
    'sealNo15',
    'sealNo16',
    'sealNo17',
    'sealNo18',
    'sealNo19',
    'sealNo20',
    'sealNo21',
    'sealNo22',
    'sealNo23',
    'sealNo24',
    'sealNo25',
    'sealNo26',
    'sealNo27',
    'sealNo28',
    'sealNo29',
    'sealNo30',
    'sealNo31',
    'sealNo32',
    'sealNo33',
    'sealNo34',
    'sealNo35',
    'sealNo36',
    'sealNo37',
    'sealNo38',
    'sealNo39',
    'sealNo40'
  ])

  function makeTranslatedTextsObject() {
    let containerDetails = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.TITLE
    )
    let hblId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.HBL_ID
    )
    let manifestId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.MANIFEST_ID
    )
    let mfId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.MF_ID
    )
    let equipmentId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.EQUIPMENT_ID
    )
    let equipmentType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.EQUIPMENT_TYPE
    )
    let sealNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO
    )

    let sealNo1 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_1
    )

    let sealNo2 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_2
    )

    let sealNo3 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_3
    )

    let sealNo4 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_4
    )

    let sealNo5 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_5
    )

    let sealNo6 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_6
    )

    let sealNo7 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_7
    )

    let sealNo8 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_8
    )

    let sealNo9 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_9
    )

    let sealNo10 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_10
    )

    let sealNo11 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_11
    )

    let sealNo12 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_12
    )

    let sealNo13 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_13
    )

    let sealNo14 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_14
    )

    let sealNo15 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_15
    )

    let sealNo16 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_16
    )

    let sealNo17 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_17
    )

    let sealNo18 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_18
    )

    let sealNo19 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_19
    )

    let sealNo20 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_20
    )

    let sealNo21 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_21
    )

    let sealNo22 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_22
    )

    let sealNo23 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_23
    )

    let sealNo24 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_24
    )

    let sealNo25 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_25
    )

    let sealNo26 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_26
    )

    let sealNo27 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_27
    )

    let sealNo28 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_28
    )

    let sealNo29 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_29
    )

    let sealNo30 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_30
    )

    let sealNo31 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_31
    )

    let sealNo32 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_32
    )

    let sealNo33 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_33
    )

    let sealNo34 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_34
    )

    let sealNo35 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_35
    )

    let sealNo36 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_36
    )

    let sealNo37 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_37
    )

    let sealNo38 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_38
    )

    let sealNo39 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_39
    )

    let sealNo40 = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.ContainerDetails.SEAL_NO_40
    )


    return {
      containerDetails,
      hblId,
      manifestId,
      mfId,
      equipmentId,
      equipmentType,
      sealNo,
      sealNo1,
      sealNo2,
      sealNo3,
      sealNo4,
      sealNo5,
      sealNo6,
      sealNo7,
      sealNo8,
      sealNo9,
      sealNo10,
      sealNo11,
      sealNo12,
      sealNo13,
      sealNo14,
      sealNo15,
      sealNo16,
      sealNo17,
      sealNo18,
      sealNo19,
      sealNo20,
      sealNo21,
      sealNo22,
      sealNo23,
      sealNo24,
      sealNo25,
      sealNo26,
      sealNo27,
      sealNo28,
      sealNo29,
      sealNo30,
      sealNo31,
      sealNo32,
      sealNo33,
      sealNo34,
      sealNo35,
      sealNo36,
      sealNo37,
      sealNo38,
      sealNo39,
      sealNo40
    }
  }

  useEffect(() => {
    let sealNo = getValues("sealNo");
    let sealArray = sealNo.split(":");
    for(let i=1; i<=sealArray.length; i++){
      setValue("sealNo"+i,sealArray[i-1])
    }
  },[]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
         <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.equipmentId}>
            <CngTextField
              required
              name="equipmentId"
              label={translatedTextsObject.equipmentId}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue("equipmentId", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
        </Grid>
        <Grid item xs={12}>
          <CollapsibleSection
            title={translatedTextsObject.sealNo}
            defaultExpanded={sealNos.filter((sealNo) => sealNo).length > 0}
            footerText={translatedTextsObject.sealNoFooterText}
            hideText={translatedTextsObject.sealNoHideText}
            showText={translatedTextsObject.sealNoShowText}
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo1}>
                <CngTextField
                  name="sealNo1"
                  label={translatedTextsObject.sealNo1}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo1", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo2}>
                <CngTextField
                  name="sealNo2"
                  label={translatedTextsObject.sealNo2}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo2", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo3}>
                <CngTextField
                  name="sealNo3"
                  label={translatedTextsObject.sealNo3}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo3", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo4}>
                <CngTextField
                  name="sealNo4"
                  label={translatedTextsObject.sealNo4}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo4", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo5}>
                <CngTextField
                  name="sealNo5"
                  label={translatedTextsObject.sealNo5}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo5", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo6}>
                <CngTextField
                  name="sealNo6"
                  label={translatedTextsObject.sealNo6}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo6", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo7}>
                <CngTextField
                  name="sealNo7"
                  label={translatedTextsObject.sealNo7}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo7", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo8}>
                <CngTextField
                  name="sealNo8"
                  label={translatedTextsObject.sealNo8}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo8", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo9}>
                <CngTextField
                  name="sealNo9"
                  label={translatedTextsObject.sealNo9}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo9", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo10}>
                <CngTextField
                  name="sealNo10"
                  label={translatedTextsObject.sealNo10}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo10", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo11}>
                <CngTextField
                  name="sealNo11"
                  label={translatedTextsObject.sealNo11}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo11", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo12}>
                <CngTextField
                  name="sealNo12"
                  label={translatedTextsObject.sealNo12}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo12", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo13}>
                <CngTextField
                  name="sealNo13"
                  label={translatedTextsObject.sealNo13}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo13", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo14}>
                <CngTextField
                  name="sealNo14"
                  label={translatedTextsObject.sealNo14}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo14", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo15}>
                <CngTextField
                  name="sealNo15"
                  label={translatedTextsObject.sealNo15}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo15", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo16}>
                <CngTextField
                  name="sealNo16"
                  label={translatedTextsObject.sealNo16}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo16", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo17}>
                <CngTextField
                  name="sealNo17"
                  label={translatedTextsObject.sealNo17}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo17", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo18}>
                <CngTextField
                  name="sealNo18"
                  label={translatedTextsObject.sealNo18}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo18", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo19}>
                <CngTextField
                  name="sealNo19"
                  label={translatedTextsObject.sealNo19}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo19", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo20}>
                <CngTextField
                  name="sealNo20"
                  label={translatedTextsObject.sealNo20}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo20", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo1}>
                <CngTextField
                  name="sealNo21"
                  label={translatedTextsObject.sealNo21}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo21", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo22}>
                <CngTextField
                  name="sealNo22"
                  label={translatedTextsObject.sealNo22}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo22", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo23}>
                <CngTextField
                  name="sealNo23"
                  label={translatedTextsObject.sealNo23}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo23", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo24}>
                <CngTextField
                  name="sealNo24"
                  label={translatedTextsObject.sealNo24}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo24", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo25}>
                <CngTextField
                  name="sealNo25"
                  label={translatedTextsObject.sealNo25}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo25", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo26}>
                <CngTextField
                  name="sealNo26"
                  label={translatedTextsObject.sealNo26}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo26", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo27}>
                <CngTextField
                  name="sealNo27"
                  label={translatedTextsObject.sealNo27}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo27", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo28}>
                <CngTextField
                  name="sealNo28"
                  label={translatedTextsObject.sealNo28}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo28", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo29}>
                <CngTextField
                  name="sealNo29"
                  label={translatedTextsObject.sealNo29}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo29", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo210}>
                <CngTextField
                  name="sealNo30"
                  label={translatedTextsObject.sealNo30}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo30", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo1}>
                <CngTextField
                  name="sealNo31"
                  label={translatedTextsObject.sealNo31}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo31", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo32}>
                <CngTextField
                  name="sealNo32"
                  label={translatedTextsObject.sealNo32}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo32", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo33}>
                <CngTextField
                  name="sealNo33"
                  label={translatedTextsObject.sealNo33}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo33", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo34}>
                <CngTextField
                  name="sealNo34"
                  label={translatedTextsObject.sealNo34}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo34", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo35}>
                <CngTextField
                  name="sealNo35"
                  label={translatedTextsObject.sealNo35}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo35", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo36}>
                <CngTextField
                  name="sealNo36"
                  label={translatedTextsObject.sealNo36}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo36", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo37}>
                <CngTextField
                  name="sealNo37"
                  label={translatedTextsObject.sealNo37}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo37", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo38}>
                <CngTextField
                  name="sealNo38"
                  label={translatedTextsObject.sealNo38}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo38", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo39}>
                <CngTextField
                  name="sealNo39"
                  label={translatedTextsObject.sealNo39}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo39", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo310}>
                <CngTextField
                  name="sealNo40"
                  label={translatedTextsObject.sealNo40}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo40", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </CollapsibleSection>
        </Grid>
      </Grid>
    </>
  )
  /*return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.containerDetails} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="hblId"
              label={translatedTextsObject.hblId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="manifestId"
              label={translatedTextsObject.manifestId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="mfId"
              label={translatedTextsObject.mfId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.equipmentId}>
            <CngTextField
              name="equipmentId"
              label={translatedTextsObject.equipmentId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="equipmentType"
              label={translatedTextsObject.equipmentType}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNo}>
            <CngTextField
              name="sealNo"
              label={translatedTextsObject.sealNo}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )*/
}

const ContainerDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ContainerDetailsFormProperties
